import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/users/user.selectors";
import {addUserStart} from "../../redux/users/user.actions";

const AddUser = ({ addLoading, setCurrentPage, addUserStart, history ,addStatus, addErrors}) => {
    if (addStatus) history.push("/home/users/list");
    //for request errors
    const [serverError, setServerError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    //form
    const [userCredentials, setCredentials] = useState({ name: '', Email: '', password: ''});
    const { email, password, name } = userCredentials;

    useEffect(() => {
        setCurrentPage('users');
    },[setCurrentPage]);

    useEffect(() => {
        if (addErrors) {
            if (addErrors.email) setEmailError(addErrors.email);
            if (addErrors.name) setNameError(addErrors.name);
            if (addErrors.password) setPasswordError(addErrors.password);
            if (
                !addErrors.name && !addErrors.email &&   !addErrors.password
            )setServerError(addErrors);
        }
    }, [addErrors]);



    const handleChange = event => {
        const {value, name} = event.target;
        setCredentials({...userCredentials, [name]: value});
    };
    const addEventHandler = async ()  => {
        const formData = new FormData();
        formData.append('email',email);
        formData.append('name',name);
        formData.append('password',password);
        setEmailError('');
        setNameError('');
        setPasswordError('');
        addUserStart({formData});
    }

    return (

        <React.Fragment>
            <div className="overview">

                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/users/list')}><i className="fas fa-arrow-left"  /></span> Ajouter un Utilsateur
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
            {
                addLoading
                ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        {
                            serverError
                                ? <div className="ui negative message">
                                    <div className="header danger-text" >
                                        le serveur a été arrêté
                                    </div>
                                    <p>veuillez réessayer
                                    </p></div>
                                :<div className="slider-img__block">
                                    <div className="ui grid">
                                        <div className="two column row">
                                            <div className="seven wide column">
                                                 <img src={'/images/user.png'} className="add-ref-image add-cat-img" alt=""/>
                                            </div>

                                            <div className="nine wide column">
                                                <div className="ui grid">
                                                    <div className="row">
                                                        <div className="sixteen wide column  formGroup">
                                                            <label htmlFor="">Nom:</label>
                                                            <div className="field">
                                                            <div className="ui right corner labeled input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Nom..."
                                                                    name="name"
                                                                    value={name}
                                                                    required
                                                                    onChange={handleChange}
                                                                />
                                                                <div className="ui right corner label">
                                                                    <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                </div>
                                                            </div>
                                                                {
                                                                    nameError
                                                                    ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                        </div>
                                                                    : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="sixteen wide column  formGroup">
                                                            <label htmlFor="">Email:</label>
                                                            <div className="field">
                                                            <div className="ui right corner labeled input">
                                                                <input
                                                                    type="email"
                                                                    placeholder="Email..."
                                                                    name="email"
                                                                    value={email}
                                                                    required
                                                                    onChange={handleChange}
                                                                />
                                                                <div className="ui right corner label">
                                                                    <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                </div>
                                                            </div>
                                                                {
                                                                    emailError
                                                                    ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                        </div>
                                                                    : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="sixteen wide column  formGroup">
                                                            <label htmlFor="">Mot de passe:</label>
                                                            <div className="field">
                                                            <div className="ui right corner labeled input">
                                                                <input
                                                                    type="password"
                                                                    placeholder="Mot de passe..."
                                                                    name="password"
                                                                    value={password}
                                                                    required
                                                                    onChange={handleChange}
                                                                />
                                                                <div className="ui right corner label">
                                                                    <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                </div>
                                                            </div>
                                                                {
                                                                    passwordError
                                                                    ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> le champ obligatoire !
                                                                        </div>
                                                                    : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <Button.Group className=" btn-block ">
                                        <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/users/list`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                        <Button.Or text="ou"/>
                                        <Button color={"black"} size={'big'} className="form-btn"  onClick={addEventHandler}> <i className="fas fa-plus"/> Ajouter</Button>
                                    </Button.Group>
                                </div>
                        }
                    </React.Fragment>

            }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
});

const mapDispatchToProps = dispatch => ({
    addUserStart : eventData => dispatch(addUserStart(eventData)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser));
