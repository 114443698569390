const SlidersActionsTypes = {
    FETCH_SLIDERS_START : 'FETCH_SLIDERS_START',
    FETCH_SLIDERS_SUCCESS : 'FETCH_SLIDERS_SUCCESS',
    FETCH_SLIDERS_FAILURE : 'FETCH_SLIDERS_FAILURE',
    DELETE_SLIDER_START : 'DELETE_SLIDER_START',
    DELETE_SLIDER_SUCCESS : 'DELETE_SLIDER_SUCCESS',
    DELETE_SLIDER_FAILURE : 'DELETE_SLIDER_FAILURE',
    ADD_SLIDER_START : 'ADD_SLIDER_START',
    ADD_SLIDER_SUCCESS : 'ADD_SLIDER_SUCCESS',
    ADD_SLIDER_FAILURE : 'ADD_SLIDER_FAILURE',
    GET_SLIDER_START : 'GET_SLIDER_START',
    GET_SLIDER_SUCCESS : 'GET_SLIDER_SUCCESS',
    GET_SLIDER_FAILURE : 'GET_SLIDER_FAILURE',
    UPDATE_SLIDER_START : 'UPDATE_SLIDER_START',
    UPDATE_SLIDER_SUCCESS : 'UPDATE_SLIDER_SUCCESS',
    UPDATE_SLIDER_FAILURE : 'UPDATE_SLIDER_FAILURE',
    CLEAR_VAR : 'CLEAR_VAR',
};

export default SlidersActionsTypes;
