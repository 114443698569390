import SubcategoriesActionsTypes from "./subcategory.types";

export const fetchSubcategoriesStart = category => ({
    type : SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_START,
    payload : category
});
export const clearVariables = () => ({
    type : SubcategoriesActionsTypes.CLEAR_VAR_SUB_CAT,
});

export const fetchSubcategoriesSuccess = subcategory => ({
    type : SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_SUCCESS,
    payload : subcategory,
});

export const fetchSubcategoriesFailure = errorMessage => ({
    type : SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_FAILURE,
    payload : errorMessage,
});

export const addSubcategoryStart = categoryInformation =>  ({
    type : SubcategoriesActionsTypes.ADD_SUBCATEGORY_START,
    payload : categoryInformation
});

export const addSubcategorySuccess = () =>  ({
    type : SubcategoriesActionsTypes.ADD_SUBCATEGORY_SUCCESS,
});

export const addSubcategoryFailure = errorMessage => ({
    type : SubcategoriesActionsTypes.ADD_SUBCATEGORY_FAILURE,
    payload : errorMessage,
});

export const deleteSubcategoryStart = subcategory =>  ({
    type : SubcategoriesActionsTypes.DELETE_SUBCATEGORY_START,
    payload : subcategory,
});

export const deleteSubcategorySuccess = subcategory =>  ({
    type : SubcategoriesActionsTypes.DELETE_SUBCATEGORY_SUCCESS,
    payload : subcategory,
});

export const deleteSubcategoryFailure = subcategory => ({
    type : SubcategoriesActionsTypes.DELETE_SUBCATEGORY_FAILURE,
    payload : subcategory,
});

export const getSubcategoryStart = subcategory =>  ({
    type : SubcategoriesActionsTypes.GET_SUBCATEGORY_START,
    payload : subcategory,
});

export const getSubcategorySuccess = subcategory =>  ({
    type : SubcategoriesActionsTypes.GET_SUBCATEGORY_SUCCESS,
    payload : subcategory,
});

export const getSubcategoryFailure = errorMessage => ({
    type : SubcategoriesActionsTypes.GET_SUBCATEGORY_FAILURE,
    payload : errorMessage,
});

export const updateSubcategoryStart = subcategory =>  ({
    type : SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_START,
    payload : subcategory,
});

export const updateSubcategorySuccess = () =>  ({
    type : SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_SUCCESS,
});

export const updateSubcategoryFailure = errorMessage => ({
    type : SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_FAILURE,
    payload : errorMessage,
});
