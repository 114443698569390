import ReferencesActionsTypes from "./reference.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //References list
    references : [],
    isFetchingRef : true,

    //add Reference
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete Reference
    ReferenceNotFoundError: false,
    deleteLoading : false,

    //get Reference
    getReferenceError: null,
    currentReference : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const referenceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //references list
        case ReferencesActionsTypes.FETCH_REFERENCES_START:
            return {
                ...state,
                isFetchingRef: true,
                addStatus: false,
                addErrorMessage: null,
                referenceNotFoundError:false,
                updateStatus: false,
            };
        case ReferencesActionsTypes.FETCH_REFERENCES_SUCCESS:
            return {
                ...state,
                isFetchingRef: false,
                references: action.payload,
            };
        case ReferencesActionsTypes.FETCH_REFERENCES_FAILURE :
            return {
                ...state,
                isFetchingRef: false,
                // errorMessage: action.payload, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            };

        //Add reference
        case ReferencesActionsTypes.ADD_REFERENCE_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case ReferencesActionsTypes.ADD_REFERENCE_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingRef: true,
                addErrorMessage: null,
            }
        case ReferencesActionsTypes.ADD_REFERENCE_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete reference
        case ReferencesActionsTypes.DELETE_REFERENCE_START :
            return {
                ...state,
                deleteLoading: true,
                referenceNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case ReferencesActionsTypes.DELETE_REFERENCE_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                referenceNotFoundError: false,
                references: state.references.filter(
                    reference => reference.id !== action.payload.id
                )
            }
        case ReferencesActionsTypes.DELETE_REFERENCE_FAILURE :
            return {
                ...state,
                referenceNotFoundError: true,
                deleteLoading: false,
                references: state.references.filter(
                    reference => reference.id !== action.payload.id
                )
            }

        //get reference
        case ReferencesActionsTypes.GET_REFERENCE_START :
            return {
                ...state,
                updateLoading: true,
                getReferenceError:null,
            }
        case ReferencesActionsTypes.GET_REFERENCE_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getReferenceError: null,
                currentReference: action.payload,
            }
        case ReferencesActionsTypes.GET_REFERENCE_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getReferenceError: action.payload,
                currentReference: null,
            }


        //update
        case ReferencesActionsTypes.UPDATE_REFERENCE_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case ReferencesActionsTypes.UPDATE_REFERENCE_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingRef: true,
            }
        case ReferencesActionsTypes.UPDATE_REFERENCE_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //References list
                references : [],
                isFetchingRef : false,

                //add Reference
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,

                //delete Reference
                ReferenceNotFoundError: false,
                deleteLoading : false,

                //get Reference
                getReferenceError: null,
                currentReference : null,
                updateLoading : false,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,

            }
        default:
            return state;

    }
};

export default referenceReducer;
