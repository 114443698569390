import ProductsActionsTypes from "./products.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';

import Axios from "axios";
import {
    addProductFailure, addProductSuccess,
    deleteProductFailure, deleteProductSuccess,
    fetchProductsFailure,
    fetchProductsSuccess, getProductFailure, getProductSuccess,
    updateProductFailure,
    updateProductSuccess
} from "./products.actions";



export function* fetchProductsAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/products/${id}/edit`);
        const Products = response.data.products
        yield put(fetchProductsSuccess(Products));
    } catch (error) {
        yield put(fetchProductsFailure(error.message));
    }
}


export function* addProductAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/products", formData);
        yield put(addProductSuccess());
    } catch (error) {
        yield put(addProductFailure(error.response.data));
    }
}

export function* getProductAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/products/${id}`);
        const Product = response.data.product;
        yield put(getProductSuccess(Product));
    } catch (error) {
        yield put(getProductFailure(error.message));
    }
}

export function* deleteProductAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/products/${id}`);
        yield put(deleteProductSuccess({id: id}));
    } catch (error) {
        if (error.response.status === 422) {
            yield put(deleteProductFailure({id: id}));
        }

    }
}
//
// export function* clearSubcategoriesVariables() {
//     yield put(clearVariables())
// }

export function* updateProductAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/products/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateProductSuccess());
    } catch (error) {
        yield put(updateProductFailure(error.response.data));
    }
}

export function* onFetchProducts() {
    yield takeLatest(ProductsActionsTypes.FETCH_PRODUCTS_START, fetchProductsAsync)
}

export function* onAddProductStart() {
    yield takeLatest(ProductsActionsTypes.ADD_PRODUCT_START, addProductAsync)
}

export function* onDeleteProductStart() {
    yield takeLatest(ProductsActionsTypes.DELETE_PRODUCT_START, deleteProductAsync)
}

export function* onGetProductStart() {
    yield takeLatest(ProductsActionsTypes.GET_PRODUCT_START, getProductAsync)
}

export function* onUpdateProductStart() {
    yield takeLatest(ProductsActionsTypes.UPDATE_PRODUCT_START, updateProductAsync)
}

// export function* onUserSignOutFct() {
//     yield takeLatest(ProductsActionsTypes.SIGN_OUT_SUCCESS, clearSubcategoriesVariables)
// }

export function* ProductSagas() {
    yield all([
        call(onFetchProducts),
        call(onAddProductStart),
        call(onDeleteProductStart),
        call(onGetProductStart),
        call(onUpdateProductStart),
        // call(onUserSignOutFct),
    ]);
}

