import SlidersActionsTypes from "./slider.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //sliders list
    sliders : [],
    isFetching : true,

    //add slide
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete slide
    sliderNotFoundError: false,
    deleteLoading : false,

    //get slide
    getSliderError: null,
    currentSlider : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const sliderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //sliders list
        case SlidersActionsTypes.FETCH_SLIDERS_START:
            return {
                ...state,
                isFetching: true,
                addStatus: false,
                addErrorMessage: null,
                sliderNotFoundError:false,
                updateStatus: false,
            };
        case SlidersActionsTypes.FETCH_SLIDERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                sliders: action.payload,
            };
        case SlidersActionsTypes.FETCH_SLIDERS_FAILURE :
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
            };

        //Add slide
        case SlidersActionsTypes.ADD_SLIDER_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case SlidersActionsTypes.ADD_SLIDER_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetching: true,
                addErrorMessage: null,
            }
        case SlidersActionsTypes.ADD_SLIDER_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete slide
        case SlidersActionsTypes.DELETE_SLIDER_START :
            return {
                ...state,
                deleteLoading: true,
                sliderNotFoundError : false
            }
        case SlidersActionsTypes.DELETE_SLIDER_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                sliderNotFoundError: false,
                sliders: state.sliders.filter(
                    slider => slider.id !== action.payload.id
                )
            }
        case SlidersActionsTypes.DELETE_SLIDER_FAILURE :
            return {
                ...state,
                sliderNotFoundError: true,
                deleteLoading: false,
                sliders: state.sliders.filter(
                    slider => slider.id !== action.payload.id
                )
            }

        //get slide
        case SlidersActionsTypes.GET_SLIDER_START :
            return {
                ...state,
                updateLoading: true,
                getSliderError:null,
            }
        case SlidersActionsTypes.GET_SLIDER_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getSliderError: null,
                currentSlider: action.payload,
            }
        case SlidersActionsTypes.GET_SLIDER_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getSliderError: action.payload,
                currentSlider: null,
            }


        //update slide
        case SlidersActionsTypes.UPDATE_SLIDER_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case SlidersActionsTypes.UPDATE_SLIDER_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetching: true,
            }
        case SlidersActionsTypes.UPDATE_SLIDER_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

            case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                sliders : [],
                isFetching : false,
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,
                sliderNotFoundError: false,
                deleteLoading : false,
                getSliderError: null,
                currentSlider : null,
                updateLoading : false,
                updateErrorMessage: null,
                updateStatus : false,
            }
        default:
            return state;

    }
};

export default sliderReducer;
