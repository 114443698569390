import ConfigActionsTypes from "./config.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {

    //get Config
    getConfigError: null,
    currentConfig : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,

};

const configReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //get config
        case ConfigActionsTypes.GET_CONFIG_START :
            return {
                ...state,
                updateLoading: true,
                getConfigError:null,
            }
        case ConfigActionsTypes.GET_CONFIG_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getConfigError: null,
                currentConfig: action.payload,
            }
        case ConfigActionsTypes.GET_CONFIG_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getConfigError: action.payload,
                currentConfig: null,
            }


        //update
        case ConfigActionsTypes.UPDATE_CONFIG_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case ConfigActionsTypes.UPDATE_CONFIG_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
            }
        case ConfigActionsTypes.UPDATE_CONFIG_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //get Config
                getConfigError: null,
                currentConfig : null,
                updateLoading : true,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,

            }


        default:
            return state;
    }
};

export default configReducer;
