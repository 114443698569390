import ReferencesActionsTypes from "./reference.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';
import {
    addReferenceFailure,
    addReferenceSuccess,
    clearVariables,
    deleteReferenceFailure,
    deleteReferenceSuccess,
    fetchReferencesFailure,
    fetchReferencesSuccess, getReferenceFailure, getReferenceSuccess, updateReferenceFailure, updateReferenceSuccess
} from "./reference.actions";
import Axios from "axios";


export function* fetchReferencesAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/references");
        const References = response.data.references;
        yield put(fetchReferencesSuccess(References));
    } catch (error) {
        yield put(fetchReferencesFailure(error.message));
    }
}


export function* addReferencesAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/references", formData);
        yield put(addReferenceSuccess());
    } catch (error) {
        yield put(addReferenceFailure(error.response.data));
    }
}

export function* getReferencesAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/references/${id}`);
        const Reference = response.data.reference;
        yield put(getReferenceSuccess(Reference));
    } catch (error) {
        yield put(getReferenceFailure(error.message));
    }
}

export function* deleteReferencesAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/references/${id}`);
        yield put(deleteReferenceSuccess({id: id}));
    } catch (error) {
        console.log(error)
        if (error.response.status === 422) {
            yield put(deleteReferenceFailure({id: id}));
        }

    }
}

export function* clearReferencesVariables() {
    yield put(clearVariables())
}

export function* updateReferencesAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/references/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateReferenceSuccess());
    } catch (error) {
        yield put(updateReferenceFailure(error.response.data));
    }
}

export function* onFetchReferences() {
    yield takeLatest(ReferencesActionsTypes.FETCH_REFERENCES_START, fetchReferencesAsync)
}

export function* onAddReferenceStart() {
    yield takeLatest(ReferencesActionsTypes.ADD_REFERENCE_START, addReferencesAsync)
}

export function* onDeleteReferenceStart() {
    yield takeLatest(ReferencesActionsTypes.DELETE_REFERENCE_START, deleteReferencesAsync)
}

export function* onGetReferenceStart() {
    yield takeLatest(ReferencesActionsTypes.GET_REFERENCE_START, getReferencesAsync)
}

export function* onUpdateReferenceStart() {
    yield takeLatest(ReferencesActionsTypes.UPDATE_REFERENCE_START, updateReferencesAsync)
}

export function* onUserSignOutFct() {
    yield takeLatest(ReferencesActionsTypes.SIGN_OUT_SUCCESS, clearReferencesVariables)
}

export function* ReferenceSagas() {
    yield all([
        call(onFetchReferences),
        call(onAddReferenceStart),
        call(onDeleteReferenceStart),
        call(onGetReferenceStart),
        call(onUpdateReferenceStart),
        // call(onUserSignOutFct),
    ]);
}

