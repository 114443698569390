import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    user => user.currentUser,
);

export const selectSignInError= createSelector(
    [selectUser],
    user => user.error
);

export const selectLoading= createSelector(
    [selectUser],
    user => user.loading,
);

export const selectCheckLoading= createSelector(
    [selectUser],
    user => user.checkLoading,
);


/////////users crud
export const selectUsers  = createSelector(
    [selectUser],
    user => user.users,
);

export const selectIsFetchingUser = createSelector(
    [selectUser],
    user => user.isFetchingUser,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectUser],
    user => user.addStatus
);

export const selectAddError = createSelector(
    [selectUser],
    user => user.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectUser],
    user => user.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectUser],
    user => user.deleteLoading
);
export const selectDeleteError = createSelector(
    [selectUser],
    user => user.userNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectUser],
    user => user.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectUser],
    user => user.updateErrorMessage,
)

export const selectCurrentUserForCrud = createSelector(
    [selectUser],
    user => user.currentUserForCrud
);

export const selectUpdateStatus = createSelector(
    [selectUser],
    user => user.updateStatus,
);




