import React, {useEffect} from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {deleteEventStart, fetchEventsStart} from "../../redux/events/events.actions";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectEvents,
    selectIsFetchingEv
} from "../../redux/events/events.selectors";


const EventList = ({ setCurrentPage,deleteError,fetchEvents, isFetching, events,deleteLoading,deleteEventStart, history}) => {

    useEffect(() => {
        fetchEvents();
    },[fetchEvents]);

    useEffect(() => {
        setCurrentPage('events');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    list des événements disponible
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/events/add'>
                        <i className="fas fa-plus"/> Ajouter un Evénement
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content" style={{width: '92%'}}>
               {
                   deleteError
                       ? <div className="ui error message">
                           <div className="header">
                               un message d'erreur apparait !
                           </div>
                           <ul className="list">
                               <li>la Categorie a été est supprimé deja </li>
                               <li>ou bien le serveur a été arrêté</li>
                           </ul>
                       </div>

                       : null
               }
               {isFetching
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   : <React.Fragment>
                       {
                           deleteLoading
                               ? <div className="detail-loader"><LoaderConetnt/></div>
                               : events.length > 0
                               ?  <Table compact celled definition>
                                   <Table.Header>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell>image</Table.HeaderCell>
                                           <Table.HeaderCell>titre</Table.HeaderCell>
                                           <Table.HeaderCell className={"sub-column des-column-min "}>Date D'événement</Table.HeaderCell>
                                           <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                           <Table.HeaderCell>Action</Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Header>
                                   <Table.Body>
                                       {
                                           events.map(ev => {
                                               return(
                                                   <Table.Row key={ev.id}>
                                                       <Table.Cell collapsing>
                                                           {/*<Checkbox slider />*/}
                                                       </Table.Cell>
                                                       <Table.Cell className="slider-img-td"><img src={"/images/event.png"} alt="" className="slider-img"/></Table.Cell>
                                                       <Table.Cell>{ev.title}</Table.Cell>
                                                       <Table.Cell className={"sub-column des-column-min"} >{ev.date}</Table.Cell>
                                                       <Table.Cell>{ev.created_at}</Table.Cell>
                                                       <Table.Cell className="slider-action">
                                                           <Button.Group className="slider-action-btn">
                                                               <Button  onClick={() => history.push(`/home/events/view/${ev.id}`)}><i className="far fa-eye" /> Afficher </Button>
                                                               <Button.Or text="ou"/>
                                                               <Button color={"black"} onClick={() => deleteEventStart(ev) }><i className="fas fa-trash"/> supprimer</Button>
                                                           </Button.Group>
                                                       </Table.Cell>
                                                   </Table.Row>
                                               )
                                           })
                                       }
                                   </Table.Body>

                                   <Table.Footer fullWidth>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell colSpan='8'>
                                           </Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Footer>
                               </Table>
                               :
                               <React.Fragment>
                                   <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                               </React.Fragment>
                       }
                   </React.Fragment>
               }
           </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching : selectIsFetchingEv,
    deleteLoading : selectDeleteLoading,
    deleteError : selectDeleteError,
    events : selectEvents,
});

const mapDispatchToProps = dispatch => ({
    fetchEvents : () => dispatch(fetchEventsStart()),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    deleteEventStart : e => dispatch(deleteEventStart(e)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EventList));
