import UserActionTypes from "./user.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';
import Axios from "axios";
import jwt_decode from "jwt-decode";
import cookie from 'js-cookie';

import {
    addUserFailure,
    addUserSuccess,
    deleteUserFailure, deleteUserSuccess,
    fetchUsersFailure,
    fetchUsersSuccess, getUserFailure, getUserSuccess,
    signInFailure,
    signInSuccess,
    signOutFailure,
    signOutSuccess,
    updateUserFailure, updateUserSuccess
} from "./user.actions";
import {clearVariables} from "../slliders/slider.actions";



export function* signIn({payload: {email, password}}) {
    const data = {email, password};
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/login", data);
        const user = response.data.user;
        yield put(signInSuccess({id: user.id, ...user}));
    } catch (error) {
        yield put(signInFailure(error.response.data));
    }
}

export function* isUserAuthenticated() {
    try{
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/profile");
        const user = response.data;
        yield put(signInSuccess({id: user.id, ...user}));
    }catch (error) {
        yield put(signInFailure(error.response.data.message));
    }
}

export function* refreshUserSession() {
    try{
        const  tokenExpiration = jwt_decode(cookie.get("access_token")).exp;
        console.log(tokenExpiration*1000-Date.now());
        if ((tokenExpiration*1000-Date.now()) < 300000){
            const response = yield Axios.post("https://www.admin.aqua-vim.com/api/auth/refresh");
            cookie.set("access_token", response.data.access_token);
        }

    }catch (error) {
        yield put(signInFailure(error.response.data.message));
    }
}

// export function* SignUp({payload: { name, email, password}}) {
//     const data = { name, email, password};
//     try {
//         const response = yield Axios.post("https://www.admin.aqua-vim.com/api/register", data );
//         const user = response.data.user;
//         const password = response.data.password;
//         yield put(signUpSuccess({id: user.id, password: password, ...user}));
//      }catch (error) {
//         yield put(signUpFailure(error.response.data));
//     }
// }

export function* SignOut() {
    try {
        const res = yield Axios.post("https://www.admin.aqua-vim.com/api/logout");
        yield (put(signOutSuccess()));
    }catch (error) {

        yield put(signOutFailure())
    }
}




export function* fetchUsersAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/users");
        const users = response.data.users;
        yield put(fetchUsersSuccess(users));
    } catch (error) {
        yield put(fetchUsersFailure(error.message));
    }
}


export function* addUsersAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/users", formData);
        yield put(addUserSuccess());
    } catch (error) {
        yield put(addUserFailure(error.response.data));
    }
}

export function* getUsersAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/users/${id}`);
        const user = response.data.user;
        yield put(getUserSuccess(user));
    } catch (error) {
        yield put(getUserFailure(error.message));
    }
}

export function* deleteUsersAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/users/${id}`);
        yield put(deleteUserSuccess({id: id}));
    } catch (error) {
        console.log(error)
        if (error.response.status === 422) {
            yield put(deleteUserFailure({id: id}));
        }

    }
}

// export function* clearUsersVariables() {
//     yield put(clearVariables())
// }

export function* updateUsersAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/users/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        );
        yield put(updateUserSuccess());
    } catch (error) {
        yield put(updateUserFailure(error.response.data));
    }
}

export function* onFetchUsers() {
    yield takeLatest(UserActionTypes.FETCH_USERS_START, fetchUsersAsync)
}

export function* onAddUserStart() {
    yield takeLatest(UserActionTypes.ADD_USER_START, addUsersAsync)
}

export function* onDeleteUserStart() {
    yield takeLatest(UserActionTypes.DELETE_USER_START, deleteUsersAsync)
}

export function* onGetUserStart() {
    yield takeLatest(UserActionTypes.GET_USER_START, getUsersAsync)
}

export function* onUpdateUserStart() {
    yield takeLatest(UserActionTypes.UPDATE_USER_START, updateUsersAsync)
}

// export function* onUserSignOutFct() {
//     yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearUsersVariables)
// }

export function* signInAfterSignUp({payload: { email, password}}) {
    yield signIn({payload: {email, password}});
}

export function* onSignInStart() {
    yield takeLatest(UserActionTypes.SIGN_IN_START, signIn)
}

export function* onCheckUserSession() {
    yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated)
}

export function* onRefreshUserSession() {
    yield takeLatest(UserActionTypes.REFRESH_USER_SESSION, refreshUserSession)
}

export function* onSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, SignOut)
}

// export function* onSignUpStart() {
//     yield takeLatest(UserActionTypes.SIGN_UP_START, SignUp)
// }

export function* onSignUpSuccess() {
    yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp)
}

export function* userSagas() {
    yield all([
        call(onSignInStart),
        call(onCheckUserSession),
        call(onRefreshUserSession),
        call(onSignOutStart),
        // call(onSignUpStart),
        call(onSignUpSuccess),

        //crud
        call(onFetchUsers),
        call(onAddUserStart),
        call(onDeleteUserStart),
        call(onGetUserStart),
        call(onUpdateUserStart),
    ]);
}
