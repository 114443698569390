const ConfigActionsTypes = {
    GET_CONFIG_START : 'GET_CONFIG_START',
    GET_CONFIG_SUCCESS : 'GET_CONFIG_SUCCESS',
    GET_CONFIG_FAILURE : 'GET_CONFIG_FAILURE',
    UPDATE_CONFIG_START : 'UPDATE_CONFIG_START',
    UPDATE_CONFIG_SUCCESS : 'UPDATE_CONFIG_SUCCESS',
    UPDATE_CONFIG_FAILURE : 'UPDATE_CONFIG_FAILURE',
};

export default ConfigActionsTypes;
