import ServicesActionsTypes from "./services.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';

import Axios from "axios";
import {
    addServiceFailure,
    addServiceSuccess, deleteServiceFailure, deleteServiceSuccess,
    fetchServicesFailure,
    fetchServicesSuccess, getServiceFailure,
    getServiceSuccess, updateServiceFailure, updateServiceSuccess
} from "./services.actions";


export function* fetchServicesAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/services");
        const services = response.data.services;
        console.log(services);
        yield put(fetchServicesSuccess(services));
    } catch (error) {
        yield put(fetchServicesFailure(error.message));
    }
}


export function* addServiceAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/services", formData);
        yield put(addServiceSuccess());
    } catch (error) {
        yield put(addServiceFailure(error.response.data));
    }
}

export function* getServiceAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/services/${id}`);
        const service = response.data.service;
        yield put(getServiceSuccess(service));
    } catch (error) {
        yield put(getServiceFailure(error.message));
    }
}

export function* deleteServiceAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/services/${id}`);
        yield put(deleteServiceSuccess({id: id}));
    } catch (error) {
        if (error.response.status === 422) {
            yield put(deleteServiceFailure({id: id}));
        }

    }
}

// export function* clearServicesVariables() {
//     yield put(clearVariables())
// }

export function* updateServiceAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/services/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateServiceSuccess());
    } catch (error) {
        yield put(updateServiceFailure(error.response.data));
    }
}

export function* onFetchServices() {
    yield takeLatest(ServicesActionsTypes.FETCH_SERVICES_START, fetchServicesAsync)
}

export function* onAddServiceStart() {
    yield takeLatest(ServicesActionsTypes.ADD_SERVICE_START, addServiceAsync)
}

export function* onDeleteServiceStart() {
    yield takeLatest(ServicesActionsTypes.DELETE_SERVICE_START, deleteServiceAsync)
}

export function* onGetServiceStart() {
    yield takeLatest(ServicesActionsTypes.GET_SERVICE_START, getServiceAsync)
}

export function* onUpdateServiceStart() {
    yield takeLatest(ServicesActionsTypes.UPDATE_SERVICE_START, updateServiceAsync)
}

// export function* onUserSignOutFct() {
//     yield takeLatest(ServicesActionsTypes.SIGN_OUT_SUCCESS, clearServicesVariables)
// }

export function* ServiceSagas() {
    yield all([
        call(onFetchServices),
        call(onAddServiceStart),
        call(onDeleteServiceStart),
        call(onGetServiceStart),
        call(onUpdateServiceStart),
        // call(onUserSignOutFct),
    ]);
}

