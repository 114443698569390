import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {addCategoryStart} from "../../redux/categories/categories.actions";
import Resizer from "react-image-file-resizer";
import {sendMessageStart} from "../../redux/clients/cleints.actions";
import {selectSendMsgError, selectSendMsgLoading, selectSendMsgStatus} from "../../redux/clients/clients.selectors";
import {toast, ToastContainer} from "react-toastify";



const Newsletter = ({ setCurrentPage, sendMsgStart, sendLoading, sendStatus, sendErrors, history}) => {
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");
    const [notifState, setNotifState] = useState(false);

    //for request errors
    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [dTitleError, setDTitleError] = useState('');
    const [fTitleError, setFTitleError] = useState('');
    const [subtitleError, setSubtitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    //form
    const [categoryCredentials, setCredentials] = useState({ title: '', subtitle: '', description: ''});
    const { title, subtitle, description, fTitle, dTitle } = categoryCredentials;

    useEffect(() => {
        setCurrentPage('newsletter');
    },[setCurrentPage]);

    useEffect(() => {
        if (sendErrors) {
            if (sendErrors.image_path) setImageError(sendErrors.image_path);
            if (sendErrors.title) setTitleError(sendErrors.title);
            if (sendErrors.f_title) setFTitleError(sendErrors.f_title);
            if (sendErrors.D_title) setDTitleError(sendErrors.D_title);
            if (sendErrors.subtitle) setSubtitleError(sendErrors.subtitle);
            if (sendErrors.description) setDescriptionError(sendErrors.description);
            if (
                !sendErrors.image_path && !sendErrors.title &&
                !sendErrors.subtitle   &&   !sendErrors.description
            )setServerError(sendErrors);
        }
    }, [sendErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        // setImage_path(event.target.files[0]);
        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setImage_path(image);
        } catch (err) {
            console.log(err);
        }

    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "JPEG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const handleChange = event => {
        const {value, name} = event.target;
        setCredentials({...categoryCredentials, [name]: value});
    };
    const sendHandler = async ()  => {
        const formData = new FormData();
        formData.append('image_path',image_path);
        formData.append('title',title);
        formData.append('d_title',dTitle);
        formData.append('f_title',fTitle);
        formData.append('subtitle',subtitle);
        formData.append('description',description);
        setTitleError('');
        setSubtitleError('');
        setDescriptionError('');
        setImageError('');
        sendMsgStart({formData});
        setNotifState(true);
    }

    if (sendStatus && notifState) {
        toast.dark(
            <div><i className="fas fa-check-circle"/> Newsletter a éte envoyé !!</div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                draggable: true,
                toastId: 'first_toast',
            })
        setNotifState(false);


    }

    return (

        <React.Fragment>
            <ToastContainer/>
            <div className="overview">

                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/clients')}><i className="fas fa-arrow-left"  /></span> Envoyer une newsletter
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    sendLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                serverError
                                    ? <div className="ui negative message">
                                        <div className="header danger-text" >
                                            le serveur a été arrêté
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>
                                    :<div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    {
                                                        imageError
                                                            ? <div className="ui negative message img-err">
                                                                <div className="header danger-text header-err" >
                                                                    Aucune image a ete sélectionné !
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : <React.Fragment>
                                                                {
                                                                    image
                                                                        ?
                                                                        <img src={image} className="add-ref-image" alt=""/>
                                                                        :
                                                                        <img src={'/images/news.png'} className="add-ref-image add-cat-img" alt=""/>
                                                                }
                                                            </React.Fragment>
                                                    }

                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Titre:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Title..."
                                                                            name="title"
                                                                            value={title}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        titleError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Sous Titre:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Sous Titre..."
                                                                            name="subtitle"
                                                                            value={subtitle}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        subtitleError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className='eight wide column formGroup'>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (1500*1000)px format JPG</span>

                                                                    <span className="label">
                                                        <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="sixteen wide column  formGroup">
                                                    <label htmlFor="">Description Titre:</label>
                                                    <div className="field">
                                                        <div className="ui right corner labeled input">
                                                            <input
                                                                type="text"
                                                                placeholder="Title..."
                                                                name="dTitle"
                                                                value={dTitle}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="ui right corner label">
                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                            </div>
                                                        </div>
                                                        {
                                                            dTitleError
                                                                ?
                                                                <div className="ui pointing red basic label">
                                                                    <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                </div>
                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                    <label htmlFor="">Description:</label>
                                                    <div className="field">
                                                        <div className="ui right corner labeled input">
                                                                    <textarea
                                                                        placeholder="Description..."
                                                                        name="description"
                                                                        value={description}
                                                                        onChange={handleChange}
                                                                    />
                                                            <div className="ui right corner label">
                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                            </div>
                                                        </div>
                                                        {
                                                            descriptionError
                                                                ?
                                                                <div className="ui pointing red basic label">
                                                                    <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                </div>
                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="sixteen wide column  formGroup">
                                                    <label htmlFor="">Titre</label>
                                                    <div className="field">
                                                        <div className="ui right corner labeled input">
                                                            <input
                                                                type="text"
                                                                placeholder="Title..."
                                                                name="fTitle"
                                                                value={fTitle}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="ui right corner label">
                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                            </div>
                                                        </div>
                                                        {
                                                            fTitleError
                                                                ?
                                                                <div className="ui pointing red basic label">
                                                                    <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                </div>
                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/clients`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} className="form-btn"  onClick={sendHandler}> <i className="fas fa-plus"/> Envoyer</Button>
                                        </Button.Group>
                                    </div>
                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    sendLoading: selectSendMsgLoading,
    sendStatus: selectSendMsgStatus,
    sendErrors: selectSendMsgError,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    sendMsgStart: msgData => dispatch(sendMessageStart(msgData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Newsletter));