import UserActionTypes from "./user.types";


export const signInStart = emailAndPassword => ({
    type : UserActionTypes.SIGN_IN_START,
    payload : emailAndPassword,
});

export const signInSuccess = user => ({
   type : UserActionTypes.SIGN_IN_SUCCESS,
   payload : user,
});

export const signInFailure = error => ({
   type : UserActionTypes.SIGN_IN_FAILURE,
   payload : error,
});

export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION,
});

export const refreshUserSession = () => ({
    type: UserActionTypes.REFRESH_USER_SESSION,
});

export const signOutStart = () => ({
    type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
    type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = error => ({
    type: UserActionTypes.SIGN_OUT_FAILURE,
    payload: error,
});


export const fetchUsersStart = () => ({
    type : UserActionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = users => ({
    type : UserActionTypes.FETCH_USERS_SUCCESS,
    payload : users,
});

export const fetchUsersFailure = errorMessage => ({
    type : UserActionTypes.FETCH_USERS_FAILURE,
    payload : errorMessage,
});

export const addUserStart = userInformation =>  ({
    type : UserActionTypes.ADD_USER_START,
    payload : userInformation
});

export const addUserSuccess = () =>  ({
    type : UserActionTypes.ADD_USER_SUCCESS,
});

export const addUserFailure = errorMessage => ({
    type : UserActionTypes.ADD_USER_FAILURE,
    payload : errorMessage,
});

export const deleteUserStart = user =>  ({
    type : UserActionTypes.DELETE_USER_START,
    payload : user,
});

export const deleteUserSuccess = user => ({
    type : UserActionTypes.DELETE_USER_SUCCESS,
    payload : user,
});

export const deleteUserFailure = user => ({
    type : UserActionTypes.DELETE_USER_FAILURE,
    payload : user,
});

export const getUserStart = user =>  ({
    type : UserActionTypes.GET_USER_START,
    payload : user,
});

export const getUserSuccess = user =>  ({
    type : UserActionTypes.GET_USER_SUCCESS,
    payload : user,
});

export const getUserFailure = errorMessage => ({
    type : UserActionTypes.GET_USER_FAILURE,
    payload : errorMessage,
});

export const updateUserStart = user =>  ({
    type : UserActionTypes.UPDATE_USER_START,
    payload : user,
});

export const updateUserSuccess = () =>  ({
    type : UserActionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFailure = errorMessage => ({
    type : UserActionTypes.UPDATE_USER_FAILURE,
    payload : errorMessage,
});




