import CategoriesActionsTypes from "./subcategory.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';

import Axios from "axios";
import {
    addSubcategoryFailure, addSubcategorySuccess, clearVariables, deleteSubcategoryFailure, deleteSubcategorySuccess,
    fetchSubcategoriesFailure,
    fetchSubcategoriesSuccess, getSubcategoryFailure,
    getSubcategorySuccess, updateSubcategoryFailure, updateSubcategorySuccess
} from "./subcategories.actions";


export function* fetchSubcategoriesAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/subcategories/${id}/edit`);
        const Categories = response.data.subcategories;
        yield put(fetchSubcategoriesSuccess(Categories));
    } catch (error) {
        yield put(fetchSubcategoriesFailure(error.message));
    }
}


export function* addSubcategoriesAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/subcategories", formData);
        yield put(addSubcategorySuccess());
    } catch (error) {
        yield put(addSubcategoryFailure(error.response.data));
    }
}

export function* getSubcategoriesAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/subcategories/${id}`);
        const Category = response.data.productsCategory;
        yield put(getSubcategorySuccess(Category));
    } catch (error) {
        yield put(getSubcategoryFailure(error.message));
    }
}

export function* deleteSubcategoriesAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/subcategories/${id}`);
        yield put(deleteSubcategorySuccess({id: id}));
    } catch (error) {
        console.log(error)
        if (error.response.status === 422) {
            yield put(deleteSubcategoryFailure({id: id}));
        }

    }
}
//
// export function* clearSubcategoriesVariables() {
//     yield put(clearVariables())
// }

export function* updateSubcategoriesAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/subcategories/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateSubcategorySuccess());
    } catch (error) {
        yield put(updateSubcategoryFailure(error.response.data));
    }
}

export function* onFetchSubcategories() {
    yield takeLatest(CategoriesActionsTypes.FETCH_SUBCATEGORIES_START, fetchSubcategoriesAsync)
}

export function* onAddSubcategoryStart() {
    yield takeLatest(CategoriesActionsTypes.ADD_SUBCATEGORY_START, addSubcategoriesAsync)
}

export function* onDeleteSubcategoryStart() {
    yield takeLatest(CategoriesActionsTypes.DELETE_SUBCATEGORY_START, deleteSubcategoriesAsync)
}

export function* onGetSubcategoryStart() {
    yield takeLatest(CategoriesActionsTypes.GET_SUBCATEGORY_START, getSubcategoriesAsync)
}

export function* onUpdateSubcategoryStart() {
    yield takeLatest(CategoriesActionsTypes.UPDATE_SUBCATEGORY_START, updateSubcategoriesAsync)
}

// export function* onUserSignOutFct() {
//     yield takeLatest(CategoriesActionsTypes.SIGN_OUT_SUCCESS, clearSubcategoriesVariables)
// }

export function* SubcategorySagas() {
    yield all([
        call(onFetchSubcategories),
        call(onAddSubcategoryStart),
        call(onDeleteSubcategoryStart),
        call(onGetSubcategoryStart),
        call(onUpdateSubcategoryStart),
        // call(onUserSignOutFct),
    ]);
}

