import ConfigActionsTypes from "./config.types";
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {getConfigFailure, getConfigSuccess, updateConfigFailure, updateConfigSuccess} from "./config.actions";
import Axios from "axios";



export function* getConfigAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/config");
        const Config = response.data.config;
        yield put(getConfigSuccess(Config));
    } catch (error) {
        yield put(getConfigFailure(error.message));
    }
}

export function* updateConfigAsync({payload: {id, formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/config/${id}`, formData,
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
        yield put(updateConfigSuccess());
    } catch (error) {
        yield put(updateConfigFailure(error.response.data));
    }
}


export function* onGetConfigStart() {
    yield takeLatest(ConfigActionsTypes.GET_CONFIG_START, getConfigAsync)
}

export function* onUpdateConfigStart() {
    yield takeLatest(ConfigActionsTypes.UPDATE_CONFIG_START, updateConfigAsync)
}


export function* ConfigSagas() {
    yield all([
        call(onGetConfigStart),
        call(onUpdateConfigStart),
    ]);
}

