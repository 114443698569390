import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/slliders/slider.selectors";
import {addSliderStart} from "../../redux/slliders/slider.actions";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import Resizer from "react-image-file-resizer";

const AddSlider = ({addLoading, addSliderStart, history, addStatus, addErrors}) => {
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");

    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');

    useEffect(() => {
        if (addErrors) {
            if (addErrors.image_path) {
                setImageError(addErrors.image_path)
            } else {
                setServerError(addErrors.serverError)
            }
        }
    }, [addErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        try {
            const file = event.target.files[0];
            // const image = await resizeFile(file);
            setImage_path(file);
        } catch (err) {
            console.log(err);
        }

    }

    // const resizeFile = (file) =>
    //     new Promise((resolve) => {
    //         Resizer.imageFileResizer(
    //             file,
    //             1500,
    //             800,
    //             "JPEG",
    //             72,
    //             0,
    //             (blob) => {
    //                 resolve(blob);
    //             },
    //             'blob'
    //         );
    //     });


    const addSliderHandler = async () => {
        const formData = new FormData();
        formData.append('image_path', image_path);
        addSliderStart({formData});
    }
    if (addStatus) return (<Redirect to="/home/slides/list"/>);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    <span onClick={() => history.push(`/home/slides/list`)}><i
                        className="fas fa-arrow-left"/></span> Ajouter une nouvelle diapositive
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-content">
                {
                    addLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <div className="slider-img__block">
                            {
                                image && !addErrors
                                    ? <img id="target" src={image} alt="" className="slider-image"/>
                                    : <React.Fragment>
                                        <div className="button-wrapper add-button-wrapper">
                                    <span className="label">
                                        <i className="fas fa-file-upload"/> Upload File
                                    </span>
                                            <input
                                                type="file"
                                                name="image_path"
                                                id="upload"
                                                className="upload-box"
                                                placeholder="Upload File"
                                                onChange={fileSelectedHandler}

                                            />
                                        </div>
                                        {
                                            imageError
                                                ? <div className="ui negative message">
                                                    <div className="header danger-text">
                                                        Aucune image a ete sélectionné !
                                                    </div>
                                                    <p>veuillez réessayer
                                                    </p></div>
                                                : null
                                        }
                                        {
                                            serverError
                                                ? <div className="ui negative message">
                                                    <div className="header danger-text">
                                                        le serveur a été arrêté
                                                    </div>
                                                    <p>veuillez réessayer
                                                    </p></div>
                                                : null
                                        }
                                    </React.Fragment>
                            }
                            <span>Image (1366*766)px format JPG</span>
                            <Button.Group className=" btn-block ">
                                <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/slides/list`)}>
                                    <i className="fas fa-arrow-left"></i> Reteur </Button>
                                <Button.Or text="ou"/>
                                <Button color={"black"} size={'big'} className="form-btn" onClick={addSliderHandler}> <i
                                    className="fas fa-plus"></i> Ajouter</Button>
                            </Button.Group>
                        </div>
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
});

const mapDispatchToProps = dispatch => ({
    addSliderStart: sliderData => dispatch(addSliderStart(sliderData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSlider));
