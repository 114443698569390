import React, {useEffect} from 'react';
import {Table} from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {fetchClientsStart} from "../../redux/clients/cleints.actions";
import {selectClients, selectIsFetchingClients} from "../../redux/clients/clients.selectors";

const SubscribedClients = ({setCurrentPage, fetchClients, isFetching, clients,  history}) => {

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    useEffect(() => {
        setCurrentPage('newsletter');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    liste d'abonnés
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/newsletter'>
                        <i className="fas fa-plus"/> Envoyer une newsletter
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content" style={{width: '92%'}}>

                {isFetching
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        {clients.length > 0
                                ? <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell>id</Table.HeaderCell>
                                            <Table.HeaderCell
                                                className={"sub-column des-column-min "}>email</Table.HeaderCell>
                                            <Table.HeaderCell style={{textAlign: 'center'}}>date d'inscription </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            clients.map(client => {
                                                return (
                                                    <Table.Row key={client.id}>
                                                        <Table.Cell collapsing>
                                                            {/*<Checkbox slider />*/}
                                                        </Table.Cell>
                                                        <Table.Cell>{client.id}</Table.Cell>
                                                        <Table.Cell>{client.email}</Table.Cell>
                                                        <Table.Cell style={{textAlign: 'center'}}>{client.created_at}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>

                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell colSpan='8'>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                                :
                                <React.Fragment>
                                    <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectIsFetchingClients,
    clients: selectClients,
});

const mapDispatchToProps = dispatch => ({
    fetchClients: () => dispatch(fetchClientsStart()),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscribedClients));
