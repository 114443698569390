import SlidersActionsTypes from "./slider.types";
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
    addSliderFailure,
    addSliderSuccess,
    deleteSliderFailure,
    deleteSliderSuccess,
    fetchSlidersFailure,
    fetchSlidersSuccess,
    getSliderFailure,
    getSliderSuccess,
    updateSliderFailure,
    updateSliderSuccess
} from "./slider.actions";
import Axios from "axios";


export function* fetchSlidersAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/sliders");
        const Sliders = response.data.sliders;
        yield put(fetchSlidersSuccess(Sliders));
    } catch (error) {
        yield put(fetchSlidersFailure(error.message));
    }
}


export function* addSlidersAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/sliders", formData);
        yield put(addSliderSuccess());
        console.log(response);
    } catch (error) {
        console.log(error);
        yield put(addSliderFailure(error.response.data));
    }
}

export function* getSlidersAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/sliders/${id}`);
        const Slider = response.data.slider;
        yield put(getSliderSuccess(Slider));
    } catch (error) {
        yield put(getSliderFailure(error.message));
    }
}

export function* deleteSlidersAsync({payload: {id}}) {

    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/sliders/${id}`);
        yield put(deleteSliderSuccess({id: id}));
    } catch (error) {
        if (error.response.status === 422) {
            yield put(deleteSliderFailure({id: id}));
        }

    }
}


export function* updateSlidersAsync({payload: {id, formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/sliders/${id}`, formData,
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
        yield put(updateSliderSuccess());
    } catch (error) {
        yield put(updateSliderFailure(error.response.data));
    }
}

export function* onFetchSliders() {
    yield takeLatest(SlidersActionsTypes.FETCH_SLIDERS_START, fetchSlidersAsync)
}

export function* onAddSliderStart() {
    yield takeLatest(SlidersActionsTypes.ADD_SLIDER_START, addSlidersAsync)
}

export function* onDeleteSliderStart() {
    yield takeLatest(SlidersActionsTypes.DELETE_SLIDER_START, deleteSlidersAsync)
}

export function* onGetSliderStart() {
    yield takeLatest(SlidersActionsTypes.GET_SLIDER_START, getSlidersAsync)
}

export function* onUpdateSliderStart() {
    yield takeLatest(SlidersActionsTypes.UPDATE_SLIDER_START, updateSlidersAsync)
}


export function* sliderSagas() {
    yield all([
        call(onFetchSliders),
        call(onAddSliderStart),
        call(onDeleteSliderStart),
        call(onGetSliderStart),
        call(onUpdateSliderStart),
    ]);
}

