import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import '@fortawesome/fontawesome-free/js/all';
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./redux/store";
import { store } from "./redux/store";
import {Provider} from "react-redux";
import SetupAxios from './AxiosInterceptor';

import axios from "axios";
axios.defaults.withCredentials = true;
axios.get( "https://admin.aqua-vim.com/sanctum/csrf-cookie");

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <PersistGate persistor={persistor}>
                  <SetupAxios>
                      <App/>
                  </SetupAxios>
              </PersistGate>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
