import React, {useEffect} from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import Overview from "../overview/overview.component";
import {deleteReferenceStart, fetchReferencesStart} from "../../redux/references/reference.actions";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetchingRef, selectReferences
} from "../../redux/references/reference.selectors";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";

const ReferencesList = ({ fetchSlidersStart, setCurrentPage,deleteError,fetchReferences, isFetching, references,deleteLoading,deleteReferenceStart, history}) => {

    useEffect(() => {
        fetchReferences();
    },[fetchReferences],[setCurrentPage]);

    useEffect(() => {
        setCurrentPage('references');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    list des Réferences disponible
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/references/add'>
                        <i className="fas fa-plus"/> Ajouter une Réference
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content">
               {
                   deleteError
                       ? <div className="ui error message">
                           <div className="header">
                               un message d'erreur apparait !
                           </div>
                           <ul className="list">
                               <li>le slider a été est supprimé deja </li>
                               <li>ou bien le serveur a été arrêté</li>
                           </ul>
                       </div>

                       : null
               }
               {isFetching
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   : <React.Fragment>
                       {
                           deleteLoading
                               ? <div className="detail-loader"><LoaderConetnt/></div>
                               : references.length > 0
                               ?  <Table compact celled definition>
                                   <Table.Header>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell>Image</Table.HeaderCell>
                                           <Table.HeaderCell>Nom</Table.HeaderCell>
                                           <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                           <Table.HeaderCell>Date de modification</Table.HeaderCell>
                                           <Table.HeaderCell>Action</Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Header>
                                   <Table.Body>
                                       {
                                           references.map(ref => {
                                               return(
                                                   <Table.Row key={ref.id}>
                                                       <Table.Cell collapsing>
                                                           {/*<Checkbox slider />*/}
                                                       </Table.Cell>
                                                       <Table.Cell className="slider-img-td"><img src={`https://www.admin.aqua-vim.com/${ref.image_path}`} alt="" className="slider-img"/></Table.Cell>
                                                       <Table.Cell>{ref.name}</Table.Cell>
                                                       <Table.Cell>{ref.created_at}</Table.Cell>
                                                       <Table.Cell>{ref.state ? 'Terminé' : 'En Cours'}</Table.Cell>
                                                       {/*<Table.Cell>{ref.updated_at}</Table.Cell>*/}
                                                       <Table.Cell className="slider-action">
                                                           <Button.Group className="slider-action-btn">
                                                               <Button  onClick={() => history.push(`/home/references/view/${ref.id}`)}><i className="far fa-eye" /> Afficher </Button>
                                                               <Button.Or text="ou"/>
                                                               <Button color={"black"} onClick={() => deleteReferenceStart(ref) }><i className="fas fa-trash"/> supprimer</Button>
                                                           </Button.Group>
                                                       </Table.Cell>
                                                   </Table.Row>
                                               )
                                           })
                                       }
                                   </Table.Body>

                                   <Table.Footer fullWidth>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell colSpan='8'>
                                               <Button
                                                   floated='right'
                                                   icon
                                                   labelPosition='left'
                                                   color={'black'}
                                                   size='medium'
                                                   onClick={() => history.push('/home/references/add')}
                                               >
                                                   <Icon name='image'  /> Ajouter une Référence
                                               </Button>

                                           </Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Footer>
                               </Table>
                               :   <React.Fragment>
                                   <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                               </React.Fragment>
                       }
                   </React.Fragment>
               }
           </div>

        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching : selectIsFetchingRef,
    deleteLoading : selectDeleteLoading,
    deleteError : selectDeleteError,
    references : selectReferences,
});

const mapDispatchToProps = dispatch => ({
    fetchReferences : () => dispatch(fetchReferencesStart()),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    deleteReferenceStart : ref => dispatch(deleteReferenceStart(ref)),
    // setPageTitle : title => dispatch(setPageTittle(title)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ReferencesList));
