import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {createStructuredSelector} from "reselect";
import HomePage from "./pages/home-page/home-page.components";
import SignIn from "./pages/sign-in/sign-in.component";
import {selectCheckLoading, selectCurrentUser} from "./redux/users/user.selectors";
import {checkUserSession, refreshUserSession} from "./redux/users/user.actions";
import Loader from "./components/loader/loader.compoenent";



function App({currentUser,checkLoading,checkUserSession,refreshUserSession}) {
    useEffect(() => {
        checkUserSession()
    }, [checkUserSession]);

    // useEffect(() => {
    //
    //     if (currentUser) {
    //         refreshUserSession()
    //     }
    // }, [currentUser]);

  return (
      <React.Fragment>
          {
              checkLoading
                  ? ( <Loader/>)
                  : (
                      !currentUser ?
                          (
                              <Switch>
                                  <Route exact path="/sign-in" component={SignIn}/>
                                  <Redirect to="/sign-in" />
                              </Switch>)
                          :
                          (<Switch>
                              <Route path="/home" component={HomePage}/>
                              <Redirect to="/home"/>

                          </Switch>))
          }
      </React.Fragment>
  );
}


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    checkLoading: selectCheckLoading,
});

const mapDispatchToProps = dispatch => ({
    checkUserSession: () => dispatch(checkUserSession()),
    refreshUserSession: () => dispatch(refreshUserSession()),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);




