import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {addReferenceStart} from "../../redux/references/reference.actions";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/references/reference.selectors";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import Resizer from "react-image-file-resizer";


const AddReference = ({addLoading, setCurrentPage, addReferenceStart, history, addStatus, addErrors}) => {
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");


    //for request errors
    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');
    const [nameError, setNameError] = useState('');
    const [stateError, setStateError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [serviceError, setServiceError] = useState('');
    //form
    const [referenceCredentials, setCredentials] = useState({name: '', state: '', service: '', category: ''});
    const {name, state, category, service} = referenceCredentials;

    useEffect(() => {
        setCurrentPage('references');
    }, [setCurrentPage]);

    useEffect(() => {
        if (addErrors) {
            if (addErrors.image_path) setImageError(addErrors.image_path);
            if (addErrors.name) setNameError(addErrors.name);
            if (addErrors.category_id) setCategoryError(addErrors.category_id);
            if (addErrors.service) setServiceError(addErrors.service);
            if (addErrors.state) setStateError(addErrors.state);
            if (
                !addErrors.image_path && !addErrors.name && !addErrors.service_id &&
                !addErrors.category_id && !addErrors.name && !addErrors.state
            ) setServerError(addErrors);
        }
    }, [addErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setImage_path(image);
        } catch (err) {
            console.log(err);
        }

    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "JPEG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const handleChange = event => {
        const {value, name} = event.target;
        setCredentials({...referenceCredentials, [name]: value});
    };
    const addReferenceHandler = async () => {
        const formData = new FormData();
        formData.append('image_path', image_path);
        formData.append('name', name);
        formData.append('state', state);
        formData.append('category_id', category);
        formData.append('service', service);
        addReferenceStart({formData});
    }

    if (addStatus) history.push("/home/references/list");
    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    <span onClick={() => history.push(`/home/references/list`)}><i
                        className="fas fa-arrow-left"/></span> Ajouter une Réfeence
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    addLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                serverError
                                    ? <div className="ui negative message">
                                        <div className="header danger-text">
                                            le serveur a été arrêté
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>
                                    : <div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    {
                                                        imageError
                                                            ? <div className="ui negative message img-err">
                                                                <div className="header danger-text header-err">
                                                                    Aucune image a ete sélectionné !
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : <React.Fragment>
                                                                {
                                                                    image
                                                                        ?
                                                                        <img src={image} className="add-ref-image" alt=""/>
                                                                        :
                                                                        <img src={'/images/image-logo.png'}
                                                                             className="add-ref-image" alt=""/>
                                                                }
                                                            </React.Fragment>
                                                    }

                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Nom:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Nom..."
                                                                            name="name"
                                                                            value={name}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        nameError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="eight wide column formGroup">
                                                                <label htmlFor="">Etat:</label>
                                                                <select
                                                                    className="ui dropdown" style={{width: '93%'}}
                                                                    name='state'
                                                                    value={state}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Etat du Projet</option>
                                                                    <option value="1">projet terminé</option>
                                                                    <option value="0">projet En cours</option>
                                                                </select>
                                                                {
                                                                    stateError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Ce
                                                                            champ est obligatoire,veuillez le renseogner
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Service:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="service..."
                                                                            name="service"
                                                                            value={service}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        serviceError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="eight wide column formGroup">
                                                                <label htmlFor="">Category:</label>
                                                                <select
                                                                    className="ui dropdown" style={{width: '93%'}}
                                                                    name='category'
                                                                    value={category}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Choisissez Une Categorie...</option>
                                                                    <option value="1">Hotels</option>
                                                                    <option value="2">centres</option>
                                                                    <option value="3">Entreprises</option>
                                                                    <option value="4">Jardins</option>

                                                                </select>
                                                                {
                                                                    categoryError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> ce un
                                                                            champ est obligatoire,veuillez le renseogner
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className='eight wide column formGroup'>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (400*400)px format JPG</span>

                                                                    <span className="label">
                                                        <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'}
                                                    onClick={() => history.push(`/home/references/list`)}> <i
                                                className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} className="form-btn"
                                                    onClick={addReferenceHandler}> <i className="fas fa-plus"/> Ajouter</Button>
                                        </Button.Group>
                                    </div>
                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
});

const mapDispatchToProps = dispatch => ({
    addReferenceStart: sliderData => dispatch(addReferenceStart(sliderData)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddReference));
