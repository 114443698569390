import ClientsActionsTypes from "./clients.types";
import {all, call, put, takeLatest} from 'redux-saga/effects';

import Axios from "axios";
import {fetchClientsFailure, fetchClientsSuccess, sendMessageFailure, sendMessageSuccess} from "./cleints.actions";

export function* fetchClientsAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/newsletter");
        const clients = response.data.clients;
        yield put(fetchClientsSuccess(clients));
    } catch (error) {
        yield put(fetchClientsFailure(error.message));
    }
}

export function* sendMsgStartAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/newsletter", formData);
        yield put(sendMessageSuccess());
    } catch (error) {
        yield put(sendMessageFailure(error.response.data));
    }
}


export function* onFetchClients() {
    yield takeLatest(ClientsActionsTypes.FETCH_CLIENTS_START, fetchClientsAsync)
}


export function* onSendMsgStart() {
    yield takeLatest(ClientsActionsTypes.SEND_MESSAGE_START, sendMsgStartAsync)
}

export function* ClientSagas() {
    yield all([
        call(onSendMsgStart),
        call(onFetchClients),
    ]);
}

