import EventsActionsTypes from "./events.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //events list
    events : [],
    isFetchingEv : true,

    //add event
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete Event
    eventNotFoundError: false,
    deleteLoading : false,

    //get Event
    getEventError: null,
    currentEvent : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const eventsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //events list
        case EventsActionsTypes.FETCH_EVENTS_START:
            return {
                ...state,
                isFetchingEv: true,
                addStatus: false,
                addErrorMessage: null,
                eventNotFoundError:false,
                updateStatus: false,
                updateLoading: true,//verfierv update loading in the reference reducer
            };
        case EventsActionsTypes.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                isFetchingEv: false,
                events: action.payload,
            };
        case EventsActionsTypes.FETCH_EVENTS_FAILURE :
            return {
                ...state,
                isFetchingEv: false,
                errorMessage: action.payload,
            };

        //Add reference
        case EventsActionsTypes.ADD_EVENT_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case EventsActionsTypes.ADD_EVENT_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingEv: true,
                addErrorMessage: null,
            }
        case EventsActionsTypes.ADD_EVENT_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete reference
        case EventsActionsTypes.DELETE_EVENT_START :
            return {
                ...state,
                deleteLoading: true,
                eventNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case EventsActionsTypes.DELETE_EVENT_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                eventNotFoundError: false,
                events: state.events.filter(
                    reference => reference.id !== action.payload.id
                )
            }
        case EventsActionsTypes.DELETE_EVENT_FAILURE :
            return {
                ...state,
                eventNotFoundError: true,
                deleteLoading: false,
                events: state.events.filter(
                    reference => reference.id !== action.payload.id
                )
            }

        //get reference
        case EventsActionsTypes.GET_EVENT_START :
            return {
                ...state,
                updateLoading: true,
                getEventError:null,
            }
        case EventsActionsTypes.GET_EVENT_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getEventError: null,
                currentEvent: action.payload,
            }
        case EventsActionsTypes.GET_EVENT_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getEventError: action.payload,
                currentEvent: null,
            }


        //update
        case EventsActionsTypes.UPDATE_EVENT_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case EventsActionsTypes.UPDATE_EVENT_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingEv: true,
            }
        case EventsActionsTypes.UPDATE_EVENT_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //events list
                events : [],
                isFetchingEv : false,

                //add event
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,

                //delete Event
                eventNotFoundError: false,
                deleteLoading : false,

                //get Event
                getEventError: null,
                currentEvent : null,
                updateLoading : false,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,

            }
        default:
            return state;

    }
};

export default eventsReducer;
