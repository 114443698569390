import React, {useEffect} from 'react';
import iconSet from "../../selection.json";
import IcomoonReact, {iconList} from "icomoon-react";
import {withRouter,Link} from "react-router-dom";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentPage} from "../../redux/design-utilites/design-utilities.selectors";
import {selectCurrentUser} from "../../redux/users/user.selectors";

const SideBar = ({history,current_page,currentUser}) => {


    return (
        <nav className="sidebar">
            <ul className="side-nav">
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'slides' ? 'active': null}`}
                    onClick={() => history.push('/home/slides/list')}
                >
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="blackboard"/>
                        <span>carousel gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'references' ? 'active': null}`}
                    onClick={() => history.push('/home/references/list')}
                >
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="sweden"/>
                        <span>references gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'services' ? 'active': null}`}
                    onClick={() => history.push('/home/services/list')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="documents"/>
                        <span>Services gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'products' ? 'active': null}`}
                    onClick={() => history.push('/home/categories/list')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="price-tag"/>
                        <span>Produits gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'events' ? 'active': null}`}
                    onClick={() => history.push('/home/events/list')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="calendar"/>
                        <span>Evénement gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'partners' ? 'active': null}`}
                    onClick={() => history.push('/home/partners/list')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="colours"/>
                        <span>partenaire gestion</span>
                    </Link>
                </li>
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'newsletter' ? 'active': null}`}
                    onClick={() => history.push('/home/clients')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="message"/>
                        <span>créer une newsletter</span>
                    </Link>
                </li>
                {
                    currentUser.is_admin === 1
                    ?   <li
                            className={`side-nav__item side-nav__item--${current_page === 'users' ? 'active': null}`}
                            onClick={() => history.push('/home/users/list')}>
                            <Link to={"#"} className="side-nav__link">
                                <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="users"/>
                                <span>gestion d'utilisateurs</span>
                            </Link>
                        </li>
                     : null
                }
                <li
                    className={`side-nav__item side-nav__item--${current_page === 'config' ? 'active': null}`}
                    onClick={() => history.push('/home/config')}>
                    <Link to={"#"} className="side-nav__link">
                        <IcomoonReact iconSet={iconSet} className="side-nav__icon" size={20}  icon="cog"/>
                        <span>Configuration</span>
                    </Link>
                </li>
            </ul>

            <div className="legal">
                &copy; 2020 by Khirat Oussama. All rights reserved.
            </div>
        </nav>

    );
}

const mapStateToProps = createStructuredSelector({
    current_page: selectCurrentPage,
    currentUser: selectCurrentUser,
});


const mapDispatchToProps = dispatch => ({
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar));
