import SubcategoriesActionsTypes from "./subcategory.types";

const INITIAL_STATE = {
    //subcategories list
    subcategories : [],
    isFetchingSubCat : true,

    //add subcategory
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete Category
    subcategoryNotFoundError: false,
    deleteLoading : false,

    //get Category
    getSubcategoryError: null,
    currentSubcategory : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const subcategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //categories list
        case SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_START:
            return {
                ...state,
                isFetchingSubCat: true,
                addStatus: false,
                addErrorMessage: null,
                subcategoryNotFoundError:false,
                updateStatus: false,
                updateLoading: true,//verfierv update loading in the reference reducer
            };
        case SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingSubCat: false,
                subcategories: action.payload,
            };
        case SubcategoriesActionsTypes.FETCH_SUBCATEGORIES_FAILURE :
            return {
                ...state,
                isFetchingSubCat: false,
                // errorMessage: action.payload, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            };

        //Add reference
        case SubcategoriesActionsTypes.ADD_SUBCATEGORY_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case SubcategoriesActionsTypes.ADD_SUBCATEGORY_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingSubCat: true,
                addErrorMessage: null,
            }
        case SubcategoriesActionsTypes.ADD_SUBCATEGORY_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete reference
        case SubcategoriesActionsTypes.DELETE_SUBCATEGORY_START :
            return {
                ...state,
                deleteLoading: true,
                subcategoryNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case SubcategoriesActionsTypes.DELETE_SUBCATEGORY_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                subcategoryNotFoundError: false,
                subcategories: state.subcategories.filter(
                    reference => reference.id !== action.payload.id
                )
            }
        case SubcategoriesActionsTypes.DELETE_SUBCATEGORY_FAILURE :
            return {
                ...state,
                subcategoryNotFoundError: true,
                deleteLoading: false,
                subcategories: state.subcategories.filter(
                    reference => reference.id !== action.payload.id
                )
            }

        //get reference
        case SubcategoriesActionsTypes.GET_SUBCATEGORY_START :
            return {
                ...state,
                updateLoading: true,
                getSubcategoryError:null,
            }
        case SubcategoriesActionsTypes.GET_SUBCATEGORY_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getSubcategoryError: null,
                currentSubcategory: action.payload,
            }
        case SubcategoriesActionsTypes.GET_SUBCATEGORY_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getSubcategoryError: action.payload,
                currentSubcategory: null,
            }


        //update
        case SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingSubCat: true,
            }
        case SubcategoriesActionsTypes.UPDATE_SUBCATEGORY_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

            case SubcategoriesActionsTypes.CLEAR_VAR_SUB_CAT :
            return {
                ...state,
                loading: false,
            }
        default:
            return state;

    }
};

export default subcategoryReducer;
