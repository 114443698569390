import ServicesActionsTypes from "./services.types";

export const fetchServicesStart = () => ({
    type : ServicesActionsTypes.FETCH_SERVICES_START,
});
export const clearVariables = () => ({
    type : ServicesActionsTypes.CLEAR_VAR_REF,
});

export const fetchServicesSuccess = services => ({
    type : ServicesActionsTypes.FETCH_SERVICES_SUCCESS,
    payload : services,
});

export const fetchServicesFailure = errorMessage => ({
    type : ServicesActionsTypes.FETCH_SERVICES_FAILURE,
    payload : errorMessage,
});

export const addServiceStart = serviceInformation =>  ({
    type : ServicesActionsTypes.ADD_SERVICE_START,
    payload : serviceInformation
});

export const addServiceSuccess = () =>  ({
    type : ServicesActionsTypes.ADD_SERVICE_SUCCESS,
});

export const addServiceFailure = errorMessage => ({
    type : ServicesActionsTypes.ADD_SERVICE_FAILURE,
    payload : errorMessage,
});

export const deleteServiceStart = service =>  ({
    type : ServicesActionsTypes.DELETE_SERVICE_START,
    payload : service,
});

export const deleteServiceSuccess = service =>  ({
    type : ServicesActionsTypes.DELETE_SERVICE_SUCCESS,
    payload : service,
});

export const deleteServiceFailure = service => ({
    type : ServicesActionsTypes.DELETE_SERVICE_FAILURE,
    payload : service,
});

export const getServiceStart = service =>  ({
    type : ServicesActionsTypes.GET_SERVICE_START,
    payload : service,
});

export const getServiceSuccess = service =>  ({
    type : ServicesActionsTypes.GET_SERVICE_SUCCESS,
    payload : service,
});

export const getServiceFailure = errorMessage => ({
    type : ServicesActionsTypes.GET_SERVICE_FAILURE,
    payload : errorMessage,
});

export const updateServiceStart = service =>  ({
    type : ServicesActionsTypes.UPDATE_SERVICE_START,
    payload : service,
});

export const updateServiceSuccess = () =>  ({
    type : ServicesActionsTypes.UPDATE_SERVICE_SUCCESS,
});

export const updateServiceFailure = errorMessage => ({
    type : ServicesActionsTypes.UPDATE_SERVICE_FAILURE,
    payload : errorMessage,
});
