import ClientsActionsTypes from "./clients.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //clients
    clients : [],
    isFetchingClients: true,

    //msg
    sendErrorMessage: null,
    sendMsgLoading : false,
    sendMsgStatus : false,
};

const clientsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //clients list
        case ClientsActionsTypes.FETCH_CLIENTS_START:
            return {
                ...state,
                isFetchingClients: true,
            };
        case ClientsActionsTypes.FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                isFetchingClients: false,
                clients: action.payload,
            };
        case ClientsActionsTypes.FETCH_CLIENTS_FAILURE :
            return {
                ...state,
                isFetchingClients: false,
                errorMessage: action.payload,
            };

            //send message
        case ClientsActionsTypes.SEND_MESSAGE_START :
            return {
                ...state,
                sendMsgLoading: true,
                sendMsgStatus : false,
                sendErrorMessage: null,
            }
        case ClientsActionsTypes.SEND_MESSAGE_SUCCESS :
            return {
                ...state,
                sendMsgStatus: true,
                sendMsgLoading: false,
                sendErrorMessage: null,
            }
        case ClientsActionsTypes.SEND_MESSAGE_FAILURE :
            return {
                ...state,
                sendMsgStatus: false,
                sendErrorMessage: action.payload,
                sendMsgLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //clients
                clients : [],
                isFetchingClients: false,

                //msg
                sendErrorMessage: null,
                sendMsgLoading : false,
                sendMsgStatus : false,

            }

        default:
            return state;

    }
};

export default clientsReducer;
