import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'

import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getMarkStart, updateMarkStart} from "../../redux/marks/marks.actions";
import {
    selectCurrentMark,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/marks/maks.selectors";
import Resizer from "react-image-file-resizer";




const PartnerView = ({ updatePartnerStart, setCurrentPage,updateStatus, currentPartner, history, updateLoading, getPartnerStart, match, updateErrors}) => {
    if (updateStatus) history.push("/home/partners/list");
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");

    //for request errors
    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');
    const [nameError, setNameError] = useState('');

    //form
    const [name ,setName] = useState('');



    useEffect(() => {
        getPartnerStart({id: match.params.id});
    }, [getPartnerStart]);

    useEffect(() => {
        setCurrentPage('partners');
    },[setCurrentPage]);

    useEffect(() => {
        if (currentPartner) {
            setName(currentPartner.name);
        }
    },[currentPartner])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.image_path) setImageError(updateErrors.image_path);
            if (updateErrors.name) setNameError(updateErrors.name);
           if (
                !updateErrors.image_path && !updateErrors.name
            )setServerError(updateErrors);
        }
    }, [updateErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        // setImage_path(event.target.files[0]);
        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setImage_path(image);
        } catch (err) {
            console.log(err);
        }

    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "PNG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const addReferenceHandler = async ()  => {
        const formData = new FormData();
        formData.append('image_path',image_path);
        formData.append('name',name);
        formData.append('_method', 'PATCH');
        setNameError('');
        setImageError('');
        updatePartnerStart({ id : match.params.id, formData});
    }



    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/partners/list')}><i className="fas fa-arrow-left"  /></span> Modifier un partenaire
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    updateLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                serverError
                                    ? <div className="ui negative message">
                                        <div className="header danger-text" >
                                            le serveur a été arrêté
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>
                                    : currentPartner
                                         ? <div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    {
                                                        imageError
                                                            ? <div className="ui negative message img-err">
                                                                <div className="header danger-text header-err" >
                                                                    Aucune image a ete sélectionné !
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : <React.Fragment>
                                                                {
                                                                    image
                                                                        ?
                                                                        <img src={image} className="add-ref-image" alt=""/>
                                                                        :
                                                                        <img src={`https://www.admin.aqua-vim.com/${currentPartner.image_path}`} alt="img" className="slider-image add-cat-img" />
                                                                }
                                                            </React.Fragment>
                                                    }

                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Nom:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Nom..."
                                                                            name="name"
                                                                            value={name}
                                                                            onChange={event => setName(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        nameError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className='eight wide column formGroup'>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (1000*600)px format PNG</span>

                                                                    <span className="label">
                                                        <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/partners/list`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} className="form-btn"  onClick={addReferenceHandler}> <i className="fas fa-pen-fancy"/> modifier</Button>
                                        </Button.Group>
                                    </div>
                                        : <div className="ui negative message">
                                        <div className="header danger-text" >
                                            il y un problem
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>

                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading : selectUpdateLoading,
    currentPartner: selectCurrentMark,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getPartnerStart : p => dispatch(getMarkStart(p)),
    updatePartnerStart : p => dispatch(updateMarkStart(p)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerView));
