import UserActionTypes from "./user.types";

const INITIAL_STATE = {
    currentUser: null,
    error : null,
    loading : false,
    checkLoading : true,

    //users list
    users : [],
    isFetchingUser : true,

    //add user
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete user
    userNotFoundError: false,
    deleteLoading : false,

    //get user
    getUserError: null,
    currentUserForCrud : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,

};
const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.CHECK_USER_SESSION:
        case UserActionTypes.SIGN_OUT_START:
            return {
                ...state,
                checkLoading: true,
            };
        case UserActionTypes.SIGN_UP_START :
        case UserActionTypes.SIGN_IN_START :
            return {
              ...state,
              loading: true,
            };
        case UserActionTypes.SIGN_IN_SUCCESS :

            return {
              ...state,
              currentUser: action.payload,
              loading: false,
              checkLoading : false,
            };
        case UserActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                currentUser: null,
                error: null,
                checkLoading: false,
            };
        case UserActionTypes.SIGN_IN_FAILURE :
        case UserActionTypes.SIGN_UP_FAILURE:
            return {
              ...state,
              error: action.payload,
                loading: false,
                checkLoading : false,

            };
        case UserActionTypes.SIGN_OUT_FAILURE :
            return {
                ...state,
                error : action.payload,
                currentUser: null,
                loading: false,
            };

        //users list
        case UserActionTypes.FETCH_USERS_START:
            return {
                ...state,
                isFetchingUser: true,
                addStatus: false,
                addErrorMessage: null,
                userNotFoundError:false,
                updateStatus: false,
                updateLoading: true,
            };
        case UserActionTypes.FETCH_USERS_SUCCESS:
            return {
                ...state,
                isFetchingUser: false,
                users: action.payload,
            };
        case UserActionTypes.FETCH_USERS_FAILURE :
            return {
                ...state,
                isFetchingUser: false,
                errorMessage: action.payload,
                 }
        //Add user
        case UserActionTypes.ADD_USER_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case UserActionTypes.ADD_USER_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingUser: true,
                addErrorMessage: null,
            }
        case UserActionTypes.ADD_USER_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete user
        case UserActionTypes.DELETE_USER_START :
            return {
                ...state,
                deleteLoading: true,
                userNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case UserActionTypes.DELETE_USER_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                userNotFoundError: false,
                users: state.users.filter(
                    user => user.id !== action.payload.id
                )
            }
        case UserActionTypes.DELETE_USER_FAILURE :
            return {
                ...state,
                userNotFoundError: true,
                deleteLoading: false,
                users: state.users.filter(
                    user => user.id !== action.payload.id
                )
            }

        //get user
        case UserActionTypes.GET_USER_START :
            return {
                ...state,
                updateLoading: true,
                getUserError:null,
            }
        case UserActionTypes.GET_USER_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getUserError: null,
                currentUserForCrud: action.payload,
            }
        case UserActionTypes.GET_USER_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getUserError: action.payload,
                currentUserForCrud: null,
            }


        //update
        case UserActionTypes.UPDATE_USER_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case UserActionTypes.UPDATE_USER_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingUser: true,
            }
        case UserActionTypes.UPDATE_USER_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }


        default :
            return state;
    }
};

export default userReducer;
