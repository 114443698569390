import React, {Suspense} from 'react';
import Gallery from "../gallery/gallery-component";
import Overview from "../overview/overview.component";
import ContentRoutes from "../detail/ContentRoutes.routes";

const Main = () => {
    return (
        <main className="hotel-view">
            <Gallery/>
            {/*<Overview/>*/}
            <ContentRoutes/>
        </main>
    )
}

export default Main;