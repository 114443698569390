import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {
    selectCurrentEvent,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/events/events.selectors";
import {getEventStart, updateEventStart} from "../../redux/events/events.actions";

const EventView = ({ updateEventStart, setCurrentPage,updateStatus, currentEvent, history, updateLoading, getEventStart, match, updateErrors}) => {
    if (updateStatus) history.push("/home/events/list");

    const [serverError, setServerError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [dateError, setDateError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    //form
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');



    useEffect(() => {
        getEventStart({id: match.params.id});
    }, [getEventStart]);

    useEffect(() => {
        setCurrentPage('events');
    },[setCurrentPage]);

    useEffect(() => {
        if (currentEvent) {
            setTitle(currentEvent.title);
            setDate(currentEvent.date);
            setDescription(currentEvent.description);
        }
    },[currentEvent])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.title) setTitleError(updateErrors.title);
            if (updateErrors.date) setDateError(updateErrors.date);
            if (updateErrors.description) setDescriptionError(updateErrors.description);
            if (
                !updateErrors.title && !updateErrors.date   &&   !updateErrors.description
            )setServerError(updateErrors);
        }
    }, [updateErrors]);



    const updateEventHandler = async ()  => {
        const formData = new FormData();
        formData.append('title',title);
        formData.append('date',date);
        formData.append('description',description);
        formData.append('_method', 'PATCH');
        setTitleError('');
        setDateError('');
        setDescriptionError('');
        updateEventStart({ id : match.params.id, formData});
    }



    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/events/list')}><i className="fas fa-arrow-left"  /></span> Modifier un événement
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    updateLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                serverError
                                    ? <div className="ui negative message">
                                        <div className="header danger-text" >
                                            le serveur a été arrêté
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>
                                    : currentEvent
                                    ? <div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    <img src={'/images/event.png'} className="add-ref-image add-cat-img" alt=""/>
                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Titre:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Title..."
                                                                            name="title"
                                                                            value={title}
                                                                            onChange={event => setTitle(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        titleError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Date D'événement::</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="date"
                                                                            placeholder="Date D'événement:..."
                                                                            name="date"
                                                                            value={date}
                                                                            onChange={event => setDate(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        dateError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> ce champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Description:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                    <textarea
                                                                        placeholder="Description..."
                                                                        name="description"
                                                                        value={description}
                                                                        onChange={event => setDescription(event.target.value)}
                                                                    />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/events/list`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"green"} size={'big'} className="form-btn"  onClick={updateEventHandler}> <i className="fas fa-pen-fancy"/> modifier</Button>
                                        </Button.Group>
                                    </div>
                                    : <div className="ui negative message">
                                        <div className="header danger-text" >
                                            il y un problem
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>

                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading : selectUpdateLoading,
    currentEvent: selectCurrentEvent,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getEventStart : cat => dispatch(getEventStart(cat)),
    updateEventStart : cat => dispatch(updateEventStart(cat)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventView));
