import ServicesActionsTypes from "./services.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //services list
    services : [],
    isFetchingService : true,

    //add Service
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete Service
    serviceNotFoundError: false,
    deleteLoading : false,

    //get Service
    getServiceError: null,
    currentService : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const servicesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //services list
        case ServicesActionsTypes.FETCH_SERVICES_START:
            return {
                ...state,
                isFetchingService: true,
                addStatus: false,
                addErrorMessage: null,
                serviceNotFoundError:false,
                updateStatus: false,
                updateLoading: true,//verfierv update loading in the reference reducer
            };
        case ServicesActionsTypes.FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                isFetchingService: false,
                services: action.payload,
            };
        case ServicesActionsTypes.FETCH_SERVICES_FAILURE :
            return {
                ...state,
                isFetchingService: false,
                // errorMessage: action.payload, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            };

        //Add reference
        case ServicesActionsTypes.ADD_SERVICE_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case ServicesActionsTypes.ADD_SERVICE_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingService: true,
                addErrorMessage: null,
            }
        case ServicesActionsTypes.ADD_SERVICE_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete service
        case ServicesActionsTypes.DELETE_SERVICE_START :
            return {
                ...state,
                deleteLoading: true,
                serviceNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case ServicesActionsTypes.DELETE_SERVICE_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                serviceNotFoundError: false,
                services: state.services.filter(
                    service => service.id !== action.payload.id
                )
            }
        case ServicesActionsTypes.DELETE_SERVICE_FAILURE :
            return {
                ...state,
                serviceNotFoundError: true,
                deleteLoading: false,
                services: state.services.filter(
                    service => service.id !== action.payload.id
                )
            }

        //get service
        case ServicesActionsTypes.GET_SERVICE_START :
            return {
                ...state,
                updateLoading: true,
                getServiceError:null,
            }
        case ServicesActionsTypes.GET_SERVICE_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getServiceError: null,
                currentService: action.payload,
            }
        case ServicesActionsTypes.GET_SERVICE_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getServiceError: action.payload,
                currentService: null,
            }


        //update
        case ServicesActionsTypes.UPDATE_SERVICE_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case ServicesActionsTypes.UPDATE_SERVICE_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingService: true,
            }
        case ServicesActionsTypes.UPDATE_SERVICE_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //services list
                services : [],
                isFetchingService : false,

                //add Service
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,

                //delete Service
                serviceNotFoundError: false,
                deleteLoading : false,

                //get Service
                getServiceError: null,
                currentService : null,
                updateLoading : false,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,

            }
        default:
            return state;

    }
};

export default servicesReducer;
