import React, {useEffect} from 'react';
import {Button, Icon, Table} from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";

import {deleteServiceStart, fetchServicesStart} from "../../redux/services/services.actions";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetchingServices,
    selectServices
} from "../../redux/services/services.selectors";

const ServicesList = ({setCurrentPage, deleteError, fetchServices, isFetching, services, deleteLoading, deleteServiceStart, history}) => {

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    useEffect(() => {
        setCurrentPage('services');
    }, [setCurrentPage]);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    list des services disponible
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/services/add'>
                        <i className="fas fa-plus"/> Ajouter un service
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content" style={{width: '92%'}}>
                {
                    deleteError
                        ? <div className="ui error message">
                            <div className="header">
                                un message d'erreur apparait !
                            </div>
                            <ul className="list">
                                <li>le service a été est supprimé deja</li>
                                <li>ou bien le serveur a été arrêté</li>
                            </ul>
                        </div>

                        : null
                }
                {isFetching
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        {
                            deleteLoading
                                ? <div className="detail-loader"><LoaderConetnt/></div>
                                : services.length > 0
                                ? <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell>image</Table.HeaderCell>
                                            <Table.HeaderCell>Nom</Table.HeaderCell>
                                            <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                            <Table.HeaderCell>Date de modification</Table.HeaderCell>
                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            services.map(cat => {
                                                return (
                                                    <Table.Row key={cat.id}>
                                                        <Table.Cell collapsing>
                                                            {/*<Checkbox slider />*/}
                                                        </Table.Cell>
                                                        <Table.Cell className="slider-img-td"><img
                                                            src={`https://www.admin.aqua-vim.com/${cat.image_path}`} alt=""
                                                            className="slider-img"/></Table.Cell>
                                                        <Table.Cell>{cat.name}</Table.Cell>
                                                        <Table.Cell>{cat.created_at}</Table.Cell>
                                                        <Table.Cell>{cat.updated_at}</Table.Cell>

                                                        <Table.Cell className="slider-action">
                                                            <Button.Group className="slider-action-btn">
                                                                <Button
                                                                    onClick={() => history.push(`/home/services/view/${cat.id}`)}><i
                                                                    className="far fa-eye"/> Afficher </Button>
                                                                <Button.Or text="ou"/>
                                                                <Button color={"black"}
                                                                        onClick={() => deleteServiceStart(cat)}><i
                                                                    className="fas fa-trash"/> supprimer</Button>
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>

                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell colSpan='8'>
                                                <Button
                                                    floated='right'
                                                    icon
                                                    labelPosition='left'
                                                    color={'black'}
                                                    size='medium'
                                                    onClick={() => history.push('/home/services/add')}
                                                >
                                                    <Icon name='image'/> Ajouter un service
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                                :
                                <React.Fragment>
                                    <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectIsFetchingServices,
    deleteLoading: selectDeleteLoading,
    deleteError: selectDeleteError,
    services: selectServices,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),

    fetchServices: () => dispatch(fetchServicesStart()),
    deleteServiceStart: cat => dispatch(deleteServiceStart(cat)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesList));
