import React, {useEffect} from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {deleteSubcategoryStart, fetchSubcategoriesStart} from "../../redux/subcategories/subcategories.actions";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetchingSubCat, selectSubcategories
} from "../../redux/subcategories/subcategory.selectors";
import {getCategoryStart} from "../../redux/categories/categories.actions";
import {selectCurrentCategory, selectUpdateLoading} from "../../redux/categories/categories.selectors";
const SubcategoriesList = ({ setCurrentPage, currentCategory, updateLoading, getCategoryStart, match,deleteError, fetchSubcategories, isFetching, subcategories,deleteLoading, deleteSubcategoryStart, history}) => {

    useEffect(() => {
        getCategoryStart({id: match.params.id});
    }, [getCategoryStart]);

    useEffect(() => {
        fetchSubcategories({id: match.params.id});
    },[fetchSubcategories]);



    useEffect(() => {
        setCurrentPage('products');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            {
                updateLoading || !currentCategory
                    ? null
                    : <div className="overview">

                        <h1 className="overview__heading">
                            <span onClick={() => history.push(`/home/categories/list`)}><i className="fas fa-arrow-left"  /></span> {currentCategory.title} Sous categories List
                        </h1>
                        <div className="content__text--btn">
                            <Link to={`/home/subcategories/add/${currentCategory.id}`} >
                                <i className="fas fa-plus"/> Ajouter sous Catgories
                            </Link>
                        </div>
                        <div className="overview__rating">
                            <i className="fas fa-list"/>
                        </div>
                    </div>
            }
            <div className="detail-content" style={{width: '92%'}}>
               {
                   deleteError
                       ? <div className="ui error message">
                           <div className="header">
                               un message d'erreur apparait !
                           </div>
                           <ul className="list">
                               <li>la Categorie a été est supprimé deja </li>
                               <li>ou bien le serveur a été arrêté</li>
                           </ul>
                       </div>

                       : null
               }
               {isFetching
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   : <React.Fragment>
                       {
                           deleteLoading
                               ? <div className="detail-loader"><LoaderConetnt/></div>
                               : subcategories.length > 0
                               ?  <Table compact celled definition>
                                   <Table.Header>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell>image</Table.HeaderCell>
                                           <Table.HeaderCell>titre</Table.HeaderCell>
                                           <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                           <Table.HeaderCell>Date de modification</Table.HeaderCell>
                                           <Table.HeaderCell>Action</Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Header>
                                   <Table.Body>
                                       {
                                           subcategories.map(cat => {
                                               return(
                                                   <Table.Row key={cat.id}>
                                                       <Table.Cell collapsing>
                                                           {/*<Checkbox slider />*/}
                                                       </Table.Cell>
                                                       <Table.Cell className="slider-img-td"><img src={`https://www.admin.aqua-vim.com/${cat.image_path}`} alt="" className="slider-img"/></Table.Cell>
                                                       <Table.Cell>{cat.title}</Table.Cell>
                                                       <Table.Cell>{cat.created_at}</Table.Cell>
                                                       <Table.Cell>{cat.updated_at}</Table.Cell>
                                                       <Table.Cell className="slider-action">
                                                           <Button.Group className="slider-action-btn">
                                                               <Button  onClick={() => history.push(`/home/subcategories/view/${cat.id}`)}><i className="far fa-eye" /> Afficher </Button>
                                                               <Button.Or text="ou"/>
                                                               <Button color={"black"} onClick={() => deleteSubcategoryStart(cat) }><i className="fas fa-trash"/> supprimer</Button>
                                                               <Button.Or text="ou"/>
                                                               <Button onClick={() => history.push(`/home/products/list/${cat.id}`)}><i className="fas fa-list"/> Consulter</Button>
                                                           </Button.Group>
                                                       </Table.Cell>
                                                   </Table.Row>
                                               )
                                           })
                                       }
                                   </Table.Body>

                                   <Table.Footer fullWidth>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell colSpan='8'>
                                               <Button
                                                   floated='right'
                                                   icon
                                                   labelPosition='left'
                                                   color={'black'}
                                                   size='medium'
                                                   onClick={() => history.push(`/home/subcategories/add/${currentCategory.id}`)}
                                               >
                                                   <Icon name='image'  /> Ajouter sous categorie
                                               </Button>
                                           </Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Footer>
                               </Table>
                               :
                               <React.Fragment>
                                   <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                               </React.Fragment>
                       }
                   </React.Fragment>
               }
           </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching : selectIsFetchingSubCat,
    deleteLoading : selectDeleteLoading,
    deleteError : selectDeleteError,
    subcategories : selectSubcategories,
    currentCategory: selectCurrentCategory,
    updateLoading : selectUpdateLoading,
});

const mapDispatchToProps = dispatch => ({
    getCategoryStart : cat => dispatch(getCategoryStart(cat)),
    fetchSubcategories : category => dispatch(fetchSubcategoriesStart(category)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    deleteSubcategoryStart : cat => dispatch(deleteSubcategoryStart(cat)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SubcategoriesList));
