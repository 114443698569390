import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getCategoryStart} from "../../redux/categories/categories.actions";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/subcategories/subcategory.selectors";
import {selectCurrentCategory, selectUpdateLoading} from "../../redux/categories/categories.selectors";
import {addSubcategoryStart} from "../../redux/subcategories/subcategories.actions";
import Resizer from "react-image-file-resizer";





const AddSubcategory = ({ match, currentCategory, updateLoading, addLoading, setCurrentPage, addSubcategoryStart, getCategoryStart, history ,addStatus, addErrors}) => {
    if (addStatus) history.push(`/home/subcategories/list/${currentCategory.id}`);
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");



    //for request errors
    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');
    const [titleError, setTitleError] = useState('');
    //form
    const [title, setTitle] = useState('');


    useEffect(() => {
        setCurrentPage('products');
    },[setCurrentPage]);

    useEffect(() => {
        getCategoryStart({id: match.params.id});
    }, [getCategoryStart]);

    useEffect(() => {
        if (addErrors) {
            if (addErrors.image_path) setImageError(addErrors.image_path);
            if (addErrors.title) setTitleError(addErrors.title);
            if (
                !addErrors.image_path && !addErrors.title
            )setServerError(addErrors);
        }
    }, [addErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setImage_path(image);
        } catch (err) {
            console.log(err);
        }

    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "JPEG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });


    const addReferenceHandler = async ()  => {
        const formData = new FormData();
        formData.append('image_path',image_path);
        formData.append('title',title);
        formData.append('productsCategory_id',currentCategory ? currentCategory.id : null);
        setTitleError('');
        setImageError('');
        addSubcategoryStart({formData});
    }

    return (
       <React.Fragment>
           {
               updateLoading
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   :   <React.Fragment>
                       <div className="overview">
                           <h1 className="overview__heading">
                               <span onClick={() => history.push(`/home/subcategories/list/${currentCategory.id}`)}><i className="fas fa-arrow-left"  /></span> Ajouter une Sous Categorie
                           </h1>
                           <div className="overview__rating">
                               <i className="far fa-plus-square"/>
                           </div>
                       </div>
                       <div className="detail-bigContent">
                           {
                               addLoading
                                   ? <div className="detail-loader"><LoaderConetnt/></div>
                                   : <React.Fragment>
                                       {
                                           serverError
                                               ? <div className="ui negative message">
                                                   <div className="header danger-text" >
                                                       Quelque chose s'est mal passé
                                                   </div>
                                                   <p>veuillez réessayer
                                                   </p></div>
                                               :<div className="slider-img__block">
                                                   <div className="ui grid">
                                                       <div className="two column row">
                                                           <div className="seven wide column">
                                                               {
                                                                   imageError
                                                                       ? <div className="ui negative message img-err">
                                                                           <div className="header danger-text header-err" >
                                                                               Aucune image a ete sélectionné !
                                                                           </div>
                                                                           <p>veuillez réessayer
                                                                           </p></div>
                                                                       : <React.Fragment>
                                                                           {
                                                                               image
                                                                                   ?
                                                                                   <img src={image} className="add-ref-image" alt=""/>
                                                                                   :
                                                                                   <img src={'/images/image-logo.png'} className="add-ref-image add-cat-img" alt=""/>
                                                                           }
                                                                       </React.Fragment>
                                                               }

                                                           </div>

                                                           <div className="nine wide column">
                                                               <div className="ui grid">
                                                                   <div className="row">
                                                                       <div className="sixteen wide column  formGroup">
                                                                           <label htmlFor="">Titre:</label>
                                                                           <div className="field">
                                                                               <div className="ui right corner labeled input">
                                                                                   <input
                                                                                       type="text"
                                                                                       placeholder="Title..."
                                                                                       name="title"
                                                                                       value={title}
                                                                                       onChange={event => setTitle(event.target.value)}
                                                                                   />
                                                                                   <div className="ui right corner label">
                                                                                       <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                   </div>
                                                                               </div>
                                                                               {
                                                                                   titleError
                                                                                       ?
                                                                                       <div className="ui pointing red basic label">
                                                                                           <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                                       </div>
                                                                                       : null
                                                                               }

                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                   <div className="row">
                                                                       <div className='eight wide column formGroup'>
                                                                           <div className="button-wrapper add-button-wrapper add-ref">
                                                                               <span>Image (400*225)px format JPG</span>

                                                                               <span className="label">
                                                        <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                                               <input
                                                                                   type="file"
                                                                                   name="image_path"
                                                                                   id="upload"
                                                                                   className="upload-box"
                                                                                   placeholder="Upload File"
                                                                                   onChange={fileSelectedHandler}
                                                                               />
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>
                                                   <Button.Group className=" btn-block ">
                                                       <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/subcategories/list/${currentCategory.id}`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                                       <Button.Or text="ou"/>
                                                       <Button color={"green"} size={'big'} className="form-btn"  onClick={addReferenceHandler}> <i className="fas fa-plus"/> Ajouter</Button>
                                                   </Button.Group>
                                               </div>
                                       }
                                   </React.Fragment>

                           }
                       </div>
                   </React.Fragment>
           }
       </React.Fragment>

    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
    currentCategory: selectCurrentCategory,
    updateLoading : selectUpdateLoading,
});

const mapDispatchToProps = dispatch => ({
    getCategoryStart : cat => dispatch(getCategoryStart(cat)),
    addSubcategoryStart : categoryData => dispatch(addSubcategoryStart(categoryData)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSubcategory));
