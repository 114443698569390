import React, {useEffect} from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {deleteCategoryStart, fetchCategoriesStart} from "../../redux/categories/categories.actions";
import {
    selectCategories,
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetchingCat
} from "../../redux/categories/categories.selectors";

const CategoriesList = ({ setCurrentPage,deleteError,fetchCategories, isFetching, categories,deleteLoading,deleteCategoryStart, history}) => {

    useEffect(() => {
        fetchCategories();
    },[fetchCategories]);

    useEffect(() => {
        setCurrentPage('products');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    list des categories disponible
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/categories/add'>
                        <i className="fas fa-plus"/> Ajouter une Catgories
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content" style={{width: '92%'}}>
               {
                   deleteError
                       ? <div className="ui error message">
                           <div className="header">
                               un message d'erreur apparait !
                           </div>
                           <ul className="list">
                               <li>la Categorie a été est supprimé deja </li>
                               <li>ou bien le serveur a été arrêté</li>
                           </ul>
                       </div>

                       : null
               }
               {isFetching
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   : <React.Fragment>
                       {
                           deleteLoading
                               ? <div className="detail-loader"><LoaderConetnt/></div>
                               : categories.length > 0
                               ?  <Table compact celled definition>
                                   <Table.Header>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell>image</Table.HeaderCell>
                                           <Table.HeaderCell>titre</Table.HeaderCell>
                                           <Table.HeaderCell className={"sub-column des-column-min "}>sous titre</Table.HeaderCell>
                                           <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                           <Table.HeaderCell>Action</Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Header>
                                   <Table.Body>
                                       {
                                           categories.map(cat => {
                                               return(
                                                   <Table.Row key={cat.id}>
                                                       <Table.Cell collapsing>
                                                           {/*<Checkbox slider />*/}
                                                       </Table.Cell>
                                                       <Table.Cell className="slider-img-td"><img src={`https://www.admin.aqua-vim.com/${cat.image_path}`} alt="" className="slider-img"/></Table.Cell>
                                                       <Table.Cell>{cat.title}</Table.Cell>
                                                       <Table.Cell className={"sub-column des-column-min"} >{cat.subtitle}</Table.Cell>
                                                       <Table.Cell>{cat.created_at}</Table.Cell>
                                                       <Table.Cell className="slider-action">
                                                           <Button.Group className="slider-action-btn">
                                                               <Button  onClick={() => history.push(`/home/categories/view/${cat.id}`)}><i className="far fa-eye" /> Afficher </Button>
                                                               <Button.Or text="ou"/>
                                                               <Button color={"black"} onClick={() => deleteCategoryStart(cat) }><i className="fas fa-trash"/> supprimer</Button>
                                                               <Button.Or text="ou"/>
                                                               <Button onClick={() => history.push(`/home/subcategories/list/${cat.id}`)}><i className="fas fa-list"/> Consulter</Button>
                                                           </Button.Group>
                                                       </Table.Cell>
                                                   </Table.Row>
                                               )
                                           })
                                       }
                                   </Table.Body>

                                   <Table.Footer fullWidth>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell colSpan='8'>
                                               <Button
                                                   floated='right'
                                                   icon
                                                   labelPosition='left'
                                                   color={'black'}
                                                   size='medium'
                                                   onClick={() => history.push('/home/categories/add')}
                                               >
                                                   <Icon name='image'  /> Ajouter une Catgories
                                               </Button>
                                           </Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Footer>
                               </Table>
                               :
                               <React.Fragment>
                                   <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                               </React.Fragment>
                       }
                   </React.Fragment>
               }
           </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching : selectIsFetchingCat,
    deleteLoading : selectDeleteLoading,
    deleteError : selectDeleteError,
    categories : selectCategories,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories : () => dispatch(fetchCategoriesStart()),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    deleteCategoryStart : cat => dispatch(deleteCategoryStart(cat)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CategoriesList));
