import React, {useEffect} from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";

import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {
    getSubcategoryStart
} from "../../redux/subcategories/subcategories.actions";
import {deleteProductStart, fetchProductsStart} from "../../redux/products/products.actions";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetchingPro,
    selectProducts
} from "../../redux/products/product.selectors";
import {selectCurrentSubcategory, selectUpdateLoading} from "../../redux/subcategories/subcategory.selectors";

const ProductsList = ({ setCurrentPage, currentCategory, updateLoading, getCategoryStart, match,deleteError, fetchProducts, isFetching, products,deleteLoading, deleteProductStart, history}) => {

    useEffect(() => {
        getCategoryStart({id: match.params.id});
    }, [getCategoryStart]);

    useEffect(() => {
        fetchProducts({id: match.params.id});
    },[fetchProducts]);



    useEffect(() => {
        setCurrentPage('products');
    },[setCurrentPage]);

    return (
        <React.Fragment>
            {
                updateLoading || !currentCategory
                    ? null
                    : <div className="overview">

                        <h1 className="overview__heading">
                            <span onClick={() => history.push(`/home/subcategories/list/${currentCategory[0].productsCategory_id}`)}><i className="fas fa-arrow-left"  /></span> {currentCategory[0].title} Produits List
                        </h1>
                        <div className="content__text--btn">
                            <Link to={`/home/products/add/${currentCategory[0].id}`} >
                                <i className="fas fa-plus"/> Ajouter un Produit
                            </Link>
                        </div>
                        <div className="overview__rating">
                            <i className="fas fa-list"/>
                        </div>
                    </div>
            }

           <div className="detail-content" style={{width: '92%'}}>
               {
                   deleteError
                       ? <div className="ui error message">
                           <div className="header">
                               un message d'erreur apparait !
                           </div>
                           <ul className="list">
                               <li>la Categorie a été est supprimé deja </li>
                               <li>ou bien le serveur a été arrêté</li>
                           </ul>
                       </div>

                       : null
               }
               {isFetching
                   ? <div className="detail-loader"><LoaderConetnt/></div>
                   : <React.Fragment>
                       {
                           deleteLoading
                               ? <div className="detail-loader"><LoaderConetnt/></div>
                               : products.length > 0
                               ?  <Table compact celled definition>
                                   <Table.Header>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell>image</Table.HeaderCell>
                                           <Table.HeaderCell>type</Table.HeaderCell>
                                           <Table.HeaderCell className={"sub-column des-column"} >description</Table.HeaderCell>
                                           <Table.HeaderCell>Date de modification</Table.HeaderCell>
                                           <Table.HeaderCell>Action</Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Header>
                                   <Table.Body>
                                       {
                                           products.map(cat => {
                                               return(
                                                   <Table.Row key={cat.id}>
                                                       <Table.Cell collapsing>
                                                           {/*<Checkbox slider />*/}
                                                       </Table.Cell>
                                                       <Table.Cell className="slider-img-td"><img src={`https://www.admin.aqua-vim.com/${cat.image_path}`} alt="" className="slider-img"/></Table.Cell>
                                                       <Table.Cell>{cat.type}</Table.Cell>
                                                       <Table.Cell className={"sub-column des-column"} >{cat.description}</Table.Cell>
                                                       <Table.Cell>{cat.updated_at}</Table.Cell>
                                                       <Table.Cell className="slider-action">
                                                           <Button.Group className="slider-action-btn">
                                                               <Button  onClick={() => history.push(`/home/products/view/${cat.id}`)}><i className="far fa-eye" /> Afficher </Button>
                                                               <Button.Or text="ou"/>
                                                               <Button color={"black"} onClick={() => deleteProductStart(cat) }><i className="fas fa-trash"/> supprimer</Button>
                                                           </Button.Group>
                                                       </Table.Cell>
                                                   </Table.Row>
                                               )
                                           })
                                       }
                                   </Table.Body>

                                   <Table.Footer fullWidth>
                                       <Table.Row>
                                           <Table.HeaderCell />
                                           <Table.HeaderCell colSpan='8'>
                                               <Button
                                                   floated='right'
                                                   icon
                                                   labelPosition='left'
                                                   color={'black'}
                                                   size='medium'
                                                   onClick={() => history.push(`/home/products/add/${currentCategory[0].id}`)}
                                               >
                                                   <Icon name='image'  /> Ajouter un produit
                                               </Button>
                                           </Table.HeaderCell>
                                       </Table.Row>
                                   </Table.Footer>
                               </Table>
                               :
                               <React.Fragment>
                                   <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                               </React.Fragment>
                       }
                   </React.Fragment>
               }
           </div>

        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching : selectIsFetchingPro,
    deleteLoading : selectDeleteLoading,
    deleteError : selectDeleteError,
    products : selectProducts,
    currentCategory: selectCurrentSubcategory,
    updateLoading : selectUpdateLoading,
});

const mapDispatchToProps = dispatch => ({
    getCategoryStart : cat => dispatch(getSubcategoryStart(cat)),
    fetchProducts : category => dispatch(fetchProductsStart(category)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
    deleteProductStart : cat => dispatch(deleteProductStart(cat)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ProductsList));
