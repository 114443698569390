import ProductsActionsTypes from "./products.types";

export const fetchProductsStart = subcategory => ({
    type : ProductsActionsTypes.FETCH_PRODUCTS_START,
    payload : subcategory
});
export const clearVariables = () => ({
    type : ProductsActionsTypes.CLEAR_VAR_PRO,
});

export const fetchProductsSuccess = product => ({
    type : ProductsActionsTypes.FETCH_PRODUCTS_SUCCESS,
    payload : product,
});

export const fetchProductsFailure = errorMessage => ({
    type : ProductsActionsTypes.FETCH_PRODUCTS_FAILURE,
    payload : errorMessage,
});

export const addProductStart = productInformation =>  ({
    type : ProductsActionsTypes.ADD_PRODUCT_START,
    payload : productInformation
});

export const addProductSuccess = () =>  ({
    type : ProductsActionsTypes.ADD_PRODUCT_SUCCESS,
});

export const addProductFailure = errorMessage => ({
    type : ProductsActionsTypes.ADD_PRODUCT_FAILURE,
    payload : errorMessage,
});

export const deleteProductStart = product =>  ({
    type : ProductsActionsTypes.DELETE_PRODUCT_START,
    payload : product,
});

export const deleteProductSuccess = product =>  ({
    type : ProductsActionsTypes.DELETE_PRODUCT_SUCCESS,
    payload : product,
});

export const deleteProductFailure = product => ({
    type : ProductsActionsTypes.DELETE_PRODUCT_FAILURE,
    payload : product,
});

export const getProductStart = product =>  ({
    type : ProductsActionsTypes.GET_PRODUCT_START,
    payload : product,
});

export const getProductSuccess = product =>  ({
    type : ProductsActionsTypes.GET_PRODUCT_SUCCESS,
    payload : product,
});

export const getProductFailure = errorMessage => ({
    type : ProductsActionsTypes.GET_PRODUCT_FAILURE,
    payload : errorMessage,
});

export const updateProductStart = product =>  ({
    type : ProductsActionsTypes.UPDATE_PRODUCT_START,
    payload : product,
});

export const updateProductSuccess = () =>  ({
    type : ProductsActionsTypes.UPDATE_PRODUCT_SUCCESS,
});

export const updateProductFailure = errorMessage => ({
    type : ProductsActionsTypes.UPDATE_PRODUCT_FAILURE,
    payload : errorMessage,
});
