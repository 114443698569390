const CategoriesActionsTypes = {
    FETCH_CATEGORIES_START : 'FETCH_CATEGORIES_START',
    FETCH_CATEGORIES_SUCCESS : 'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_FAILURE : 'FETCH_CATEGORIES_FAILURE',
    DELETE_CATEGORY_START : 'DELETE_CATEGORY_START',
    DELETE_CATEGORY_SUCCESS : 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILURE : 'DELETE_CATEGORY_FAILURE',
    ADD_CATEGORY_START : 'ADD_CATEGORY_START',
    ADD_CATEGORY_SUCCESS : 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_FAILURE : 'ADD_CATEGORY_FAILURE',
    GET_CATEGORY_START : 'GET_CATEGORY_START',
    GET_CATEGORY_SUCCESS : 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILURE : 'GET_CATEGORY_FAILURE',
    UPDATE_CATEGORY_START : 'UPDATE_CATEGORY_START',
    UPDATE_CATEGORY_SUCCESS : 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE : 'UPDATE_CATEGORY_FAILURE',
};

export default CategoriesActionsTypes;
