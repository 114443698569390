import { createSelector } from 'reselect';

const selectService = state => state.service;

/////////services list
export const selectServices  = createSelector(
    [selectService],
    service => service.services,
);

export const selectIsFetchingServices = createSelector(
    [selectService],
    service => service.isFetchingService,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectService],
    service => service.addStatus
);

export const selectAddError = createSelector(
    [selectService],
    service => service.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectService],
    service => service.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectService],
    service => service.deleteLoading
);
export const selectDeleteError = createSelector(
    [selectService],
    service => service.serviceNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectService],
    service => service.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectService],
    service => service.updateErrorMessage,
)

export const selectCurrentService = createSelector(
    [selectService],
    service => service.currentService
);

export const selectUpdateStatus = createSelector(
    [selectService],
    service => service.updateStatus,
);




