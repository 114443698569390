import React from 'react';

const Gallery = () => {
    return (
        <div className="gallery">
            <figure className="gallery__item">
                <img src="/images/image--003.jpg" alt="Photo of hotel 1" className="gallery__photo"/>
            </figure>
            <figure className="gallery__item">
                <img src="/images/image--004.jpg" alt="Photo of hotel 2" className="gallery__photo"/>
            </figure>
            <figure className="gallery__item">
                <img src="/images/image--001.jpg" alt="Photo of hotel 3" className="gallery__photo"/>
            </figure>
        </div>
    )
}

export default Gallery;