import SlidersActionsTypes from "./slider.types";

export const fetchSlidersStart = () => ({
    type : SlidersActionsTypes.FETCH_SLIDERS_START,
});

export const clearVariables = () => ({
    type : SlidersActionsTypes.CLEAR_VAR,
});

export const fetchSlidersSuccess = sliders => ({
    type : SlidersActionsTypes.FETCH_SLIDERS_SUCCESS,
    payload : sliders,
});

export const fetchSlidersFailure = errorMessage => ({
    type : SlidersActionsTypes.FETCH_SLIDERS_FAILURE,
    payload : errorMessage,
});

export const addSliderStart = sliderInformation =>  ({
    type : SlidersActionsTypes.ADD_SLIDER_START,
    payload : sliderInformation
});

export const addSliderSuccess = () =>  ({
    type : SlidersActionsTypes.ADD_SLIDER_SUCCESS,
});

export const addSliderFailure = errorMessage => ({
    type : SlidersActionsTypes.ADD_SLIDER_FAILURE,
    payload : errorMessage,
});

export const deleteSliderStart = slider =>  ({
    type : SlidersActionsTypes.DELETE_SLIDER_START,
    payload : slider,
});

export const deleteSliderSuccess = slider =>  ({
    type : SlidersActionsTypes.DELETE_SLIDER_SUCCESS,
    payload : slider,
});

export const deleteSliderFailure = slider => ({
    type : SlidersActionsTypes.DELETE_SLIDER_FAILURE,
    payload : slider,
});

export const getSliderStart = slider =>  ({
    type : SlidersActionsTypes.GET_SLIDER_START,
    payload : slider,
});

export const getSliderSuccess = slider =>  ({
    type : SlidersActionsTypes.GET_SLIDER_SUCCESS,
    payload : slider,
});

export const getSliderFailure = errorMessage => ({
    type : SlidersActionsTypes.GET_SLIDER_FAILURE,
    payload : errorMessage,
});

export const updateSliderStart = slider =>  ({
    type : SlidersActionsTypes.UPDATE_SLIDER_START,
    payload : slider,
});

export const updateSliderSuccess = () =>  ({
    type : SlidersActionsTypes.UPDATE_SLIDER_SUCCESS,
});

export const updateSliderFailure = errorMessage => ({
    type : SlidersActionsTypes.UPDATE_SLIDER_FAILURE,
    payload : errorMessage,
});
