import {createSelector} from 'reselect';

const selectClient = state => state.client;


///clients list
export const selectClients  = createSelector(
    [selectClient],
    client => client.clients,
);

export const selectIsFetchingClients = createSelector(
    [selectClient],
    client => client.isFetchingClients,
);


///send message
export const selectSendMsgStatus = createSelector(
    [selectClient],
    client => client.sendMsgStatus
);

export const selectSendMsgError = createSelector(
    [selectClient],
    client => client.sendErrorMessage
);
export const selectSendMsgLoading = createSelector(
    [selectClient],
    client => client.sendMsgLoading
);