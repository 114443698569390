import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getSliderStart, updateSliderStart} from "../../redux/slliders/slider.actions";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/label.min.css'
import {Button} from "semantic-ui-react";
import {
    selectCurrentSlider,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/slliders/slider.selectors";
import Resizer from "react-image-file-resizer";

const SliderView = ({currentSlider, updateStatus, updateErrors, updateSliderStart, getSliderStart, match, history, updateLoading}) => {
    const [inputError, setinputError] = useState(null);
    const [image_path, setImage_path] = useState(null);
    const [image, setImage] = useState("");


    useEffect(() => {
        getSliderStart({id: match.params.id});
    }, [getSliderStart]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        try {
            const file = event.target.files[0];
            // const image = await resizeFile(file);
            setImage_path(file);
        } catch (err) {
            console.log(err);
        }
    }

    // const resizeFile = (file) =>
    //     new Promise((resolve) => {
    //         Resizer.imageFileResizer(
    //             file,
    //             300,
    //             300,
    //             "PNG",
    //             100,
    //             0,
    //             (blob) => {
    //                 resolve(blob);
    //             },
    //             'blob'
    //         );
    //     });

    const updateSliderHandler = async () => {
        if (!image_path) return setinputError(true);
        const formData = new FormData();
        formData.append('image_path', image_path);
        formData.append('_method', 'PATCH');

        updateSliderStart({id: match.params.id, formData});
    }
    if (updateStatus) return (<Redirect to="/home/slides/list"/>);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    <span onClick={() => history.push(`/home/slides/list`)}><i
                        className="fas fa-arrow-left"/></span> Modifier une diapositive
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-content">
                {
                    updateLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                !currentSlider
                                    ?
                                    <div className="ui negative message">
                                        <div className="header danger-text">
                                            Aucune image est trouver !
                                        </div>
                                        <p>veuillez réessayer
                                        </p>
                                    </div>
                                    : <div className="slider-img__block">
                                        <img src={`https://www.admin.aqua-vim.com/${currentSlider.image_path}`} alt="img"
                                             className="slider-image"/>
                                        {
                                            image
                                                ?
                                                <img id="target" src={image} alt=""
                                                     className="slider-image slider-image-update"/>
                                                :
                                                <React.Fragment>
                                                    <div className="button-wrapper">
                                                    <span className="label">
                                                    <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                        <input type="file" onChange={fileSelectedHandler} name="upload"
                                                               id="upload" className="upload-box" placeholder="Upload File"/>
                                                    </div>
                                                    {
                                                        inputError
                                                            ? <div className="ui negative message">
                                                                <div className="header danger-text">
                                                                    Aucune image a ete sélectionné !
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : null
                                                    }
                                                    {
                                                        updateErrors
                                                            ? <div className="ui negative message">
                                                                <div className="header danger-text">
                                                                    le serveur a été arrêté
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : null
                                                    }
                                                </React.Fragment>
                                        }
                                        <span>Image (1366*766)px format JPG</span>
                                        <Button.Group className=" btn-block">
                                            <Button className="form-btn" size={'big'}
                                                    onClick={() => history.push(`/home/slides/list`)}> <i
                                                className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} onClick={updateSliderHandler}
                                                    className="form-btn"> <i className="fas fa-pen-fancy"/> Modifier</Button>
                                        </Button.Group>
                                    </div>
                            }
                        </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
};

const mapStateToProps = createStructuredSelector({
    updateLoading: selectUpdateLoading,
    currentSlider: selectCurrentSlider,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getSliderStart: slider => dispatch(getSliderStart(slider)),
    updateSliderStart: slider => dispatch(updateSliderStart(slider)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderView));
