const UserActionTypes = {
    SIGN_IN_START : "SIGN_IN_START",
    SIGN_IN_SUCCESS : "SIGN_IN_SUCCESS",
    SIGN_IN_FAILURE : "SIGN_IN_FAILURE",
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    REFRESH_USER_SESSION: 'REFRESH_USER_SESSION',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    //////
    FETCH_USERS_START : 'FETCH_USERS_START',
    FETCH_USERS_SUCCESS : 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE : 'FETCH_USERS_FAILURE',
    DELETE_USER_START : 'DELETE_USER_START',
    DELETE_USER_SUCCESS : 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE : 'DELETE_USER_FAILURE',
    ADD_USER_START : 'ADD_USER_START',
    ADD_USER_SUCCESS : 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE : 'ADD_USER_FAILURE',
    GET_USER_START : 'GET_USER_START',
    GET_USER_SUCCESS : 'GET_USER_SUCCESS',
    GET_USER_FAILURE : 'GET_USER_FAILURE',
    UPDATE_USER_START : 'UPDATE_USER_START',
    UPDATE_USER_SUCCESS : 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE : 'UPDATE_USER_FAILURE',
};

export default UserActionTypes;
