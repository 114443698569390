import CategoriesActionsTypes from "./categories.types";

export const fetchCategoriesStart = () => ({
    type : CategoriesActionsTypes.FETCH_CATEGORIES_START,
});
export const clearVariables = () => ({
    type : CategoriesActionsTypes.CLEAR_VAR_REF,
});

export const fetchCategoriesSuccess = category => ({
    type : CategoriesActionsTypes.FETCH_CATEGORIES_SUCCESS,
    payload : category,
});

export const fetchCategoriesFailure = errorMessage => ({
    type : CategoriesActionsTypes.FETCH_CATEGORIES_FAILURE,
    payload : errorMessage,
});

export const addCategoryStart = categoryInformation =>  ({
    type : CategoriesActionsTypes.ADD_CATEGORY_START,
    payload : categoryInformation
});

export const addCategorySuccess = () =>  ({
    type : CategoriesActionsTypes.ADD_CATEGORY_SUCCESS,
});

export const addCategoryFailure = errorMessage => ({
    type : CategoriesActionsTypes.ADD_CATEGORY_FAILURE,
    payload : errorMessage,
});

export const deleteCategoryStart = category =>  ({
    type : CategoriesActionsTypes.DELETE_CATEGORY_START,
    payload : category,
});

export const deleteCategorySuccess = category =>  ({
    type : CategoriesActionsTypes.DELETE_CATEGORY_SUCCESS,
    payload : category,
});

export const deleteCategoryFailure = category => ({
    type : CategoriesActionsTypes.DELETE_CATEGORY_FAILURE,
    payload : category,
});

export const getCategoryStart = category =>  ({
    type : CategoriesActionsTypes.GET_CATEGORY_START,
    payload : category,
});

export const getCategorySuccess = category =>  ({
    type : CategoriesActionsTypes.GET_CATEGORY_SUCCESS,
    payload : category,
});

export const getCategoryFailure = errorMessage => ({
    type : CategoriesActionsTypes.GET_CATEGORY_FAILURE,
    payload : errorMessage,
});

export const updateCategoryStart = category =>  ({
    type : CategoriesActionsTypes.UPDATE_CATEGORY_START,
    payload : category,
});

export const updateCategorySuccess = () =>  ({
    type : CategoriesActionsTypes.UPDATE_CATEGORY_SUCCESS,
});

export const updateCategoryFailure = errorMessage => ({
    type : CategoriesActionsTypes.UPDATE_CATEGORY_FAILURE,
    payload : errorMessage,
});
