import { createSelector } from 'reselect';

const selectProduct = state => state.product;

/////////products list
export const selectProducts  = createSelector(
    [selectProduct],
    product => product.products,
);

export const selectIsFetchingPro = createSelector(
    [selectProduct],
    product => product.isFetchingPro,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectProduct],
    product => product.addStatus
);

export const selectAddError = createSelector(
    [selectProduct],
    product => product.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectProduct],
    product => product.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectProduct],
    product => product.deleteLoading
);

export const selectDeleteError = createSelector(
    [selectProduct],
    product => product.productNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectProduct],
    product => product.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectProduct],
    product => product.updateErrorMessage,
)

export const selectCurrentProduct = createSelector(
    [selectProduct],
    product => product.currentProduct
);

export const selectUpdateStatus = createSelector(
    [selectProduct],
    product => product.updateStatus,
);




