const ReferencesActionsTypes = {
    FETCH_REFERENCES_START : 'FETCH_REFERENCES_START',
    FETCH_REFERENCES_SUCCESS : 'FETCH_REFERENCES_SUCCESS',
    FETCH_REFERENCES_FAILURE : 'FETCH_REFERENCES_FAILURE',
    DELETE_REFERENCE_START : 'DELETE_REFERENCE_START',
    DELETE_REFERENCE_SUCCESS : 'DELETE_REFERENCE_SUCCESS',
    DELETE_REFERENCE_FAILURE : 'DELETE_REFERENCE_FAILURE',
    ADD_REFERENCE_START : 'ADD_REFERENCE_START',
    ADD_REFERENCE_SUCCESS : 'ADD_REFERENCE_SUCCESS',
    ADD_REFERENCE_FAILURE : 'ADD_REFERENCE_FAILURE',
    GET_REFERENCE_START : 'GET_REFERENCE_START',
    GET_REFERENCE_SUCCESS : 'GET_REFERENCE_SUCCESS',
    GET_REFERENCE_FAILURE : 'GET_REFERENCE_FAILURE',
    UPDATE_REFERENCE_START : 'UPDATE_REFERENCE_START',
    UPDATE_REFERENCE_SUCCESS : 'UPDATE_REFERENCE_SUCCESS',
    UPDATE_REFERENCE_FAILURE : 'UPDATE_REFERENCE_FAILURE',
    CLEAR_VAR_REF : 'CLEAR_VAR_REF',
};

export default ReferencesActionsTypes;
