import ProductsActionsTypes from "./products.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //products list
    products : [],
    isFetchingPro : true,

    //add product
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete product
    productNotFoundError: false,
    deleteLoading : false,

    //get product
    getProductError: null,
    currentProduct : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // products list
        case ProductsActionsTypes.FETCH_PRODUCTS_START:
            return {
                ...state,
                isFetchingPro: true,
                addStatus: false,
                addErrorMessage: null,
                productNotFoundError:false,
                updateStatus: false,
                updateLoading: true,//verfierv update loading in the reference reducer
            };
        case ProductsActionsTypes.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                isFetchingPro: false,
                products: action.payload,

            };
        case ProductsActionsTypes.FETCH_PRODUCTS_FAILURE :
            return {
                ...state,
                isFetchingPro: false,
                // errorMessage: action.payload, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            };

        //Add product
        case ProductsActionsTypes.ADD_PRODUCT_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case ProductsActionsTypes.ADD_PRODUCT_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingPro: true,
                addErrorMessage: null,
            }
        case ProductsActionsTypes.ADD_PRODUCT_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete product
        case ProductsActionsTypes.DELETE_PRODUCT_START :
            return {
                ...state,
                deleteLoading: true,
                productNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case ProductsActionsTypes.DELETE_PRODUCT_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                productNotFoundError: false,
                products: state.products.filter(
                    reference => reference.id !== action.payload.id
                )
            }
        case ProductsActionsTypes.DELETE_PRODUCT_FAILURE :
            return {
                ...state,
                productNotFoundError: true,
                deleteLoading: false,
                products: state.products.filter(
                    reference => reference.id !== action.payload.id
                )
            }

        //get product
        case ProductsActionsTypes.GET_PRODUCT_START :
            return {
                ...state,
                updateLoading: true,
                getProductError:null,
            }
        case ProductsActionsTypes.GET_PRODUCT_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getProductError: null,
                currentProduct: action.payload,
            }
        case ProductsActionsTypes.GET_PRODUCT_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getProductError: action.payload,
                currentProduct: null,
            }


        //update
        case ProductsActionsTypes.UPDATE_PRODUCT_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case ProductsActionsTypes.UPDATE_PRODUCT_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingPro: true,
            }
        case ProductsActionsTypes.UPDATE_PRODUCT_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //products list
                products : [],
                isFetchingPro : false,

                //add product
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,

                //delete product
                productNotFoundError: false,
                deleteLoading : false,

                //get product
                getProductError: null,
                currentProduct : null,
                updateLoading : false,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,


            }
        default:
            return state;

    }
};

export default productReducer;
