import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import SlidShowList from "../sliderControle/index.component";
import SliderView from "../sliderControle/slider-view.comonent";
import AddSlider from "../sliderControle/add-slider.component";
import ReferencesList from "../Refrences-Controller/index.component";
import AddReference from "../Refrences-Controller/add-reference.component";
import ReferenceView from "../Refrences-Controller/update-reference.component";
import CategoriesList from "../categories-controller/index.component";
import AddCategory from "../categories-controller/add-category.component";
import CategoryView from "../categories-controller/update-category.component";
import SubcategoriesList from "../sub-category-controller/index.component";
import AddSubcategory from "../sub-category-controller/add-subcategory.component";
import SubcategoryView from "../sub-category-controller/update-subcategory.component";
import ProductsList from "../products-controller/index.component";
import AddProduct from "../products-controller/add-product.component";
import ProductView from "../products-controller/update-product.component";
import EventList from "../events-controller/index.component";
import AddEvent from "../events-controller/add-event.component";
import EventView from "../events-controller/update-event.component";
import PartnersList from "../partner-controller/index.component";
import AddPartner from "../partner-controller/add-partner.component";
import PartnerView from "../partner-controller/update-partner.component";
import UsersList from "../users/index.component";
import {createStructuredSelector} from "reselect";
import {selectCheckLoading, selectCurrentUser} from "../../redux/users/user.selectors";
import {connect} from "react-redux";
import AddUser from "../users/add-user.component";
import UserView from "../users/update-user.component";
import Newsletter from "../newsletter/send-newsletter.compoenent";
import ServicesList from "../services-controller/index.component";
import AddService from "../services-controller/add-service.component";
import ServiceView from "../services-controller/update-category.component";
import Config from "../config/config.component";
import SubscribedClients from "../newsletter/ subscribed-clients-list.component";

const ContentRoutes = ({currentUser, checkLoading}) => {
    return (
        <div className="detail">
            <Switch>
                {/*slides routing*/}
                <Route exact path={`/home/slides/list`} component={SlidShowList}/>
                <Route exact path="/home/slides/view/:id" component={SliderView}/>
                <Route exact path="/home/slides/add" component={AddSlider}/>

                {/*references routing*/}
                <Route exact path="/home/references/list" component={ReferencesList}/>
                <Route exact path="/home/references/add" component={AddReference}/>
                <Route exact path="/home/references/view/:id" component={ReferenceView}/>

                {/*categories routing*/}
                <Route exact path="/home/categories/list" component={CategoriesList}/>
                <Route exact path="/home/categories/add" component={AddCategory}/>
                <Route exact path="/home/categories/view/:id" component={CategoryView}/>

                {/*Subcategories routing*/}
                <Route exact path="/home/subcategories/list/:id" component={SubcategoriesList}/>
                <Route exact path="/home/subcategories/add/:id" component={AddSubcategory}/>
                <Route exact path="/home/subcategories/view/:id" component={SubcategoryView}/>

                {/*Products routing*/}
                <Route exact path="/home/products/list/:id" component={ProductsList}/>
                <Route exact path="/home/products/add/:id" component={AddProduct}/>
                <Route exact path="/home/products/view/:id" component={ProductView}/>

                {/*events routing*/}
                <Route exact path="/home/events/list" component={EventList}/>
                <Route exact path="/home/events/add" component={AddEvent}/>
                <Route exact path="/home/events/view/:id" component={EventView}/>

                {/*PartnersList*/}
                <Route exact path="/home/partners/list" component={PartnersList}/>
                <Route exact path="/home/partners/add" component={AddPartner}/>
                <Route exact path="/home/partners/view/:id" component={PartnerView}/>

                {/*services*/}
                <Route exact path="/home/services/list" component={ServicesList}/>
                <Route exact path="/home/services/add" component={AddService}/>
                <Route exact path="/home/services/view/:id" component={ServiceView}/>

                {/*config*/}
                <Route exact path="/home/config" component={Config}/>

                {/*users List*/}
                <Route exact path='/home/users/list'
                       render={() => currentUser.is_admin === 0
                           ? (<Redirect to='/home/slides/list'/>)
                           : (<UsersList/>)
                       }
                />
                <Route exact path='/home/users/add'
                       render={() => currentUser.is_admin === 0
                           ? (<Redirect to='/home/slides/list'/>)
                           : (<AddUser/>)
                       }
                />
                <Route exact path='/home/users/view/:id' component={UserView}/>

                <Route exact path="/home/clients" component={SubscribedClients}/>
                <Route exact path="/home/newsletter" component={Newsletter}/>

                <Redirect to="/home/slides/list"/>
            </Switch>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    checkLoading: selectCheckLoading,
});


export default connect(mapStateToProps, null)(ContentRoutes);

