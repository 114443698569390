import React from 'react';
import iconSet from "../../selection.json";
import IcomoonReact, {iconList} from "icomoon-react";
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/transition.min.css'
import {Dropdown} from 'semantic-ui-react'
import {connect} from "react-redux";
import {signOutStart} from "../../redux/users/user.actions";
import {createStructuredSelector} from "reselect";
import {selectCheckLoading, selectCurrentUser} from "../../redux/users/user.selectors";
import {withRouter} from "react-router-dom";



const Header = ({ signOutStart, currentUser, history}) => {
    const options = [
        {key: 1, text: <h2 onClick={signOutStart}><i className="fas fa-sign-out-alt"/> deconnecté</h2>, value: 1},
        {key: 2, text: <h2 onClick={() => history.push(`/home/users/view/${currentUser.id}`)}><i className="fas fa-cog"></i> paramètres</h2>, value: 1},
    ]

    return (

        <header className="home-header">
            <img src="/images/logo.PNG" alt="trillo logo" className={"logo"}/>

            {/*<form action="#" className="search">*/}
            {/*    <input type="text" className="search__input" placeholder="Search..."/>*/}
            {/*    <button className="search__button">*/}
            {/*        <IcomoonReact iconSet={iconSet} className="search__icon" size={20} icon="magnifying-glass"/>*/}
            {/*    </button>*/}
            {/*</form>*/}

            <nav className="user-nav">
                {/*<div className="user-nav__icon-box">*/}
                {/*    <IcomoonReact iconSet={iconSet} className="user-nav__icon" size={20} icon="bookmark"/>*/}
                {/*    <span className="user-nav__notification">7</span>*/}
                {/*</div>*/}
                {/*<div className="user-nav__icon-box">*/}
                {/*    <IcomoonReact iconSet={iconSet} className="user-nav__icon" size={20} icon="chat"/>*/}
                {/*    <span className="user-nav__notification">13</span>*/}
                {/*</div>*/}
                <div >
                    <Dropdown
                        text=
                            {<div className="user-nav__user">
                                <img src="/images/admin.png" alt="User photo" className="user-nav__user-photo"/>
                                <span className="user-nav__user-name">{currentUser.name}</span>
                            </div>}
                    options={options}  />
                </div>
            </nav>
        </header>
    )
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
    signOutStart : () => dispatch(signOutStart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
