import { createSelector } from 'reselect';

const selectSlider = state => state.slider;

/////////sliders list
export const selectSliders  = createSelector(
    [selectSlider],
    slider => slider.sliders,
);

export const selectIsFetching = createSelector(
    [selectSlider],
    slider => slider.isFetching,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectSlider],
    slider => slider.addStatus
);

export const selectAddError = createSelector(
    [selectSlider],
    slider => slider.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectSlider],
    slider => slider.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectSlider],
    slider => slider.deleteLoading
);
export const selectDeleteError = createSelector(
    [selectSlider],
    slider => slider.sliderNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectSlider],
    slider => slider.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectSlider],
    slider => slider.updateErrorMessage,
)

export const selectCurrentSlider = createSelector(
    [selectSlider],
    slider => slider.currentSlider,
);

export const selectUpdateStatus = createSelector(
    [selectSlider],
    slider => slider.updateStatus,
);




