const ServicesActionsTypes = {
    FETCH_SERVICES_START : 'FETCH_SERVICES_START',
    FETCH_SERVICES_SUCCESS : 'FETCH_SERVICES_SUCCESS',
    FETCH_SERVICES_FAILURE : 'FETCH_SERVICES_FAILURE',
    DELETE_SERVICE_START : 'DELETE_SERVICE_START',
    DELETE_SERVICE_SUCCESS : 'DELETE_SERVICE_SUCCESS',
    DELETE_SERVICE_FAILURE : 'DELETE_SERVICE_FAILURE',
    ADD_SERVICE_START : 'ADD_SERVICE_START',
    ADD_SERVICE_SUCCESS : 'ADD_SERVICE_SUCCESS',
    ADD_SERVICE_FAILURE : 'ADD_SERVICE_FAILURE',
    GET_SERVICE_START : 'GET_SERVICE_START',
    GET_SERVICE_SUCCESS : 'GET_SERVICE_SUCCESS',
    GET_SERVICE_FAILURE : 'GET_SERVICE_FAILURE',
    UPDATE_SERVICE_START : 'UPDATE_SERVICE_START',
    UPDATE_SERVICE_SUCCESS : 'UPDATE_SERVICE_SUCCESS',
    UPDATE_SERVICE_FAILURE : 'UPDATE_SERVICE_FAILURE',
};

export default ServicesActionsTypes;
