import EventsActionsTypes from "./events.types";

export const fetchEventsStart = () => ({
    type : EventsActionsTypes.FETCH_EVENTS_START,
});
export const clearVariables = () => ({
    type : EventsActionsTypes.CLEAR_VAR_EV,
});

export const fetchEventsSuccess = event => ({
    type : EventsActionsTypes.FETCH_EVENTS_SUCCESS,
    payload : event,
});

export const fetchEventsFailure = errorMessage => ({
    type : EventsActionsTypes.FETCH_EVENTS_FAILURE,
    payload : errorMessage,
});

export const addEventStart = eventInformation =>  ({
    type : EventsActionsTypes.ADD_EVENT_START,
    payload : eventInformation
});

export const addEventSuccess = () =>  ({
    type : EventsActionsTypes.ADD_EVENT_SUCCESS,
});

export const addEventFailure = errorMessage => ({
    type : EventsActionsTypes.ADD_EVENT_FAILURE,
    payload : errorMessage,
});

export const deleteEventStart = event =>  ({
    type : EventsActionsTypes.DELETE_EVENT_START,
    payload : event,
});

export const deleteEventSuccess = event =>  ({
    type : EventsActionsTypes.DELETE_EVENT_SUCCESS,
    payload : event,
});

export const deleteEventFailure = event => ({
    type : EventsActionsTypes.DELETE_EVENT_FAILURE,
    payload : event,
});

export const getEventStart = event =>  ({
    type : EventsActionsTypes.GET_EVENT_START,
    payload : event,
});

export const getEventSuccess = event =>  ({
    type : EventsActionsTypes.GET_EVENT_SUCCESS,
    payload : event,
});

export const getEventFailure = errorMessage => ({
    type : EventsActionsTypes.GET_EVENT_FAILURE,
    payload : errorMessage,
});

export const updateEventStart = event =>  ({
    type : EventsActionsTypes.UPDATE_EVENT_START,
    payload : event,
});

export const updateEventSuccess = () =>  ({
    type : EventsActionsTypes.UPDATE_EVENT_SUCCESS,
});

export const updateEventFailure = errorMessage => ({
    type : EventsActionsTypes.UPDATE_EVENT_FAILURE,
    payload : errorMessage,
});
