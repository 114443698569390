const ClientsActionsTypes = {
    FETCH_CLIENTS_START : 'FETCH_CLIENTS_START',
    FETCH_CLIENTS_SUCCESS : 'FETCH_CLIENTS_SUCCESS',
    FETCH_CLIENTS_FAILURE : 'FETCH_CLIENTS_FAILURE',
    SEND_MESSAGE_START : 'SEND_MESSAGE_START',
    SEND_MESSAGE_SUCCESS : 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE : 'SEND_MESSAGE_FAILURE',
};

export default ClientsActionsTypes;
