import { createSelector } from 'reselect';

const selectCategory = state => state.category;

/////////categories list
export const selectCategories  = createSelector(
    [selectCategory],
    categories => categories.categories,
);

export const selectIsFetchingCat = createSelector(
    [selectCategory],
    categories => categories.isFetchingCat,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectCategory],
    categories => categories.addStatus
);

export const selectAddError = createSelector(
    [selectCategory],
    categories => categories.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectCategory],
    categories => categories.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectCategory],
    categories => categories.deleteLoading
);
export const selectDeleteError = createSelector(
    [selectCategory],
    categories => categories.categoryNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectCategory],
    categories => categories.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectCategory],
    categories => categories.updateErrorMessage,
)

export const selectCurrentCategory = createSelector(
    [selectCategory],
    categories => categories.currentCategory
);

export const selectUpdateStatus = createSelector(
    [selectCategory],
    categories => categories.updateStatus,
);




