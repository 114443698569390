import { createSelector } from 'reselect';

const selectSubcategory = state => state.subcategory;

/////////subcategories list
export const selectSubcategories  = createSelector(
    [selectSubcategory],
    subcategory => subcategory.subcategories,
);

export const selectIsFetchingSubCat = createSelector(
    [selectSubcategory],
    subcategory => subcategory.isFetchingSubCat,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectSubcategory],
    subcategory => subcategory.addStatus
);

export const selectAddError = createSelector(
    [selectSubcategory],
    subcategory => subcategory.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectSubcategory],
    subcategory => subcategory.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectSubcategory],
    subcategory => subcategory.deleteLoading
);

export const selectDeleteError = createSelector(
    [selectSubcategory],
    subcategory => subcategory.subcategoryNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectSubcategory],
    subcategory => subcategory.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectSubcategory],
    subcategory => subcategory.updateErrorMessage,
)

export const selectCurrentSubcategory = createSelector(
    [selectSubcategory],
    subcategory => subcategory.currentSubcategory
);

export const selectUpdateStatus = createSelector(
    [selectSubcategory],
    subcategory => subcategory.updateStatus,
);




