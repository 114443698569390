import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {addEventStart} from "../../redux/events/events.actions";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/events/events.selectors";

const AddEvent = ({ addLoading, setCurrentPage, addEventStart, history ,addStatus, addErrors}) => {
    if (addStatus) history.push("/home/events/list");
    //for request errors
    const [serverError, setServerError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [dateError, setDateError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    //form
    const [eventCredentials, setCredentials] = useState({ title: '', date: '', description: ''});
    const { title, date, description } = eventCredentials;

    useEffect(() => {
        setCurrentPage('events');
    },[setCurrentPage]);

    useEffect(() => {
        if (addErrors) {
            if (addErrors.title) setTitleError(addErrors.title);
            if (addErrors.date) setDateError(addErrors.date);
            if (addErrors.description) setDescriptionError(addErrors.description);
            if (
                !addErrors.title && !addErrors.date &&   !addErrors.description
            )setServerError(addErrors);
        }
    }, [addErrors]);



    const handleChange = event => {
        const {value, name} = event.target;
        setCredentials({...eventCredentials, [name]: value});
    };
    const addEventHandler = async ()  => {
        const formData = new FormData();
        formData.append('title',title);
        formData.append('date',date);
        formData.append('description',description);
        setTitleError('');
        setDateError('');
        setDescriptionError('');
        addEventStart({formData});
    }

    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/events/list')}><i className="fas fa-arrow-left"  /></span> Ajouter un événement
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
            {
                addLoading
                ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        {
                            serverError
                                ? <div className="ui negative message">
                                    <div className="header danger-text" >
                                        le serveur a été arrêté
                                    </div>
                                    <p>veuillez réessayer
                                    </p></div>
                                :<div className="slider-img__block">
                                    <div className="ui grid">
                                        <div className="two column row">
                                            <div className="seven wide column">
                                                 <img src={'/images/event.png'} className="add-ref-image add-cat-img" alt=""/>
                                            </div>

                                            <div className="nine wide column">
                                                <div className="ui grid">
                                                    <div className="row">
                                                        <div className="eight wide column  formGroup">
                                                            <label htmlFor="">Titre:</label>
                                                            <div className="field">
                                                            <div className="ui right corner labeled input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Title..."
                                                                    name="title"
                                                                    value={title}
                                                                    onChange={handleChange}
                                                                />
                                                                <div className="ui right corner label">
                                                                    <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                </div>
                                                            </div>
                                                                {
                                                                    titleError
                                                                    ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                        </div>
                                                                    : null
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="eight wide column  formGroup">
                                                            <label htmlFor="">Date D'événement:</label>
                                                            <div className="field">
                                                                <div className="ui right corner labeled input">
                                                                    <input
                                                                        type="date"
                                                                        placeholder="Date..."
                                                                        name="date"
                                                                        value={date}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <div className="ui right corner label">
                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    dateError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> ce champ obligatoire !
                                                                        </div>
                                                                        : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                            <label htmlFor="">Description:</label>
                                                            <div className="field">
                                                                <div className="ui right corner labeled input">
                                                                    <textarea
                                                                        placeholder="Description..."
                                                                        name="description"
                                                                        value={description}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <div className="ui right corner label">
                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    descriptionError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                        </div>
                                                                        : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <Button.Group className=" btn-block ">
                                        <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/events/list`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                        <Button.Or text="ou"/>
                                        <Button color={"black"} size={'big'} className="form-btn"  onClick={addEventHandler}> <i className="fas fa-plus"/> Ajouter</Button>
                                    </Button.Group>
                                </div>
                        }
                    </React.Fragment>

            }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
});

const mapDispatchToProps = dispatch => ({
    addEventStart : eventData => dispatch(addEventStart(eventData)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEvent));
