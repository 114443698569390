import {createSelector} from 'reselect';

const selectDesignUtilities = state => state.design_utilities;

export const selectCurrentPageTitle = createSelector(
    [selectDesignUtilities],
    design_utilities => design_utilities.current_title,
);

export const selectCurrentPage = createSelector(
    [selectDesignUtilities],
    design_utilities => design_utilities.current_page,
);