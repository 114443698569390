import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getUserStart, updateUserStart} from "../../redux/users/user.actions";
import {
    selectCurrentUserForCrud,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/users/user.selectors";

const UserView = ({ updateUserStart, setCurrentPage,updateStatus, currentUser, history, updateLoading, getUserStart, match, updateErrors}) => {
    if (updateStatus) history.push("/home/users/list");

    const [serverError, setServerError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    //form
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');



    useEffect(() => {
        getUserStart({id: match.params.id});
    }, [getUserStart]);

    useEffect(() => {
        setCurrentPage('users');
    },[setCurrentPage]);

    useEffect(() => {
        if (currentUser) {
            setName(currentUser.name);
            setEmail(currentUser.email);
            setPassword(currentUser.password);
        }
    },[currentUser])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.name) setNameError(updateErrors.name);
            if (updateErrors.email) setEmailError(updateErrors.email);
            if (updateErrors.password) setPasswordError(updateErrors.password);
            if (
                !updateErrors.email && !updateErrors.name   &&   !updateErrors.password
            )setServerError(updateErrors);
        }
    }, [updateErrors]);



    const updateEventHandler = async ()  => {
        const formData = new FormData();
        formData.append('name',name);
        formData.append('email',email);
        formData.append('password',password);
        formData.append('_method', 'PATCH');
        setEmailError('');
        setPasswordError('');
        setNameError('');
        updateUserStart({ id : match.params.id, formData});
    }



    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading" >
                    <span onClick={() => history.push('/home/users/list')}><i className="fas fa-arrow-left"  /></span>                     Modifier un utilisateur

                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    updateLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        : <React.Fragment>
                            {
                                serverError
                                    ? <div className="ui negative message">
                                        <div className="header danger-text" >
                                            le serveur a été arrêté
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>
                                    : currentUser
                                    ? <div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    <img src={'/images/user.png'} className="add-ref-image add-cat-img" alt=""/>
                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Nom:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Nom..."
                                                                            name="name"
                                                                            value={name}
                                                                            onChange={event => setName(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        nameError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le  champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Email :</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="email"
                                                                            placeholder="Email..."
                                                                            name="email"
                                                                            value={email}
                                                                            onChange={event => setEmail(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        emailError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le  champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Mot de Passe:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="password"
                                                                            placeholder="Mot de passe..."
                                                                            name="password"
                                                                            value={password}
                                                                            onChange={event => setPassword(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        passwordError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le  champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/users/list`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} className="form-btn"  onClick={updateEventHandler}> <i className="fas fa-plus"/> modifier</Button>
                                        </Button.Group>
                                    </div>
                                    : <div className="ui negative message">
                                        <div className="header danger-text" >
                                            il y un problem
                                        </div>
                                        <p>veuillez réessayer
                                        </p></div>

                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading : selectUpdateLoading,
    currentUser: selectCurrentUserForCrud,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getUserStart : user => dispatch(getUserStart(user)),
    updateUserStart : user => dispatch(updateUserStart(user)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserView));
