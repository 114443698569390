import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'

import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getProductStart, updateProductStart} from "../../redux/products/products.actions";
import {
    selectCurrentProduct,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/products/product.selectors";
import Resizer from "react-image-file-resizer";
import {selectDeleteError, selectDeleteLoading, selectIsFetchingM, selectMarks} from "../../redux/marks/maks.selectors";
import {fetchMarksStart} from "../../redux/marks/marks.actions";





const ProductView = ({ updateProductStart, setCurrentPage,updateStatus, currentProduct, partners,isFetching, history, fetchPartners,updateLoading, getProductStart, match, updateErrors}) => {
    if (updateStatus) history.push(`/home/products/list/${currentProduct[0].productsSubcategory_id}`);

    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");



    //for request errors
    const [imageError, setImageError] = useState('');
    const [img1Error, setImg1Error] = useState('');
    const [img2Error, setImg2Error] = useState('');
    const [serverError, setServerError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [markError, setMarkError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [debit_maxError, setDebit_maxError] = useState('');
    const [hmt_maxError, setHmt_maxError] = useState('');
    const [liquid_temperatureError, setLiquid_temperatureError] = useState('');
    const [engine_descriptionError, setEngine_descriptionError] = useState('');
    const [pump_descriptionError, setPump_descriptionError] = useState('');
    const [voltage_descriptionError, setVoltage_descriptionError] = useState('');
    const [powerError, setPowerError] = useState('');


    //form
    const [type, setType] = useState('');
    const [mark, setMark] = useState('');
    const [description, setDescription] = useState('');
    const [debit_max, setDebit_max] = useState('');
    const [hmt_max, setHmt_max] = useState('');
    const [liquid_temperature, setLiquid_temperature] = useState('');
    const [engine_description, setEngine_description] = useState('');
    const [voltage_description, setVoltage_description] = useState('');
    const [pump_description, setPump_description] = useState('');
    const [power, setPower] = useState('');
    const [first_image_path, setFirst_image_path] = useState("");
    const [second_image_path, setSecond_image_path] = useState("");
    const [pdf_path, setPdf_path] = useState("");
    const [pdf, setPdf] = useState("");
    const [pdfError, setPdfError] = useState('');

    useEffect(() => {
        getProductStart({id: match.params.id});
    }, [getProductStart]);

    useEffect(() => {
        fetchPartners();
    },[fetchPartners]);

    useEffect(() => {
        setCurrentPage('products');
    },[setCurrentPage]);


    useEffect(() => {
        if (currentProduct) {
            setType(currentProduct[0].type);
            setMark(currentProduct[0].mark_id);
            setDescription(currentProduct[0].description);
            setDebit_max(currentProduct[0].debit_max);
            setHmt_max(currentProduct[0].hmt_max);
            setLiquid_temperature(currentProduct[0].liquid_temperature);
            setEngine_description(currentProduct[0].engine_description);
            setVoltage_description(currentProduct[0].voltage_description);
            setPump_description(currentProduct[0].pump_description);
            setPower(currentProduct[0].power)
        }
    },[currentProduct])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.image_path) setImageError(updateErrors.image_path);
            if (updateErrors.type) setTypeError(updateErrors.type);
            if (updateErrors.description) setDescriptionError(updateErrors.description);
            if (updateErrors.mark_id) setMarkError(updateErrors.mark_id);
            if (updateErrors.debit_max) setDebit_maxError(updateErrors.debit_max);
            if (updateErrors.hmt_max) setHmt_maxError(updateErrors.hmt_max);
            if (updateErrors.power) setPowerError(updateErrors.power);
            if (updateErrors.pump_description) setPump_descriptionError(updateErrors.pump_description);
            if (updateErrors.engine_description) setEngine_descriptionError(updateErrors.engine_description);
            if (updateErrors.liquid_temperature) setLiquid_temperatureError(updateErrors.liquid_temperature);
            if (updateErrors.voltage_description) setVoltage_descriptionError(updateErrors.voltage_description);
            if (
                !updateErrors.image_path && !updateErrors.first_image_path && !updateErrors.third_image_path &&
                !updateErrors.type && !updateErrors.second_image_path && !updateErrors.pdf_path &&
                !updateErrors.description && !updateErrors.mark_id && !updateErrors.pump_description &&
                !updateErrors.engine_description && !updateErrors.liquid_temperature && !updateErrors.voltage_description
            )setServerError(updateErrors);
        }
    }, [updateErrors]);

    console.log(type);
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "PNG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setImage_path(event.target.files[0]);
        setImageError('');
    }
    const image1SelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage1(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setFirst_image_path(event.target.files[0]);
        setImg1Error('');
    }
    const image2SelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage2(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setSecond_image_path(event.target.files[0]);
        setImg2Error('');
    }

    const pdfSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setPdf(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setPdf_path(event.target.files[0]);
        setPdfError('');
    }


    const addReferenceHandler = async ()  => {
        const formData = new FormData();
        formData.append('image_path',image_path);
        formData.append('first_image_path', first_image_path);
        formData.append('second_image_path', second_image_path);
        formData.append('pdf_path', pdf_path);
        formData.append('type',type);
        formData.append('description',description);
        formData.append('mark_id',mark);
        formData.append('debit_max',debit_max);
        formData.append('hmt_max',hmt_max);
        formData.append('power',power);
        formData.append('power',power);
        formData.append('power',power);
        formData.append('liquid_temperature',liquid_temperature);
        formData.append('engine_description',engine_description);
        formData.append('pump_description',pump_description);
        formData.append('voltage_description',voltage_description);
        formData.append('productsSubcategory_id',currentProduct ? currentProduct[0].productsSubcategory_id : null);
        formData.append('_method', 'PATCH');
        setImageError('');
        setTypeError('');
        setImg1Error('');
        setImg2Error('');
        setPdfError('');
        setDescriptionError('');
        setMarkError('');
        setDebit_maxError('');
        setHmt_maxError('');
        setPowerError('');
        setLiquid_temperatureError('');
        setEngine_descriptionError('');
        setPump_descriptionError('');
        setVoltage_descriptionError('');
        setServerError('');
        updateProductStart({ id : match.params.id, formData});
    }



    return (

        <React.Fragment>
            {
                updateLoading || isFetching
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    :   <React.Fragment>
                        <div className="overview">
                            <h1 className="overview__heading">
                                <span onClick={() => history.push(`/home/products/list/${currentProduct[0].productsSubcategory_id}`)}><i className="fas fa-arrow-left"  /></span> Modifier un Produit
                            </h1>
                            <div className="overview__rating">
                                <i className="far fa-plus-square"/>
                            </div>
                        </div>
                        <div className="detail-bigContent">
                            {
                                updateLoading
                                    ? <div className="detail-loader"><LoaderConetnt/></div>
                                    : <React.Fragment>
                                        {
                                            serverError
                                                ? <div className="ui negative message">
                                                    <div className="header danger-text" >
                                                        Quelque chose s'est mal passé
                                                    </div>
                                                    <p>veuillez réessayer
                                                    </p></div>
                                                :<div className="slider-img__block">
                                                    <div className="ui grid">
                                                        <div className="two column row">
                                                            <div className="seven wide column">
                                                                {
                                                                    imageError
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err" >
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image
                                                                                    ?
                                                                                    <img src={image} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={`https://www.admin.aqua-vim.com/${currentProduct[0].image_path}`} alt="img" className="slider-image add-cat-img" />
                                                                            }
                                                                        </React.Fragment>
                                                                }

                                                            </div>

                                                            <div className="nine wide column">
                                                                <div className="ui grid">
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Type:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Type..."
                                                                                        name="type"
                                                                                        value={type}
                                                                                        onChange={event => setType(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    typeError
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column formGroup">
                                                                            <label htmlFor="">Marque:</label>
                                                                            <select
                                                                                className="ui dropdown" style={{width: '93%'}}
                                                                                name='mark'
                                                                                value={mark}
                                                                                onChange={event => setMark(event.target.value)}
                                                                            >
                                                                                <option value=""> selectioné une marque</option>
                                                                                {
                                                                                    partners.map(par => {
                                                                                        return (
                                                                                            <option
                                                                                                value={par.id}>{par.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            {
                                                                                markError
                                                                                    ?
                                                                                    <div className="ui pointing red basic label">
                                                                                        <i className="fas fa-exclamation-triangle"/> Ce champ est obligatoire,veuillez le renseogner
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                            <label htmlFor="">Description:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="Description..."
                                                                                name="description"
                                                                                value={description}
                                                                                rows='1'
                                                                                onChange={event => setDescription(event.target.value) }
                                                                            />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    descriptionError
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Débit maximal:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder="Débit maximal..."
                                                                                        name="debit_max"
                                                                                        value={debit_max}
                                                                                        onChange={event => setDebit_max(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    debit_maxError
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Hmt maximal:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder="Hmt maximal..."
                                                                                        name="hmt_max"
                                                                                        value={hmt_max}
                                                                                        onChange={event => setHmt_max(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    hmt_maxError
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="ui grid">
                                                        <div className="3 column row">
                                                            <div className="seven wide column formGroup" style={{marginLeft:'2rem'}}>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="four wide column formGroup">
                                                                <label htmlFor="">Temperature Liquide:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Temperature Liquide..."
                                                                            name="liquid_temperature"
                                                                            value={liquid_temperature}
                                                                            onChange={event => setLiquid_temperature(event.target.value) }
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        liquid_temperatureError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="four wide column formGroup">
                                                                <label htmlFor="">Puissance Nominale:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Puissance Nominale..."
                                                                            name="power"
                                                                            value={power}
                                                                            onChange={event => setPower(event.target.value) }
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        powerError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ui grid">
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Voltage:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="voltage_description"
                                                                                value={voltage_description}
                                                                                rows='4'
                                                                                onChange={event => setVoltage_description(event.target.value)}
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        voltage_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Moteur description:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="engine_description"
                                                                                value={engine_description}
                                                                                rows='4'
                                                                                onChange={event => setEngine_description(event.target.value) }
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        engine_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Pompe description:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="pump_description"
                                                                                value={pump_description}
                                                                                rows='4'
                                                                                onChange={event => setPump_description(event.target.value) }
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        pump_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="button-wrapper add-button-wrapper add-ref" style={{marginLeft: '3rem'}}>
                                                                            <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload la fiche technique
                                                                            </span>
                                                        <input
                                                            type="file"
                                                            name="pdf_path"
                                                            id="upload"
                                                            className="upload-box"
                                                            placeholder="Upload le pdf"
                                                            onChange={pdfSelectedHandler}
                                                        />
                                                    </div>
                                                    <div className="ui grid" style={{marginLeft: '3rem'}}>
                                                        <div className="row">
                                                            <div className={"eight wide column formGroup"}>
                                                                {
                                                                    img1Error
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err" >
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image1
                                                                                    ?
                                                                                    <img src={image1} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={`https://www.admin.aqua-vim.com/${currentProduct[0].images[0].image_path}`} alt="img" className="slider-image add-cat-img" />
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                                <div className="button-wrapper add-button-wrapper add-ref"
                                                                     style={{marginLeft: '.8rem '}}>
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="first_image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload le pdf"
                                                                        onChange={image1SelectedHandler}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className={"eight wide column formGroup"}>
                                                                {
                                                                    img2Error
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err" >
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image2
                                                                                    ?
                                                                                    <img src={image2} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={`https://www.admin.aqua-vim.com/${currentProduct[0].images[1].image_path}`} alt="img" className="slider-image add-cat-img" />
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                                <div className="button-wrapper add-button-wrapper add-ref"
                                                                     style={{marginLeft: '.8rem '}}>
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="second_image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload le pdf"
                                                                        onChange={image2SelectedHandler}
                                                                    />
                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>

                                                    <Button.Group className=" btn-block ">
                                                        <Button className="form-btn" size={'big'} onClick={() => history.push(`/home/products/list/${currentProduct[0].productsSubcategory_id}`)}> <i className="fas fa-arrow-left"/> Reteur </Button>
                                                        <Button.Or text="ou"/>
                                                        <Button color={"black"} size={'big'} className="form-btn"  onClick={addReferenceHandler}> <i className="fas fa-pen-fancy"/> Modifier</Button>
                                                    </Button.Group>
                                                </div>
                                        }
                                    </React.Fragment>

                            }
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading : selectUpdateLoading,
     currentProduct: selectCurrentProduct,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,

    //marks
    isFetching : selectIsFetchingM,
    partners : selectMarks,
});

const mapDispatchToProps = dispatch => ({
    getProductStart : cat => dispatch(getProductStart(cat)),
    updateProductStart : cat => dispatch(updateProductStart(cat)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),

    //marks
    fetchPartners : () => dispatch(fetchMarksStart()),

});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductView));
