const ProductsActionsTypes = {
    FETCH_PRODUCTS_START : 'FETCH_PRODUCTS_START',
    FETCH_PRODUCTS_SUCCESS : 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAILURE : 'FETCH_PRODUCTS_FAILURE',
    DELETE_PRODUCT_START : 'DELETE_PRODUCT_START',
    DELETE_PRODUCT_SUCCESS : 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILURE : 'DELETE_PRODUCT_FAILURE',
    ADD_PRODUCT_START : 'ADD_PRODUCT_START',
    ADD_PRODUCT_SUCCESS : 'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_FAILURE : 'ADD_PRODUCT_FAILURE',
    GET_PRODUCT_START : 'GET_PRODUCT_START',
    GET_PRODUCT_SUCCESS : 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE : 'GET_PRODUCT_FAILURE',
    UPDATE_PRODUCT_START : 'UPDATE_PRODUCT_START',
    UPDATE_PRODUCT_SUCCESS : 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILURE : 'UPDATE_PRODUCT_FAILURE',
    CLEAR_VAR_PRO : 'CLEAR_VAR_PRO',
};

export default ProductsActionsTypes;
