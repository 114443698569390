import DesignActionTypes from "./design-utilities.types";

export const setPageTittle = currentTitle => ({
   type: DesignActionTypes.SET_PAGE_TITLE,
   payload: currentTitle
});

export const setCurrentPage = current_page => ({
   type: DesignActionTypes.SET_CURRENT_PAGE_NAV,
   payload: current_page
});

