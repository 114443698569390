import { createSelector } from 'reselect';

const selectReference = state => state.reference;

/////////references list
export const selectReferences  = createSelector(
    [selectReference],
    reference => reference.references,
);

export const selectIsFetchingRef = createSelector(
    [selectReference],
    reference => reference.isFetchingRef,
);

/////////add selectors
export const selectAddStatus = createSelector(
    [selectReference],
    reference => reference.addStatus
);

export const selectAddError = createSelector(
    [selectReference],
    reference => reference.addErrorMessage
);
export const selectAddLoading = createSelector(
    [selectReference],
    reference => reference.addLoading
);

/////delete selectors
export const selectDeleteLoading = createSelector(
    [selectReference],
    reference => reference.deleteLoading
);
export const selectDeleteError = createSelector(
    [selectReference],
    reference => reference.referenceNotFoundError
);

//update selectors

export const selectUpdateLoading = createSelector(
    [selectReference],
    reference => reference.updateLoading,
);

export const selectUpdateError = createSelector(
    [selectReference],
    reference => reference.updateErrorMessage,
)

export const selectCurrentReference = createSelector(
    [selectReference],
    reference => reference.currentReference
);

export const selectUpdateStatus = createSelector(
    [selectReference],
    reference => reference.updateStatus,
);




