import ConfigActionsTypes from "./config.types";

export const getConfigStart = () =>  ({
    type : ConfigActionsTypes.GET_CONFIG_START,
});

export const getConfigSuccess = config =>  ({
    type : ConfigActionsTypes.GET_CONFIG_SUCCESS,
    payload : config,
});

export const getConfigFailure = errorMessage => ({
    type : ConfigActionsTypes.GET_CONFIG_FAILURE,
    payload : errorMessage,
});

export const updateConfigStart = config =>  ({
    type : ConfigActionsTypes.UPDATE_CONFIG_START,
    payload : config,
});

export const updateConfigSuccess = () =>  ({
    type : ConfigActionsTypes.UPDATE_CONFIG_SUCCESS,
});

export const updateConfigFailure = errorMessage => ({
    type : ConfigActionsTypes.UPDATE_CONFIG_FAILURE,
    payload : errorMessage,
});
