import ClientsActionsTypes from "./clients.types";


export const fetchClientsStart = () => ({
    type : ClientsActionsTypes.FETCH_CLIENTS_START,
});

export const fetchClientsSuccess = clients => ({
    type : ClientsActionsTypes.FETCH_CLIENTS_SUCCESS,
    payload : clients,
});

export const fetchClientsFailure = errorMessage => ({
    type : ClientsActionsTypes.FETCH_CLIENTS_FAILURE,
    payload : errorMessage,
});


export const sendMessageStart = messageInformation =>  ({
    type : ClientsActionsTypes.SEND_MESSAGE_START,
    payload : messageInformation
});

export const sendMessageSuccess = () =>  ({
    type : ClientsActionsTypes.SEND_MESSAGE_SUCCESS,
});

export const sendMessageFailure = errorMessage => ({
    type : ClientsActionsTypes.SEND_MESSAGE_FAILURE,
    payload : errorMessage,
});
