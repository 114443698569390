import CategoriesActionsTypes from "./categories.types";
import UserActionTypes from "../users/user.types";

const INITIAL_STATE = {
    //categories list
    categories : [],
    isFetchingCat : true,

    //add Category
    addErrorMessage: null,
    addLoading : false,
    addStatus : false,

    //delete Category
    categoryNotFoundError: false,
    deleteLoading : false,

    //get Category
    getCategoryError: null,
    currentCategory : null,
    updateLoading : true,

    //update loading
    updateErrorMessage: null,
    updateStatus : false,


};

const categoriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //categories list
        case CategoriesActionsTypes.FETCH_CATEGORIES_START:
            return {
                ...state,
                isFetchingCat: true,
                addStatus: false,
                addErrorMessage: null,
                categoryNotFoundError:false,
                updateStatus: false,
                updateLoading: true,//verfierv update loading in the reference reducer
            };
        case CategoriesActionsTypes.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingCat: false,
                categories: action.payload,
            };
        case CategoriesActionsTypes.FETCH_CATEGORIES_FAILURE :
            return {
                ...state,
                isFetchingCat: false,
                // errorMessage: action.payload, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            };

        //Add reference
        case CategoriesActionsTypes.ADD_CATEGORY_START :
            return {
                ...state,
                addLoading: true,
                addErrorMessage: null,
            }
        case CategoriesActionsTypes.ADD_CATEGORY_SUCCESS :
            return {
                ...state,
                addStatus: true,
                addLoading: false,
                isFetchingCat: true,
                addErrorMessage: null,
            }
        case CategoriesActionsTypes.ADD_CATEGORY_FAILURE :
            return {
                ...state,
                addStatus: false,
                addErrorMessage: action.payload,
                addLoading: false,
            }

        //delete reference
        case CategoriesActionsTypes.DELETE_CATEGORY_START :
            return {
                ...state,
                deleteLoading: true,
                categoryNotFoundError : false
                // errorMessage: null,
                // status: null,
            }
        case CategoriesActionsTypes.DELETE_CATEGORY_SUCCESS :
            return {
                ...state,
                deleteLoading: false,
                categoryNotFoundError: false,
                categories: state.categories.filter(
                    reference => reference.id !== action.payload.id
                )
            }
        case CategoriesActionsTypes.DELETE_CATEGORY_FAILURE :
            return {
                ...state,
                categoryNotFoundError: true,
                deleteLoading: false,
                categories: state.categories.filter(
                    reference => reference.id !== action.payload.id
                )
            }

        //get reference
        case CategoriesActionsTypes.GET_CATEGORY_START :
            return {
                ...state,
                updateLoading: true,
                getCategoryError:null,
            }
        case CategoriesActionsTypes.GET_CATEGORY_SUCCESS :
            return {
                ...state,
                updateLoading: false,
                getCategoryError: null,
                currentCategory: action.payload,
            }
        case CategoriesActionsTypes.GET_CATEGORY_FAILURE :
            return {
                ...state,
                updateLoading: false,
                getCategoryError: action.payload,
                currentCategory: null,
            }


        //update
        case CategoriesActionsTypes.UPDATE_CATEGORY_START :
            return {
                ...state,
                updateLoading: true,
                updateErrorMessage : null,
                updateStatus : false,
            }
        case CategoriesActionsTypes.UPDATE_CATEGORY_SUCCESS :
            return {
                ...state,
                updateStatus : true,
                updateErrorMessage: null,
                updateLoading: false,
                isFetchingCat: true,
            }
        case CategoriesActionsTypes.UPDATE_CATEGORY_FAILURE :
            return {
                ...state,
                updateStatus : false,
                updateErrorMessage: action.payload,
                updateLoading: false,
            }

        case UserActionTypes.SIGN_OUT_SUCCESS :
            return {
                ...state,
                //categories list
                categories : [],
                isFetchingCat : false,

                //add Category
                addErrorMessage: null,
                addLoading : false,
                addStatus : false,

                //delete Category
                categoryNotFoundError: false,
                deleteLoading : false,

                //get Category
                getCategoryError: null,
                currentCategory : null,
                updateLoading : false,

                //update loading
                updateErrorMessage: null,
                updateStatus : false,

            }
        default:
            return state;

    }
};

export default categoriesReducer;
