import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";

import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getSubcategoryStart} from "../../redux/subcategories/subcategories.actions";
import {addProductStart} from "../../redux/products/products.actions";
import {selectAddError, selectAddLoading, selectAddStatus} from "../../redux/products/product.selectors";
import {selectCurrentSubcategory, selectUpdateLoading} from "../../redux/subcategories/subcategory.selectors";
import Resizer from "react-image-file-resizer";
import {selectIsFetchingM, selectMarks} from "../../redux/marks/maks.selectors";
import {fetchMarksStart} from "../../redux/marks/marks.actions";


const AddProduct = ({match, fetchPartners, currentCategory, partners, isFetching, updateLoading, addLoading, setCurrentPage, addProductStart, getCategoryStart, history, addStatus, addErrors}) => {
    if (addStatus) history.push(`/home/products/list/${currentCategory[0].id}`);
    const [image_path, setImage_path] = useState("");
    const [first_image_path, setFirst_image_path] = useState("");
    const [second_image_path, setSecond_image_path] = useState("");
    const [pdf_path, setPdf_path] = useState("");
    const [pdf, setPdf] = useState("");
    const [image, setImage] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");



    //for request errors
    const [imageError, setImageError] = useState('');
    const [pdfError, setPdfError] = useState('');
    const [img1Error, setImg1Error] = useState('');
    const [img2Error, setImg2Error] = useState('');
    const [img3Error, setImg3Error] = useState('');
    const [serverError, setServerError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [markError, setMarkError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [debit_maxError, setDebit_maxError] = useState('');
    const [hmt_maxError, setHmt_maxError] = useState('');
    const [liquid_temperatureError, setLiquid_temperatureError] = useState('');
    const [engine_descriptionError, setEngine_descriptionError] = useState('');
    const [pump_descriptionError, setPump_descriptionError] = useState('');
    const [voltage_descriptionError, setVoltage_descriptionError] = useState('');
    const [powerError, setPowerError] = useState('');
    //form
    const [productCredentials, setCredentials] = useState({
        type: '',
        description: '',
        mark: '',
        debit_max: '',
        hmt_max: '',
        power: '',
        liquid_temperature: '',
        engine_description: '',
        pump_description: '',
        voltage_description: ''
    });
    const {type, mark, description, debit_max, engine_description, hmt_max, liquid_temperature, power, pump_description, voltage_description} = productCredentials;

    useEffect(() => {
        fetchPartners();
    },[fetchPartners]);

    useEffect(() => {
        setCurrentPage('products');
    }, [setCurrentPage]);

    useEffect(() => {
        getCategoryStart({id: match.params.id});
    }, [getCategoryStart]);

    const handleChange = event => {
        const {value, name} = event.target;
        setCredentials({...productCredentials, [name]: value});
    };

    useEffect(() => {
        if (addErrors) {
            if (addErrors.image_path) setImageError(addErrors.image_path);
            if (addErrors.pdf_path) setPdfError(addErrors.pdf_path);
            if (addErrors.first_image_path) setImg1Error(addErrors.first_image_path);
            if (addErrors.second_image_path) setImg2Error(addErrors.second_image_path);
            if (addErrors.type) setTypeError(addErrors.type);
            if (addErrors.description) setDescriptionError(addErrors.description);
            if (addErrors.mark_id) setMarkError(addErrors.mark_id);
            if (addErrors.debit_max) setDebit_maxError(addErrors.debit_max);
            if (addErrors.hmt_max) setHmt_maxError(addErrors.hmt_max);
            if (addErrors.power) setPowerError(addErrors.power);
            if (addErrors.pump_description) setPump_descriptionError(addErrors.pump_description);
            if (addErrors.engine_description) setEngine_descriptionError(addErrors.engine_description);
            if (addErrors.liquid_temperature) setLiquid_temperatureError(addErrors.liquid_temperature);
            if (addErrors.voltage_description) setVoltage_descriptionError(addErrors.voltage_description);
            if (
                !addErrors.image_path && !addErrors.first_image_path &&
                !addErrors.type && !addErrors.second_image_path && !addErrors.pdf_path &&
                !addErrors.description && !addErrors.mark_id && !addErrors.pump_description &&
                !addErrors.engine_description && !addErrors.liquid_temperature && !addErrors.voltage_description
            ) setServerError(addErrors);
        }
    }, [addErrors]);


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "PNG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setImage_path(event.target.files[0]);
        setPdfError('');
    }
    const image1SelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage1(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setFirst_image_path(event.target.files[0]);
        setImg1Error('');
    }
    const image2SelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage2(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setSecond_image_path(event.target.files[0]);
        setImg2Error('');
    }


    const pdfSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setPdf(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setPdf_path(event.target.files[0]);
        setPdfError('');
    }

    const addReferenceHandler = async () => {
        const formData = new FormData();
        formData.append('image_path', image_path);
        formData.append('first_image_path', first_image_path);
        formData.append('second_image_path', second_image_path);
        formData.append('pdf_path', pdf_path);
        formData.append('type', type);
        formData.append('description', description);
        formData.append('mark_id', mark);
        formData.append('debit_max', debit_max);
        formData.append('hmt_max', hmt_max);
        formData.append('power', power);
        formData.append('liquid_temperature', liquid_temperature);
        formData.append('engine_description', engine_description);
        formData.append('pump_description', pump_description);
        formData.append('voltage_description', pump_description);
        formData.append('productsSubcategory_id', currentCategory ? currentCategory[0].id : null);
        setImageError('');
        setImg1Error('');
        setImg2Error('');
        setImg3Error('');
        setPdfError('');
        setTypeError('');
        setDescriptionError('');
        setMarkError('');
        setDebit_maxError('');
        setHmt_maxError('');
        setPowerError('');
        setLiquid_temperatureError('');
        setEngine_descriptionError('');
        setPump_descriptionError('');
        setVoltage_descriptionError('');
        setServerError('');

        addProductStart({formData});
    }

    return (
        <React.Fragment>
            {
                updateLoading
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        <div className="overview">
                            <h1 className="overview__heading">
                                <span onClick={() => history.push(`/home/products/list/${currentCategory[0].id}`)}><i
                                    className="fas fa-arrow-left"/></span> Ajouter un Produit
                            </h1>
                            <div className="overview__rating">
                                <i className="far fa-plus-square"/>
                            </div>
                        </div>
                        <div className="detail-bigContent">
                            {
                                addLoading || isFetching
                                    ? <div className="detail-loader"><LoaderConetnt/></div>
                                    : <React.Fragment>
                                        {
                                            serverError
                                                ? <div className="ui negative message">
                                                    <div className="header danger-text">
                                                        Quelque chose s'est mal passé
                                                    </div>
                                                    <p>veuillez réessayer
                                                    </p></div>
                                                : <div className="slider-img__block">
                                                    <div className="ui grid">
                                                        <div className="two column row">
                                                            <div className="seven wide column">
                                                                {
                                                                    imageError
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err">
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image
                                                                                    ?
                                                                                    <img src={image} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={'/images/image-logo.png'}
                                                                                         className="add-ref-image add-cat-img" alt=""/>
                                                                            }
                                                                        </React.Fragment>
                                                                }

                                                            </div>

                                                            <div className="nine wide column">
                                                                <div className="ui grid">
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Type:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Type..."
                                                                                        name="type"
                                                                                        value={type}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon"
                                                                                           style={{color: '#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    typeError
                                                                                        ?
                                                                                        <div
                                                                                            className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le
                                                                                            champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column formGroup">
                                                                            <label htmlFor="">Marque:</label>
                                                                            <select
                                                                                className="ui dropdown" style={{width: '93%'}}
                                                                                name='mark'
                                                                                value={mark}
                                                                                onChange={handleChange}
                                                                            >
                                                                                <option value=""> selectioné une marque</option>
                                                                                {
                                                                                    partners.map(par => {
                                                                                        return (
                                                                                            <option
                                                                                                value={par.id}>{par.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            {
                                                                                markError
                                                                                    ?
                                                                                    <div className="ui pointing red basic label">
                                                                                        <i className="fas fa-exclamation-triangle"/> Ce
                                                                                        champ est obligatoire,veuillez le renseogner
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div
                                                                            className="sixteen wide column  formGroup ui form add-cat-text">
                                                                            <label htmlFor="">Description:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="Description..."
                                                                                name="description"
                                                                                value={description}
                                                                                rows='1'
                                                                                onChange={handleChange}
                                                                            />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon"
                                                                                           style={{color: '#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    descriptionError
                                                                                        ?
                                                                                        <div
                                                                                            className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le
                                                                                            nom est un champ obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Débit maximal:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder="Débit maximal..."
                                                                                        name="debit_max"
                                                                                        value={debit_max}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon"
                                                                                           style={{color: '#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    debit_maxError
                                                                                        ?
                                                                                        <div
                                                                                            className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le
                                                                                            champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Hmt maximal:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder="Hmt maximal..."
                                                                                        name="hmt_max"
                                                                                        value={hmt_max}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon"
                                                                                           style={{color: '#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    hmt_maxError
                                                                                        ?
                                                                                        <div
                                                                                            className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le
                                                                                            champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="ui grid">
                                                        <div className="3 column row">
                                                            <div className="seven wide column formGroup"
                                                                 style={{marginLeft: '2rem'}}>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="four wide column formGroup">
                                                                <label htmlFor="">Temperature Liquide:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Temperature Liquide..."
                                                                            name="liquid_temperature"
                                                                            value={liquid_temperature}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        liquid_temperatureError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                champ est obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="four wide column formGroup">
                                                                <label htmlFor="">Puissance Nominale:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Puissance Nominale..."
                                                                            name="power"
                                                                            value={power}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        powerError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                champ est obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Voltage:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="voltage_description"
                                                                                value={voltage_description}
                                                                                rows='4'
                                                                                onChange={handleChange}
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        voltage_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom
                                                                                est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Moteur description:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="engine_description"
                                                                                value={engine_description}
                                                                                rows='4'
                                                                                onChange={handleChange}
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        engine_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom
                                                                                est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Pompe description:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="text..."
                                                                                name="pump_description"
                                                                                value={pump_description}
                                                                                rows='4'
                                                                                onChange={handleChange}
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        pump_descriptionError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom
                                                                                est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="sixteen wide column formGroup"
                                                                 style={{marginLeft: '4.7rem'}}>

                                                                {
                                                                    pdf_path
                                                                        ? <div className="pdf-container">
                                                                            <img src="/images/pdf.png" alt="pdf logo"
                                                                                 className={"pdf-image"}/>
                                                                        </div>
                                                                        : <React.Fragment>
                                                                            <div className="button-wrapper add-button-wrapper add-ref">
                                                                            <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload la fich technique
                                                                            </span>
                                                                                <input
                                                                                    type="file"
                                                                                    name="pdf_path"
                                                                                    id="upload"
                                                                                    className="upload-box"
                                                                                    placeholder="Upload le pdf"
                                                                                    onChange={pdfSelectedHandler}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                pdfError
                                                                                    ?
                                                                                    <div className="ui pointing red basic label">
                                                                                        <i className="fas fa-exclamation-triangle"/> Le
                                                                                        nom est un champ obligatoire !
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ui grid" style={{marginLeft: '3rem'}}>
                                                        <div className="row">
                                                            <div className={"eight wide column formGroup"}>
                                                                {
                                                                    img1Error
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err">
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image1
                                                                                    ?
                                                                                    <img src={image1} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={'/images/image-logo.png'}
                                                                                         className="add-ref-image add-cat-img" alt=""/>
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                                <div className="button-wrapper add-button-wrapper add-ref"
                                                                     style={{marginLeft: '.8rem '}}>
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="first_image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload le pdf"
                                                                        onChange={image1SelectedHandler}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className={"eight wide column formGroup"}>
                                                                {
                                                                    img2Error
                                                                        ? <div className="ui negative message img-err">
                                                                            <div className="header danger-text header-err">
                                                                                Aucune image a ete sélectionné !
                                                                            </div>
                                                                            <p>veuillez réessayer
                                                                            </p></div>
                                                                        : <React.Fragment>
                                                                            {
                                                                                image2
                                                                                    ?
                                                                                    <img src={image2} className="add-ref-image" alt=""/>
                                                                                    :
                                                                                    <img src={'/images/image-logo.png'}
                                                                                         className="add-ref-image add-cat-img" alt=""/>
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                                <div className="button-wrapper add-button-wrapper add-ref"
                                                                     style={{marginLeft: '.8rem '}}>
                                                                    <span>Image (465*465)px format JPG</span>

                                                                    <span className="label">
                                                                            <i className="fas fa-file-upload"/> Upload File
                                                                            </span>
                                                                    <input
                                                                        type="file"
                                                                        name="second_image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload le pdf"
                                                                        onChange={image2SelectedHandler}
                                                                    />
                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <Button.Group className=" btn-block ">
                                                        <Button className="form-btn" size={'big'}
                                                                onClick={() => history.push(`/home/products/list/${currentCategory[0].id}`)}>
                                                            <i className="fas fa-arrow-left"/> Reteur </Button>
                                                        <Button.Or text="ou"/>
                                                        <Button color={"black"} size={'big'} className="form-btn"
                                                                onClick={addReferenceHandler}> <i className="fas fa-plus"/> Ajouter</Button>
                                                    </Button.Group>
                                                </div>
                                        }
                                    </React.Fragment>

                            }
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>

    )
}

const mapStateToProps = createStructuredSelector({
    addLoading: selectAddLoading,
    addStatus: selectAddStatus,
    addErrors: selectAddError,
    currentCategory: selectCurrentSubcategory,
    updateLoading: selectUpdateLoading,

    //marks
    isFetching : selectIsFetchingM,
    partners : selectMarks,
});

const mapDispatchToProps = dispatch => ({
    getCategoryStart: cat => dispatch(getSubcategoryStart(cat)),
    addProductStart: productData => dispatch(addProductStart(productData)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),

    //marks
    fetchPartners : () => dispatch(fetchMarksStart()),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProduct));
