import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'react-toastify/dist/ReactToastify.css';

import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import {getConfigStart, updateConfigStart} from "../../redux/config/config.actions";
import {
    selectCurrentConfig,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/config/config.selectors";

import {toast, ToastContainer} from "react-toastify";



const Config = ({ updateConfigStart, currentConfig, setCurrentPage,updateStatus, history, updateLoading, getConfigStart, match, updateErrors}) => {
    // if (updateStatus) history.push(`/home/products/list/${currentProduct[0].productsSubcategory_id}`);

    const [expNbr, setExpNbr] = useState("");
    const [projectsNbr, setProjectsNbr] = useState("");
    const [clientsNbr, setClientsNbr] = useState("");
    const [sysNbr, setSysNbr] = useState("");
    const [mobile_1, setMobile_1] = useState("");
    const [mobile_2, setMobile_2] = useState("");
    const [mobile_3, setMobile_3] = useState("");
    const [mobile_4, setMobile_4] = useState("");
    const [mobile_5, setMobile_5] = useState("");
    const [mobile_6, setMobile_6] = useState("");
    const [email, setEmail] = useState("");
    const [address_1, setAddress_1] = useState("");
    const [address_2, setAddress_2] = useState("");
    const [pdf_path, setPdf_path] = useState("");

    const [serverError, setServerError] = useState('');
    const [expNbrError, setExpNbrError] = useState('');
    const [projectsNbrError, setprojectsNbrError] = useState('');
    const [clientsNbrError, setClientsNbrError] = useState('');
    const [sysNbrError, setSysNbrError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobile_1Error, setMobile_1Error] = useState("");
    const [mobile_2Error, setMobile_2Error] = useState("");
    const [mobile_3Error, setMobile_3Error] = useState("");
    const [mobile_4Error, setMobile_4Error] = useState("");
    const [mobile_5Error, setMobile_5Error] = useState("");
    const [mobile_6Error, setMobile_6Error] = useState("");
    const [address_1Error, setAddress_1Error] = useState("");
    const [address_2Error, setAddress_2Error] = useState("");
    const [pdf, setPdf] = useState("");
    const [pdfError, setPdfError] = useState('');
    const [notifState, setNotifState] = useState(false);


    useEffect(() => {
        getConfigStart();
    }, [getConfigStart]);

    useEffect(() => {
        setCurrentPage('config');
    },[setCurrentPage]);


    useEffect(() => {
        if (currentConfig) {
            setSysNbr(currentConfig.sysNbr);
            setClientsNbr(currentConfig.clientsNbr);
            setProjectsNbr(currentConfig.projectsNbr);
            setExpNbr(currentConfig.expNbr);
            setEmail(currentConfig.email);
            setMobile_1(currentConfig.phone_number_1);
            setMobile_2(currentConfig.phone_number_2);
            setMobile_3(currentConfig.phone_number_3);
            setMobile_4(currentConfig.phone_number_4);
            setMobile_5(currentConfig.phone_number_5);
            setMobile_6(currentConfig.phone_number_6);
            setAddress_1(currentConfig.address_1);
            setAddress_2(currentConfig.address_1);
        }
    },[currentConfig])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.expNbr) setExpNbrError(updateErrors.expNbr);
            if (updateErrors.sysNbr) setSysNbrError(updateErrors.sysNbr);
            if (updateErrors.projectsNbr) setprojectsNbrError(updateErrors.projectsNbr);
            if (updateErrors.clientsNbr) setClientsNbrError(updateErrors.clientsNbr);
            if (updateErrors.email) setEmailError(updateErrors.email);
            if (updateErrors.phone_number_1) setMobile_1Error(updateErrors.phone_number_1);
            if (updateErrors.phone_number_2) setMobile_2Error(updateErrors.phone_number_2);
            if (updateErrors.phone_number_3) setMobile_3Error(updateErrors.phone_number_3);
            if (updateErrors.phone_number_4) setMobile_4Error(updateErrors.phone_number_4);
            if (updateErrors.phone_number_5) setMobile_5Error(updateErrors.phone_number_5);
            if (updateErrors.phone_number_6) setMobile_6Error(updateErrors.phone_number_6);
            if (updateErrors.address_1) setAddress_1Error(updateErrors.address_1);
            if (updateErrors.address_2) setAddress_2Error(updateErrors.address_2);
            if (
                !updateErrors.expNbr && !updateErrors.phone_number_1 && !updateErrors.phone_number_5 &&
                !updateErrors.sysNbr && !updateErrors.phone_number_2 && !updateErrors.phone_number_6 &&
                !updateErrors.projectsNbr && !updateErrors.phone_number_3 && !updateErrors.address_1 &&
                !updateErrors.clientsNbr && !updateErrors.phone_number_4 && !updateErrors.address_2 && !updateErrors.email
            )setServerError(updateErrors);
        }
    }, [updateErrors]);

    const pdfSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setPdf(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }
        setPdf_path(event.target.files[0]);
        setPdfError('');
    }

    const updateConfig = async ()  => {
        const formData = new FormData();
        formData.append('expNbr',expNbr);
        formData.append('projectsNbr',projectsNbr);
        formData.append('clientsNbr',clientsNbr);
        formData.append('sysNbr',sysNbr);
        formData.append('phone_number_1',mobile_1);
        formData.append('phone_number_2',mobile_2);
        formData.append('phone_number_3',mobile_3);
        formData.append('phone_number_4',mobile_4);
        formData.append('phone_number_5',mobile_5);
        formData.append('phone_number_6',mobile_6);
        formData.append('email',email);
        formData.append('pdf_path', pdf_path);
        formData.append('address_1',address_1);
        formData.append('address_2',address_2);
        formData.append('_method', 'PATCH');
        setPdf_path(null);
        updateConfigStart({ id : match.params.id, formData});
        setNotifState(true);
    }

    if (updateStatus && notifState) {
        toast.dark(
            <div><i className="fas fa-check-circle"/> Mise à jour réussie</div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                draggable: true,
                toastId: 'first_toast',
            })
        setNotifState(false);


    }



    return (

        <React.Fragment>
            <ToastContainer/>
            {
                updateLoading
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    :   <React.Fragment>
                        <div className="overview">
                            <h1 className="overview__heading">
                                Configuration
                            </h1>
                            <div className="overview__rating">
                                <i className="far fa-plus-square"/>
                            </div>
                        </div>
                        <div className="detail-bigContent">
                            {
                                updateLoading
                                    ? <div className="detail-loader"><LoaderConetnt/></div>
                                    : <React.Fragment>
                                        {
                                            serverError
                                                ? <div className="ui negative message">
                                                    <div className="header danger-text" >
                                                        Quelque chose s'est mal passé
                                                    </div>
                                                    <p>veuillez réessayer
                                                    </p></div>
                                                :<div className="slider-img__block">
                                                    <div className="ui grid">
                                                        <div className="two column row">
                                                            <div className="seven wide column">
                                                                  <img src={'/images/contact.png'} className="add-ref-image" alt=""/>
                                                            </div>
                                                            <div className="nine wide column">
                                                                <div className="ui grid">
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Mobile 1:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."
                                                                                        name="mobile_1"
                                                                                        value={mobile_1}
                                                                                        onChange={event => setMobile_1(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_1Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column  formGroup">
                                                                                <label htmlFor="">Mobile 2:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."
                                                                                        name="mobile_2"
                                                                                        value={mobile_2}
                                                                                        onChange={event => setMobile_2(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_2Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Mobile 3:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."
                                                                                        name="mobile_3"
                                                                                        value={mobile_3}
                                                                                        onChange={event => setMobile_3(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_3Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Mobile 4:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."
                                                                                        name="mobile_4"
                                                                                        value={mobile_4}
                                                                                        onChange={event => setMobile_4(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_4Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Mobile 5:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."                                                                                        name="type"
                                                                                        value={mobile_5}
                                                                                        onChange={event => setMobile_5(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_5Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="eight wide column  formGroup">
                                                                            <label htmlFor="">Mobile 6:</label>
                                                                            <div className="field">
                                                                                <div className="ui right corner labeled input">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Numéro..."                                                                                        name="type"
                                                                                        value={mobile_6}
                                                                                        onChange={event => setMobile_6(event.target.value)}
                                                                                    />
                                                                                    <div className="ui right corner label">
                                                                                        <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    mobile_6Error
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ui grid" >
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup">
                                                                <label htmlFor="">Email:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Exemple@gmail.com"
                                                                            name="email"
                                                                            value={email}
                                                                            onChange={event => setEmail(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        emailError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>                                                        </div>
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Addresse Social 1:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="Addresse..."
                                                                                name="address_1"
                                                                                value={address_1}
                                                                                rows='4'
                                                                                onChange={event => setAddress_1(event.target.value) }
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        address_1Error
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" >
                                                            <div className="sixteen wide column  formGroup ui form add-cat-text">
                                                                <label htmlFor="">Addresse Social 2:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                            <textarea
                                                                                placeholder="Addresse..."
                                                                                name="address_2"
                                                                                value={address_2}
                                                                                rows='4'
                                                                                onChange={event => setAddress_2(event.target.value) }
                                                                            />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        address_2Error
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui grid pdfRow">
                                                            <div className="row ">
                                                                <div className="sixteen wide column formGroup"
                                                                     style={{marginLeft: '4.7rem',textAlign: 'center'}}>

                                                                    {
                                                                        pdf_path
                                                                            ? <div className="pdf-container">
                                                                                <img src="/images/pdf.png" alt="pdf logo"
                                                                                     className={"pdf-image"}/>
                                                                            </div>
                                                                            : <React.Fragment>
                                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                            <span className="label">
                                                                            <i className="fas fa-file-upload"/> upload L'offer de service
                                                                            </span>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="pdf_path"
                                                                                        id="upload"
                                                                                        className="upload-box"
                                                                                        placeholder="Upload le pdf"
                                                                                        onChange={pdfSelectedHandler}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    pdfError
                                                                                        ?
                                                                                        <div className="ui pointing red basic label">
                                                                                            <i className="fas fa-exclamation-triangle"/> Le
                                                                                            nom est un champ obligatoire !
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{justifyContent:'center'}} >
                                                                <div className="four wide column  formGroup">
                                                                    <label htmlFor="">Ans d'erpérience:</label>
                                                                    <div className="field">
                                                                        <div className="ui right corner labeled input">
                                                                            <input
                                                                                type="number"
                                                                                placeholder="..."
                                                                                name="exp_nbr"
                                                                                value={expNbr}
                                                                                onChange={event => setExpNbr(event.target.value)}
                                                                            />
                                                                            <div className="ui right corner label">
                                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            expNbrError
                                                                                ?
                                                                                <div className="ui pointing red basic label">
                                                                                    <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className="four wide column  formGroup">
                                                                    <label htmlFor="">PROJETS RÉALISÉE :</label>
                                                                    <div className="field">
                                                                        <div className="ui right corner labeled input">
                                                                            <input
                                                                                type="number"
                                                                                placeholder="..."
                                                                                name="projectsNbr"
                                                                                value={projectsNbr}
                                                                                onChange={event => setProjectsNbr(event.target.value)}
                                                                            />
                                                                            <div className="ui right corner label">
                                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            projectsNbrError
                                                                                ?
                                                                                <div className="ui pointing red basic label">
                                                                                    <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className="four wide column  formGroup">
                                                                    <label htmlFor="">SYSTÈMES LIVRÉS :</label>
                                                                    <div className="field">
                                                                        <div className="ui right corner labeled input">
                                                                            <input
                                                                                type="number"
                                                                                placeholder="..."
                                                                                name="sysNbr"
                                                                                value={sysNbr}
                                                                                onChange={event => setSysNbr(event.target.value)}
                                                                            />
                                                                            <div className="ui right corner label">
                                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            sysNbrError
                                                                                ?
                                                                                <div className="ui pointing red basic label">
                                                                                    <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className="four wide column  formGroup">
                                                                    <label htmlFor="">CLIENTS :</label>
                                                                    <div className="field">
                                                                        <div className="ui right corner labeled input">
                                                                            <input
                                                                                type="number"
                                                                                placeholder="..."
                                                                                name="clients"
                                                                                value={clientsNbr}
                                                                                onChange={event => setClientsNbr(event.target.value)}
                                                                            />
                                                                            <div className="ui right corner label">
                                                                                <i className="asterisk icon" style={{color:'#d71343'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            clientsNbrError
                                                                                ?
                                                                                <div className="ui pointing red basic label">
                                                                                    <i className="fas fa-exclamation-triangle"/> Le champ est obligatoire !
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <Button.Group className=" btn-block ">
                                                        <Button color={"black"} size={'big'} className="form-btn"  onClick={updateConfig}> <i className="fas fa-pen-fancy"/> Modifier</Button>
                                                    </Button.Group>
                                                </div>
                                        }
                                    </React.Fragment>

                            }
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading : selectUpdateLoading,
    currentConfig: selectCurrentConfig,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getConfigStart : () => dispatch(getConfigStart()),
    updateConfigStart : config => dispatch(updateConfigStart(config)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Config));
