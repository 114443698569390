const SubcategoriesActionsTypes = {
    FETCH_SUBCATEGORIES_START : 'FETCH_SUBCATEGORIES_START',
    FETCH_SUBCATEGORIES_SUCCESS : 'FETCH_SUBCATEGORIES_SUCCESS',
    FETCH_SUBCATEGORIES_FAILURE : 'FETCH_SUBCATEGORIES_FAILURE',
    DELETE_SUBCATEGORY_START : 'DELETE_SUBCATEGORY_START',
    DELETE_SUBCATEGORY_SUCCESS : 'DELETE_SUBCATEGORY_SUCCESS',
    DELETE_SUBCATEGORY_FAILURE : 'DELETE_SUBCATEGORY_FAILURE',
    ADD_SUBCATEGORY_START : 'ADD_SUBCATEGORY_START',
    ADD_SUBCATEGORY_SUCCESS : 'ADD_SUBCATEGORY_SUCCESS',
    ADD_SUBCATEGORY_FAILURE : 'ADD_SUBCATEGORY_FAILURE',
    GET_SUBCATEGORY_START : 'GET_SUBCATEGORY_START',
    GET_SUBCATEGORY_SUCCESS : 'GET_SUBCATEGORY_SUCCESS',
    GET_SUBCATEGORY_FAILURE : 'GET_SUBCATEGORY_FAILURE',
    UPDATE_SUBCATEGORY_START : 'UPDATE_SUBCATEGORY_START',
    UPDATE_SUBCATEGORY_SUCCESS : 'UPDATE_SUBCATEGORY_SUCCESS',
    UPDATE_SUBCATEGORY_FAILURE : 'UPDATE_SUBCATEGORY_FAILURE',
    CLEAR_VAR_SUB_CAT : 'CLEAR_VAR_CAT',
};

export default SubcategoriesActionsTypes;
