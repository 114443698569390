import React, {useEffect} from 'react';
import {Button, Icon, Table} from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/message.min.css'
import {createStructuredSelector} from "reselect";
import {
    selectDeleteError,
    selectDeleteLoading,
    selectIsFetching,
    selectSliders
} from "../../redux/slliders/slider.selectors";
import {deleteSliderStart, fetchSlidersStart} from "../../redux/slliders/slider.actions";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import LoaderConetnt from "../loader/loader-content.component";
import {setCurrentPage, setPageTittle} from "../../redux/design-utilites/design-utilities.actions";


const SlidShowList = ({fetchSlidersStart, setCurrentPage, deleteError, setPageTitle, isFetching, sliders, deleteLoading, deleteSliderStart, history}) => {

    useEffect(() => {
        fetchSlidersStart();
    }, [fetchSlidersStart]);

    useEffect(() => {
        setCurrentPage('slides');
    }, [setCurrentPage]);

    useEffect(() => {
        setPageTitle('Sliders Items');
    }, []);

    return (
        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    Carousel gestion
                </h1>
                <div className="content__text--btn">
                    <Link to='/home/slides/add'>
                        <i className="fas fa-plus"/> Ajouter une nouvelle diapositive
                    </Link>
                </div>
                <div className="overview__rating">
                    <i className="fas fa-list"/>
                </div>
            </div>
            <div className="detail-content">
                {
                    deleteError
                        ? <div className="ui error message">
                            <div className="header">
                                un message d'erreur apparait !
                            </div>
                            <ul className="list">
                                <li>le slider a été est supprimé deja</li>
                                <li>ou bien le serveur a été arrêté</li>
                            </ul>
                        </div>

                        : null
                }
                {isFetching
                    ? <div className="detail-loader"><LoaderConetnt/></div>
                    : <React.Fragment>
                        {
                            deleteLoading
                                ? <div className="detail-loader"><LoaderConetnt/></div>
                                : sliders.length > 0
                                ? <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell>Image</Table.HeaderCell>
                                            <Table.HeaderCell>Date d'insertion</Table.HeaderCell>
                                            <Table.HeaderCell>Date de modification</Table.HeaderCell>
                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            sliders.map(slider => {
                                                return (
                                                    <Table.Row key={slider.id}>
                                                        <Table.Cell collapsing>
                                                            {/*<Checkbox slider />*/}
                                                        </Table.Cell>
                                                        <Table.Cell className="slider-img-td"><img
                                                            src={`https://www.admin.aqua-vim.com/${slider.image_path}`}
                                                            alt="" className="slider-img"/></Table.Cell>
                                                        <Table.Cell>{slider.created_at}</Table.Cell>
                                                        <Table.Cell>{slider.updated_at}</Table.Cell>
                                                        <Table.Cell className="slider-action">
                                                            <Button.Group className="slider-action-btn">
                                                                <Button
                                                                    onClick={() => history.push(`/home/slides/view/${slider.id}`)}><i
                                                                    className="far fa-eye"/> Afficher </Button>
                                                                <Button.Or text="ou"/>
                                                                <Button color={"black"}
                                                                        onClick={() => deleteSliderStart(slider)}><i
                                                                    className="fas fa-trash"/> supprimer</Button>
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>

                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell colSpan='4'>
                                                <Button
                                                    floated='right'
                                                    icon
                                                    labelPosition='left'
                                                    color={'black'}
                                                    size='medium'
                                                    onClick={() => history.push('/home/slides/add')}
                                                >
                                                    <Icon name='image'/> Ajouter une nouvelle diapositive
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                                : <React.Fragment>
                                    <img src={'/images/empty.svg'} alt="Image" className="empty-img"/>
                                </React.Fragment>
                        }
                    </React.Fragment>
                }


            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectIsFetching,
    deleteLoading: selectDeleteLoading,
    deleteError: selectDeleteError,
    sliders: selectSliders,
});

const mapDispatchToProps = dispatch => ({
    fetchSlidersStart: () => dispatch(fetchSlidersStart()),
    deleteSliderStart: slider => dispatch(deleteSliderStart(slider)),
    setPageTitle: title => dispatch(setPageTittle(title)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidShowList));
