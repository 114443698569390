import React, {useEffect, useState} from 'react';
import {createStructuredSelector} from "reselect";
import {getSliderStart} from "../../redux/slliders/slider.actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import LoaderConetnt from "../loader/loader-content.component";
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import {getReferenceStart, updateReferenceStart} from "../../redux/references/reference.actions";
import {
    selectCurrentReference,
    selectUpdateError,
    selectUpdateLoading,
    selectUpdateStatus
} from "../../redux/references/reference.selectors";
import {setCurrentPage} from "../../redux/design-utilites/design-utilities.actions";
import Resizer from "react-image-file-resizer";


const ReferenceView = ({updateReferenceStart, setCurrentPage, updateStatus, currentReference, history, updateLoading, getReferenceStart, match, updateErrors}) => {
    const [image_path, setImage_path] = useState("");
    const [image, setImage] = useState("");

    //for request errors
    const [imageError, setImageError] = useState('');
    const [serverError, setServerError] = useState('');
    const [nameError, setNameError] = useState('');
    const [stateError, setStateError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [serviceError, setServiceError] = useState('');
    //form
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [state, setState] = useState('');
    const [service, setService] = useState('');


    useEffect(() => {
        getReferenceStart({id: match.params.id});
    }, [getSliderStart]);

    useEffect(() => {
        setCurrentPage('references');
    }, [setCurrentPage]);

    useEffect(() => {
        if (currentReference) {
            setName(currentReference[0].name);
            setState(currentReference[0].state);
            setService(currentReference[0].service);
            setCategory(currentReference[0].category_id);
        }
    }, [currentReference])

    useEffect(() => {
        if (updateErrors) {
            if (updateErrors.image_path) setImageError(updateErrors.image_path);
            if (updateErrors.name) setNameError(updateErrors.name);
            if (updateErrors.category_id) setCategoryError(updateErrors.category_id);
            if (updateErrors.service) setServiceError(updateErrors.service);
            if (updateErrors.state) setStateError(updateErrors.state);
            if (
                !updateErrors.image_path && !updateErrors.name && !updateErrors.service &&
                !updateErrors.category_id && !updateErrors.name && !updateErrors.state
            ) setServerError(updateErrors);
        }
    }, [updateErrors]);


    const fileSelectedHandler = async event => {
        if (event.target.files && event.target.files[0]) {
            let rd = new FileReader();
            rd.onload = (e) => {
                setImage(e.target.result);
            };
            rd.readAsDataURL(event.target.files[0]);
        }

        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setImage_path(image);
        } catch (err) {
            console.log(err);
        }

    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1500,
                800,
                "JPEG",
                72,
                0,
                (blob) => {
                    resolve(blob);
                },
                'blob'
            );
        });

    const addReferenceHandler = async () => {
        const formData = new FormData();
        formData.append('image_path', image_path);
        formData.append('name', name);
        formData.append('state', state);
        formData.append('category_id', category);
        formData.append('service', service);
        formData.append('_method', 'PATCH');

        updateReferenceStart({id: match.params.id, formData});
    }

    if (updateStatus) history.push("/home/references/list");

    return (

        <React.Fragment>
            <div className="overview">
                <h1 className="overview__heading">
                    <span onClick={() => history.push(`/home/references/list`)}><i
                        className="fas fa-arrow-left"/></span> Modifier une Réference
                </h1>
                <div className="overview__rating">
                    <i className="far fa-plus-square"/>
                </div>
            </div>
            <div className="detail-bigContent">
                {
                    updateLoading
                        ? <div className="detail-loader"><LoaderConetnt/></div>
                        :
                        <React.Fragment>
                            {
                                !currentReference
                                    ? <div className="ui negative message">
                                        <div className="header danger-text">
                                            Aucune Réfernce est trouver !
                                        </div>
                                        <p>veuillez réessayer</p>
                                    </div>
                                    : <div className="slider-img__block">
                                        <div className="ui grid">
                                            <div className="two column row">
                                                <div className="seven wide column">
                                                    {
                                                        imageError
                                                            ? <div className="ui negative message img-err">
                                                                <div className="header danger-text header-err">
                                                                    Aucune image a ete sélectionné !
                                                                </div>
                                                                <p>veuillez réessayer
                                                                </p></div>
                                                            : <React.Fragment>
                                                                {
                                                                    image
                                                                        ?
                                                                        <img src={image} className="add-ref-image" alt=""/>
                                                                        :
                                                                        <img
                                                                            src={`https://www.admin.aqua-vim.com/${currentReference[0].image_path}`}
                                                                            alt="img" className="slider-image add-cat-img"/>
                                                                }
                                                            </React.Fragment>
                                                    }                                                </div>

                                                <div className="nine wide column">
                                                    <div className="ui grid">
                                                        <div className="row">
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Nom:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Nom..."
                                                                            name="name"
                                                                            value={name}
                                                                            onChange={event => setName(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        nameError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="eight wide column formGroup">
                                                                <label htmlFor="">Etat:</label>
                                                                <select
                                                                    className="ui dropdown" style={{width: '93%'}}
                                                                    name='state'
                                                                    value={state}
                                                                    onChange={event => setState(event.target.value)}
                                                                >
                                                                    <option value="">Etat du Projet</option>
                                                                    <option value="1">Project terminer</option>
                                                                    <option value="0">Project En cours</option>
                                                                </select>
                                                                {
                                                                    stateError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> Ce
                                                                            champ est obligatoire,veuillez le renseogner
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="eight wide column  formGroup">
                                                                <label htmlFor="">Service:</label>
                                                                <div className="field">
                                                                    <div className="ui right corner labeled input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="service..."
                                                                            name="service"
                                                                            value={service}
                                                                            onChange={event => setService(event.target.value)}
                                                                        />
                                                                        <div className="ui right corner label">
                                                                            <i className="asterisk icon"
                                                                               style={{color: '#d71343'}}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        serviceError
                                                                            ?
                                                                            <div className="ui pointing red basic label">
                                                                                <i className="fas fa-exclamation-triangle"/> Le
                                                                                nom est un champ obligatoire !
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="eight wide column formGroup">
                                                                <label htmlFor="">Category:</label>
                                                                <select
                                                                    className="ui dropdown" style={{width: '93%'}}
                                                                    name='category'
                                                                    value={category}
                                                                    onChange={event => setCategory(event.target.value)}
                                                                >
                                                                    <option value="">Choisissez Une Categorie...</option>
                                                                    <option value="1">Hotels</option>
                                                                    <option value="2">Centers</option>
                                                                    <option value="3">Companies</option>
                                                                    <option value="4">Gardens</option>
                                                                </select>
                                                                {
                                                                    categoryError
                                                                        ?
                                                                        <div className="ui pointing red basic label">
                                                                            <i className="fas fa-exclamation-triangle"/> ce
                                                                            un champ est obligatoire,veuillez le renseogner
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className='eight wide column formGroup'>
                                                                <div className="button-wrapper add-button-wrapper add-ref">
                                                                    <span>Image (400*400)px format JPG</span>

                                                                    <span className="label">
                                                        <i className="fas fa-file-upload"/> Upload File
                                                     </span>
                                                                    <input
                                                                        type="file"
                                                                        name="image_path"
                                                                        id="upload"
                                                                        className="upload-box"
                                                                        placeholder="Upload File"
                                                                        onChange={fileSelectedHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button.Group className=" btn-block ">
                                            <Button className="form-btn" size={'big'}
                                                    onClick={() => history.push(`/home/references/list`)}> <i
                                                className="fas fa-arrow-left"/> Reteur </Button>
                                            <Button.Or text="ou"/>
                                            <Button color={"black"} size={'big'} className="form-btn"
                                                    onClick={addReferenceHandler}> <i
                                                className="fas fa-pen-fancy"/> Modifier </Button>
                                        </Button.Group>
                                    </div>

                            }
                        </React.Fragment>

                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    updateLoading: selectUpdateLoading,
    currentReference: selectCurrentReference,
    updateErrors: selectUpdateError,
    updateStatus: selectUpdateStatus,
});

const mapDispatchToProps = dispatch => ({
    getReferenceStart: ref => dispatch(getReferenceStart(ref)),
    updateReferenceStart: ref => dispatch(updateReferenceStart(ref)),
    setCurrentPage: current_page => dispatch(setCurrentPage(current_page)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferenceView));
