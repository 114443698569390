import React from 'react';
import Header from "../../components/header/header.component";
import SideBar from "../../components/sideBar/sideBar.component";
import Main from "../../components/main/main.compoenent";
import Particles from "react-particles-js";


const HomePage =  () => {
    return (
        <div>
            <div className="layer">
                <Particles height={"100%"}
                           params={{
                               "particles": {
                                   "number": {
                                       "value": 500,
                                       "density": {
                                           "enable": false
                                       }
                                   },
                                   "size": {
                                       "value": 3,
                                       "random": true,
                                       "anim": {
                                           "speed": 4,
                                           "size_min": 0.3
                                       }
                                   },
                                   "color": {
                                       "value": "#000"
                                   },
                                   "line_linked": {
                                       "enable": false
                                   },
                                   "move": {
                                       "random": true,
                                       "speed": 1,
                                       "direction": "top",
                                       "out_mode": "out"
                                   }
                               },
                               "interactivity": {
                                   "events": {
                                       "onhover": {
                                           "enable": true,
                                           "mode": "bubble"
                                       },
                                       "onclick": {
                                           "enable": true,
                                           "mode": "repulse"
                                       }
                                   },
                                   "modes": {
                                       "bubble": {
                                           "distance": 250,
                                           "duration": 2,
                                           "size": 0,
                                           "opacity": 0
                                       },
                                       "repulse": {
                                           "distance": 400,
                                           "duration": 4
                                       }
                                   }
                               }
                           }}
                />
            </div>
            <div className="container">
                <Header/>
                <div className="content">
                    <SideBar/>
                    <Main/>
                </div>
            </div>
        </div>

    )
}

export default HomePage;