import ReferencesActionsTypes from "./reference.types";

export const fetchReferencesStart = () => ({
    type : ReferencesActionsTypes.FETCH_REFERENCES_START,
});
export const clearVariables = () => ({
    type : ReferencesActionsTypes.CLEAR_VAR_REF,
});

export const fetchReferencesSuccess = references => ({
    type : ReferencesActionsTypes.FETCH_REFERENCES_SUCCESS,
    payload : references,
});

export const fetchReferencesFailure = errorMessage => ({
    type : ReferencesActionsTypes.FETCH_REFERENCES_FAILURE,
    payload : errorMessage,
});

export const addReferenceStart = referenceInformation =>  ({
    type : ReferencesActionsTypes.ADD_REFERENCE_START,
    payload : referenceInformation
});

export const addReferenceSuccess = () =>  ({
    type : ReferencesActionsTypes.ADD_REFERENCE_SUCCESS,
});

export const addReferenceFailure = errorMessage => ({
    type : ReferencesActionsTypes.ADD_REFERENCE_FAILURE,
    payload : errorMessage,
});

export const deleteReferenceStart = reference =>  ({
    type : ReferencesActionsTypes.DELETE_REFERENCE_START,
    payload : reference,
});

export const deleteReferenceSuccess = reference =>  ({
    type : ReferencesActionsTypes.DELETE_REFERENCE_SUCCESS,
    payload : reference,
});

export const deleteReferenceFailure = reference => ({
    type : ReferencesActionsTypes.DELETE_REFERENCE_FAILURE,
    payload : reference,
});

export const getReferenceStart = reference =>  ({
    type : ReferencesActionsTypes.GET_REFERENCE_START,
    payload : reference,
});

export const getReferenceSuccess = reference =>  ({
    type : ReferencesActionsTypes.GET_REFERENCE_SUCCESS,
    payload : reference,
});

export const getReferenceFailure = errorMessage => ({
    type : ReferencesActionsTypes.GET_REFERENCE_FAILURE,
    payload : errorMessage,
});

export const updateReferenceStart = reference =>  ({
    type : ReferencesActionsTypes.UPDATE_REFERENCE_START,
    payload : reference,
});

export const updateReferenceSuccess = () =>  ({
    type : ReferencesActionsTypes.UPDATE_REFERENCE_SUCCESS,
});

export const updateReferenceFailure = errorMessage => ({
    type : ReferencesActionsTypes.UPDATE_REFERENCE_FAILURE,
    payload : errorMessage,
});
