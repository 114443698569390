import EventsActionsTypes from "./events.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';

import Axios from "axios";
import {
    addEventFailure,
    addEventSuccess, clearVariables, deleteEventFailure, deleteEventSuccess,
    fetchEventsFailure,
    fetchEventsSuccess, getEventFailure,
    getEventSuccess, updateEventFailure, updateEventSuccess
} from "./events.actions";


export function* fetchEventsAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/events");
        const events = response.data.events;
        yield put(fetchEventsSuccess(events));
    } catch (error) {
        yield put(fetchEventsFailure(error.message));
    }
}


export function* addEventsAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/events", formData);
        yield put(addEventSuccess());
    } catch (error) {
        yield put(addEventFailure(error.response.data));
    }
}

export function* getEventsAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/events/${id}`);
        const event = response.data.event;
        yield put(getEventSuccess(event));
    } catch (error) {
        yield put(getEventFailure(error.message));
    }
}

export function* deleteEventsAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/events/${id}`);
        yield put(deleteEventSuccess({id: id}));
    } catch (error) {
        console.log(error)
        if (error.response.status === 422) {
            yield put(deleteEventFailure({id: id}));
        }

    }
}

export function* clearEventsVariables() {
    yield put(clearVariables())
}

export function* updateEventsAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/events/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateEventSuccess());
    } catch (error) {
        yield put(updateEventFailure(error.response.data));
    }
}

export function* onFetchEvents() {
    yield takeLatest(EventsActionsTypes.FETCH_EVENTS_START, fetchEventsAsync)
}

export function* onAddEventStart() {
    yield takeLatest(EventsActionsTypes.ADD_EVENT_START, addEventsAsync)
}

export function* onDeleteEventStart() {
    yield takeLatest(EventsActionsTypes.DELETE_EVENT_START, deleteEventsAsync)
}

export function* onGetEventStart() {
    yield takeLatest(EventsActionsTypes.GET_EVENT_START, getEventsAsync)
}

export function* onUpdateEventStart() {
    yield takeLatest(EventsActionsTypes.UPDATE_EVENT_START, updateEventsAsync)
}

// export function* onUserSignOutFct() {
//     yield takeLatest(EventsActionsTypes.SIGN_OUT_SUCCESS, clearEventsVariables)
// }

export function* EventSagas() {
    yield all([
        call(onFetchEvents),
        call(onAddEventStart),
        call(onDeleteEventStart),
        call(onGetEventStart),
        call(onUpdateEventStart),
        // call(onUserSignOutFct),
    ]);
}

