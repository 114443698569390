import CategoriesActionsTypes from "./categories.types";
import {takeLatest, put, all, call} from 'redux-saga/effects';
import {
    addCategoryFailure,
    addCategorySuccess,
    clearVariables,
    deleteCategoryFailure,
    deleteCategorySuccess,
    fetchCategoriesFailure,
    fetchCategoriesSuccess, getCategoryFailure, getCategorySuccess, updateCategoryFailure, updateCategorySuccess
} from "./categories.actions";
import Axios from "axios";


export function* fetchCategoriesAsync() {
    try {
        const response = yield Axios.get("https://www.admin.aqua-vim.com/api/authentication/categories");
        const Categories = response.data.categories;
        yield put(fetchCategoriesSuccess(Categories));
    } catch (error) {
        yield put(fetchCategoriesFailure(error.message));
    }
}


export function* addCategoriesAsync({payload: {formData}}) {
    try {
        const response = yield Axios.post("https://www.admin.aqua-vim.com/api/authentication/categories", formData);
        yield put(addCategorySuccess());
    } catch (error) {
        yield put(addCategoryFailure(error.response.data));
    }
}

export function* getCategoriesAsync({payload: {id}}) {
    try {
        const response = yield Axios.get(`https://www.admin.aqua-vim.com/api/authentication/categories/${id}`);
        const Category = response.data.productsCategory;
        yield put(getCategorySuccess(Category));
    } catch (error) {
        yield put(getCategoryFailure(error.message));
    }
}

export function* deleteCategoriesAsync({payload: {id}}) {
    try {
        const response = yield Axios.delete(`https://www.admin.aqua-vim.com/api/authentication/categories/${id}`);
        yield put(deleteCategorySuccess({id: id}));
    } catch (error) {
        if (error.response.status === 422) {
            yield put(deleteCategoryFailure({id: id}));
        }

    }
}

export function* clearCategoriesVariables() {
    yield put(clearVariables())
}

export function* updateCategoriesAsync({payload: {id,formData}}) {
    try {
        const response = yield Axios.post(`https://www.admin.aqua-vim.com/api/authentication/categories/${id}`, formData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
        yield put(updateCategorySuccess());
    } catch (error) {
        yield put(updateCategoryFailure(error.response.data));
    }
}

export function* onFetchCategories() {
    yield takeLatest(CategoriesActionsTypes.FETCH_CATEGORIES_START, fetchCategoriesAsync)
}

export function* onAddCategoryStart() {
    yield takeLatest(CategoriesActionsTypes.ADD_CATEGORY_START, addCategoriesAsync)
}

export function* onDeleteCategoryStart() {
    yield takeLatest(CategoriesActionsTypes.DELETE_CATEGORY_START, deleteCategoriesAsync)
}

export function* onGetCategoryStart() {
    yield takeLatest(CategoriesActionsTypes.GET_CATEGORY_START, getCategoriesAsync)
}

export function* onUpdateCategoryStart() {
    yield takeLatest(CategoriesActionsTypes.UPDATE_CATEGORY_START, updateCategoriesAsync)
}

export function* onUserSignOutFct() {
    yield takeLatest(CategoriesActionsTypes.SIGN_OUT_SUCCESS, clearCategoriesVariables)
}

export function* CategorySagas() {
    yield all([
        call(onFetchCategories),
        call(onAddCategoryStart),
        call(onDeleteCategoryStart),
        call(onGetCategoryStart),
        call(onUpdateCategoryStart),
        // call(onUserSignOutFct),
    ]);
}

