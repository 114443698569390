import DesignActionTypes from "./design-utilities.types";

const INITIAL_STATE = {
    current_title : "",
    current_page : "slides",
};

const designUtilitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case DesignActionTypes.SET_PAGE_TITLE:
          return {
              ...state,
              current_title: action.payload,
          };
      case DesignActionTypes.SET_CURRENT_PAGE_NAV:
          return {
              ...state,
              current_page: action.payload,
          };

      default:
          return state;
  }
};

export default designUtilitiesReducer;
